import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { BreakpointUpMedium } from '../styles/Breakpoints';
import Container from '../components/Container/Container';
import Button from '../components/UI/Button/Button';
import theme from '../styles/theme';
import { merklesService } from '../services/merkles/merkles.service';
import { useParams} from 'react-router-dom';
import { isLoggedInMetamask, getChainBaseUrl } from '../helpers/web3';
import {Web3Connect} from '../components/Web3Connect/Web3Connect';
import configData from '../config.json';
import Overlay from '../components/Modal/Overlay';
import { useToasts } from 'react-toast-notifications';
import Modal from '../components/Modal/Modal';
import { etherToWei} from '../helpers/utilities';
import { useTranslation } from 'react-i18next';
import { termsAndConditionService } from '../services/terms_and_condition.service';
import TermsAndConditionAgreement from '../components/TermsAndConditionAgreement/TermsAndConditionAgreement';

export default function PolybetClaimReward (props) {
  const {t} = useTranslation();
  const { addToast } = useToasts();
  const [transactionSuccessHash, setTransactionSuccessHash] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const  {globalState, setGlobalState, item} = props;
  const { companyName, isMarket = 0 } = useParams();
  const [rewardRecord, setRewardRecord] = useState({});
  const isUserLoggedIn = isLoggedInMetamask(globalState);
  const [hasClaimed, setHasClaimed] = useState(0);
  const [isClaimOpen, setIsClaimOpen] = useState(merklesService.isClaimOpen(companyName));
  const [loaded, setLoaded] = useState(0);
  const [tree, setTree] = useState();
  const [treeData, setTreeData] = useState();
  const [PBTDistributorContract, setPBTDistributorContract] = useState();
  const [claimSubmittedText, setClaimSubmittedText] = useState('');
  const [showTermsAndConditionAgreement, setShowTermsAndConditionAgreement] = useState(0);

  const closeTermAndCondition = (dontThrow=0) => {
    setShowTermsAndConditionAgreement(0)
    if(dontThrow==0)
      throw 'agreement not signed';
  }

  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  const formatCompanyNameForDisplay = (companyName) => {
    let result = companyName.replaceAll("-", " ");
    return toTitleCase(result);
  }

  const askToSignAgreement = async() => {
    const hasUserSigned = await termsAndConditionService.hasUserSigned(globalState.currentAddress, configData.sign_terms_message);
    if(hasUserSigned==0){
      setShowTermsAndConditionAgreement(1)
      throw 'agreement not signed';
    }
  }

  const beforeSend = () => {
    setShowOverlay(true);
  }
  
  const afterSend = () => {
    setShowOverlay(false);
  }
  const claim = async() => {
    closeTermAndCondition(1);
    var userAddress = globalState.currentAddress;
    // var userAddress = "0x5210c4dcd7eb899a1274fd6471adec9896ae05aa";
    var tokensInBig = rewardRecord.tokens_user_can_claim;
    var tokensInDecimalPlaces = etherToWei(tokensInBig);
    const proof = merklesService.getProof(treeData, tree, userAddress, tokensInBig);
    beforeSend(); 
    try{
      await askToSignAgreement();
      PBTDistributorContract.methods
        .claim(userAddress,tokensInDecimalPlaces,proof)
        .send({from: globalState.currentAddress})
        .on('transactionHash', function(hash){
          setTransactionSuccessHash(hash);
          setClaimSubmittedText(t('claim_transaction_submitted_to_chain'));
          afterSend();
        })
        .on('confirmation', function(confirmationNumber, receipt){
          if(confirmationNumber==configData.SHOW_RESULT_AFTER_NTH_CONFIRMATION){
            setClaimSubmittedText(t('you_successfully_claimed_your_rewards'));
          }
        })
        .on('error', function(error, receipt) {
          setClaimSubmittedText('')
          addToast(
            error.message, 
            { appearance: "error" }
          );
          afterSend();
          throw error;
        });
    }catch(error){
      if(error=='agreement not signed'){
        setShowOverlay(false);
      }else{
        afterSend()  
      }  
    }
    
  }
  

  //load tree for the first time
  useEffect(() => {
    const onLoad = async () => {
      const treeData = merklesService.getTreeData(companyName);
      const tree = merklesService.getTree(treeData);
      setTree(tree);
      setTreeData(treeData);

      setLoaded(1)
    }
    onLoad();
  }, [])

  useEffect(() => {
    const onLoad = async () => {
      const userAddress = globalState.currentAddress;
      // var userAddress = '0x5210c4dcd7eb899a1274fd6471adec9896ae05aa';
      
      if(globalState.currentAddress!=""){
        const reward = await merklesService.getReward(userAddress, companyName)
        setRewardRecord(reward)

        const distributorContract = await merklesService.loadPBTDistributionContract(globalState, companyName)
        setPBTDistributorContract(distributorContract);

        if(reward.hasOwnProperty('address')){
          const hasClaimedReward = await merklesService.hasClaimedReward(userAddress, distributorContract)
          setHasClaimed(hasClaimedReward)
        }

        setLoaded(1)
      }
    }
    onLoad();
  }, [globalState])

  

  return (
    <div style={{ paddingTop: 50, backgroundColor: 'hsla(245, 75%, 90%, 80%)'}}>
      <Container>
        <Banner>
          <h2 style={{ marginBottom: 15 }}>
            {isMarket==1 && t("claim_company_rewards")}
            {isMarket!=1 && t("claim_company_rewards_dynamic", {company_name : formatCompanyNameForDisplay(companyName)})}
          </h2>
          <p style={{ color: '#475a62' }}>
            {/* Your Metamask Wallet is your gateway to ICE Poker, the metaverse, and beyond */}
          </p>
        </Banner>
        <Box className="two-column">
          <Column>
            {/* <ColumnTitle>{t("claim_company_rewards", {company_name : formatCompanyNameForDisplay(companyName)})}</ColumnTitle> */}
            {isClaimOpen==1 && (
              <Group>
                {!loaded 
                  ? (<div className="text" style={{ marginBottom: 5, fontWeight: 600 }}>{t('loading_please_wait')}</div>)
                  : ''
                }
                {loaded && isUserLoggedIn && !rewardRecord.hasOwnProperty('address') 
                  ? (<div className="text" style={{ marginBottom: 5, fontWeight: 600 }}>{t('you_have_nothing_to_claim')}</div>)
                  : ''
                }
                {(loaded && rewardRecord.hasOwnProperty('address')) 
                  ? 
                  (<>
                    <div className="text" style={{ marginBottom: 5 }}>{t('gnosis_tokens_airdropped')} {rewardRecord.tokens_user_can_claim}</div>
                    {rewardRecord.airdrop_for=='gnosis' && 
                      <>
                        <div className="text" style={{ marginBottom: 5 }}>{t('gnosis_token_held')}: {rewardRecord.gnosis_tokens}</div>
                        <div className="text" style={{ marginBottom: 5 }}>{t('gnosis_token_percentage')}: {rewardRecord.gnosis_percentage}</div>
                      </>
                    }
                    {rewardRecord.airdrop_for=='polymarket' && 
                      <>
                        <div className="text" style={{ marginBottom: 5 }}>{t('polymarket_pnl')}: {rewardRecord.pnl}</div>
                        <div className="text" style={{ marginBottom: 5 }}>{t('polymarket_pnl_percentage')}: {rewardRecord.pnl_percentage}</div>
                      </>
                    }
                  </>)
                  : (<></>)
                }
                {(loaded && rewardRecord.hasOwnProperty('address') && !hasClaimed && claimSubmittedText=='') 
                  ? 
                  (
                    <>
                      <Button appearance="primary" onClick={claim}>{t('claim')}</Button> 
                    </>
                  )
                  : (<></>)
                }

                {(loaded && rewardRecord.hasOwnProperty('address') && hasClaimed)
                  ? (<div className="text" style={{ marginBottom: 5, fontWeight: 600 }}>{t('you_have_already_claimed_rewards')}</div>)
                  : (<></>)
                }

                {(loaded && !isUserLoggedIn) 
                  ? (<Web3Connect appearance="staking" globalState={globalState} setGlobalState={setGlobalState}></Web3Connect>)
                  : (<></>)
                }
                
                {(loaded && claimSubmittedText!='') 
                  ? (<div className="text" style={{ marginBottom: 5, fontWeight: 600 }}>{claimSubmittedText}</div>)
                  : (<></>)
                }
                
              </Group>
            )}
            {loaded && isClaimOpen==0 && (
              <Group>
                  <div className="text" style={{ marginBottom: 5, fontWeight: 600 }}>{t('claiming_yet_not_open')}</div>
              </Group>
            )}
          </Column>
        </Box>
      </Container>
      {showTermsAndConditionAgreement===1 && 
        <TermsAndConditionAgreement globalState={globalState} closeAgreement={closeTermAndCondition} signTermsClicked={(e) => claim(e)}/>
      }
      {showOverlay && <Overlay/>}
      {transactionSuccessHash!='' && (
        <Modal
          title={t('transaction_hash')}
          align="center"
          close={() => setTransactionSuccessHash('')}
        >
          <div>
            <p style={{wordWrap:'break-word', wordBreak: 'break-all'}}>
              {t('transaction_hash')}: {transactionSuccessHash}
            </p>
            <a href={getChainBaseUrl(globalState.chainId) + `/tx/${transactionSuccessHash}`} target="_blank">{t('visit_etherscan_to_view_transaction')}</a>
          </div>          
        </Modal>
      )}
    </div>
  )
}

const Banner = styled.div`
  color: #296588;
  padding: 30px 20px;
  margin-bottom: 10px;
  text-align: center;

  ${BreakpointUpMedium} {
    padding: 30px;
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  border-radius: 8px; 
  border: 2px solid #15719D;
  background-color: rgba(255,255,255, 0.4);

  ${BreakpointUpMedium} {
    flex-direction: row;
    align-items: center;
  }
`

const Column = styled.div`
  flex-basis: 100%;
  max-width: 100%;
  min-height: 195px;
  padding: 0 30px;

  &:not(:last-child) {
    border-bottom: 1px solid #7ea2b3;
    margin-bottom: 20px;
    padding-bottom: 30px;
  }

  ${BreakpointUpMedium} {
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);

    .two-column & {
      flex-basis: calc(100% / 2);
      max-width: calc(100% / 2);
    }

    &:not(:last-child) {
      border-right: 1px solid #7ea2b3;
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`
const ColumnTitle = styled.h4`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  color: #157196;
  /* padding-bottom: 7px;
  border-bottom: 1px solid #7ea2b3; */
`

const Group = styled.div`
  margin: 12px 0 12px;

  .text {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 15px;
    color: #475a62;
  }
`

const InputBox = styled.div`
  position: relative;

  .form-input {
    line-height: 1.4375;
    border: 1px solid #ccc;
    padding: .5625rem .75rem .4375rem;
    width: 100%;
    border-radius: 2px;
    border: 1px solid ${theme.darkBlue};
  }

  span {
    display: block;
    position: absolute;
    padding: 0 .5rem;
    top: 6px;
    right: 2px;
    background-color: #fff;

    button{
      color: ${theme.darkBlue};
      text-decoration: underline;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
`