import React from 'react';
import styled, { css } from 'styled-components/macro';
import Portal from './Portal';
import BlockSpinner from '../Container/BlockSpinner';

const Overlay = () => {
  return (
    <Portal>
      <ModalBackdrop>
        <BlockSpinner/>
      </ModalBackdrop>
    </Portal>
  );
};

export default Overlay;

const ModalBackdrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1051;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
`;