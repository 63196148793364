import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../../styles/theme';

const Button = ({  
  size,
  children,
  appearance,
  textSize,
  textColor,
  type,
  ...props }) => {
  return (
    <ButtonElement
      size={size}
      appearance={appearance}
      textSize={textSize}
      textColor={textColor}
      type={type}
      {...props}
    >
      {children}
    </ButtonElement>
  );
}

export default Button;

Button.propTypes = {
  appearance: PropTypes.oneOf(['primary', 'secondary', 'danger']),
  size: PropTypes.oneOf(['sm','md','lg','xl']),
  textSize: PropTypes.number,
  textColor: PropTypes.string,
  type:  PropTypes.string,
};

const ButtonElement = styled.button.attrs(props => ({
  className: 'button straight',
  type: props.type || 'button',
  textColor: props.textColor || 'white',
  textSize: props.textSize || '16',
}))`
  font-weight: 600;
  font-size: ${props => props.textSize}px;
  width: 100%;
  padding: 9px 18px;
  font-size: max(14px, min(calc(1vw * (16 * 100 / 1440)), 16px));
  font-weight: 700;
  height: max(46px, min(calc(1vw * (56 * 100 / 1440)), 56px));
 
  ${({ appearance }) => appearance === 'primary' && css`
    color: #fff;
    border: 1px solid #1CC8EE;
    background-color: #1CC8EE;

    &:hover,
    &:focus {
      border: 1px solid #04C0EA;
      background-color: #04C0EA;
    }
  `}

  ${({ appearance }) => appearance === 'secondary' && css`
    color: ${props => props.textColor};
    border: 1px solid #2ea3f2;
    background-color: #2ea3f2;

    &:hover,
    &:focus {
      border: 1px solid #2ea3f2;
      background-color: #2ea3f2;
    }
  `}

  ${({ appearance }) => appearance === 'danger' && css`
    color: ${props => props.textColor};
    border: 1px solid red;
    background-color: red;
  `}

  ${({ size }) => size === 'sm' && css`
    width: auto;
    padding: 5px 16px;
    font-size: 14px;
  `}

  &.disabled, 
  &:disabled{
    pointer-events: none;
    opacity: .65;
  }
`