import React, {useState, useEffect} from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Modal from '../Modal/Modal';
import Button from '../UI/Button/Button';
import { ethers } from "ethers";
import { termsAndConditionService } from '../../services/terms_and_condition.service';
import configData from '../../config.json';
import TermsOfUse from '../../assets/docs/DegenBetsTOS.pdf';



const TermsAndConditionAgreement = (props) => {
  const { t } = useTranslation();
  const {signTermsClicked, closeAgreement, globalState} = props;
  const signTermsAndConditions = async() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const data = ethers.utils.toUtf8Bytes(configData.sign_terms_message);
    const signer = provider.getSigner();
    const addr = await signer.getAddress();
    const signature = await provider.send('personal_sign', [ethers.utils.hexlify(data), addr.toLowerCase()])
        .catch((error) => {
            if(error.code==4001){
              closeAgreement();
            }
        });
    
    const termsAndConditionResponse = await termsAndConditionService.create(globalState.currentAddress, configData.sign_terms_message, signature);
    signTermsClicked();
  }

  return (
        <Modal
              title={t('site_terms_and_conditions_agreement_title')}
              align="center"
              close={() => closeAgreement(1)}
            >
          <div>
            <p style={{wordWrap:'break-word', wordBreak: 'break-all'}}>
              <Trans i18nKey="site_terms_and_conditions_agreement_terms_of_use">
                <div style={{marginBottom:10}}>By using DegenBets, I agree to <a href={TermsOfUse} target="_blank">Terms of Use</a></div>
              </Trans>
              <ul>
                <Trans i18nKey="site_terms_and_conditions_agreement_formatted">
                    <li>I am not the person or entities who reside in, are citizens of, are incorporated in, or have a registered office in the United States of America or any Prohibited Localities, as defined in the Terms of Use.</li>
                    <li>I will not in the future access this site or use DegenBets dApp while located within the United States or any Prohibited Localities, as defined in the Terms of Use.</li>
                    <li>I am not using, and will not in the future use, a VPN to mask my physical location from a restricted territory.</li>
                    <li>I am lawfully permitted to access this site and use DegenBets dApp under the laws of the jurisdiction on which I reside and am located.</li>
                    <li>I understand the risks associated with entering into using DegenBets Network protocols.</li>
                </Trans>
              </ul>  
              <div className="d-flex align-center justify-content-center">
                <div style={{padding:10}}>
                  <Button appearance="primary" size="md"  onClick={signTermsAndConditions}>{t('site_terms_and_conditions_sign_and_proceed')}</Button>
                </div>
              </div>
            </p>
            
          </div>          
        </Modal>
  )
}

export default TermsAndConditionAgreement;