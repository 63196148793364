import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Button from '../Button/Button';
// import { BreakpointUpMedium } from '../../../styles/Breakpoints';
// import theme from '../../../styles/theme';

export default function VoteCard () {
  const [value, setValue] = useState('yes');

  return (
    <Container>
      <Card>
        <CardTitle>Would they make it on time?</CardTitle>

        <Progress>
          <div className="bar bar-yes" style={{ width: '60%' }}>60% Yes</div>
          <div className="bar bar-no" style={{ width: '40%' }}>40% No</div>
        </Progress>

        <Options>
          <div>
            <input
              type="radio"
              name="select"
              id="id_yes"
              value="yes"
              checked={value === 'yes'}
              onChange={(e) => setValue(e.target.value)}
            />
            <Label for="id_yes" className="label-yes">
              <div class="dot"></div>
              <span className="text-line">Yes</span>
            </Label>
          </div>

          <div style={{ paddingLeft: 20 }}>
            <input
              type="radio"
              name="select"
              id="id_no"
              value="no"
              checked={value === 'no'}
              onChange={(e) => setValue(e.target.value)}
            />
            <Label for="id_no" className="label-no">
              <div class="dot"></div>
              <span className="text-line">No</span>
            </Label>
          </div>
        </Options>

        <VoteButton>
          <div>
            <Button appearance="secondary">Vote</Button>
          </div>
        </VoteButton>

      </Card>
    </Container>
  )
}

const Container = styled.section`
  padding-top: 20px;
`;

const Card = styled.div`
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 25px 20px;
  color: #296588;
  border: 4px solid #FF8042;
  background-color: #f2e7ff;
`;

const CardTitle = styled.h2`
  font-size: 20px;
  line-height: 1.2;
  color: #296588;
  margin-bottom: 20px;
`;


const Progress = styled.div`
  display: flex;
  height: 34px;
  overflow: hidden;
  font-weight: 500;
  font-size: 15px;
  background-color: white;
  border-radius: 0.25rem;
  margin-bottom: 20px;

  .bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0d6efd;
    transition: width .6s ease;

    &.bar-yes {
      background-color: #198754 !important;
    }

    &.bar-no {
      background-color: #dc3545 !important;
    }
  }
`;


const Options = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    -webkit-appearance: none;
    display: inline-block;
    vertical-align: middle;
    z-index: 100;
  }

  input[type="radio"]:checked + label {

    .dot {
      background-color: #fff;
      &:before {
        opacity: 1;
        transform: scale(1);
      }
    }

    &.label-yes {
      border-color: #198754;
      color: #198754;

      .dot:before {
        background-color: #198754;
      }
    }

    &.label-no {
      border-color: #dc3545;
      color: #dc3545;

      .dot:before {
        background-color: #dc3545;
      }
    }
  }
`

const Label = styled.label`
  min-width: 80px;
  display: inline-flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  background-color: transparent;
  transition: all 0.3s ease;

  &.label-yes {
    border-color: hsl(152deg 69% 31% / 30%);
    color: hsl(152deg 69% 31% / 40%);
  }

  &.label-no {
    border-color: hsl(354deg 70% 54% / 30%);
    color: hsl(354deg 70% 54% / 40%);
  }

  .dot {
    position: relative;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: white;
   
    &:before {
      position: absolute;
      content: "";
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      background: #0069d9;
      border-radius: 50%;
      opacity: 0;
      transform: scale(1.5);
      transition: all 0.3s ease;
    }
  }

  .text-line {
    color: inherit;
    margin-left: 10px;
    line-height: 1;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
  }
`

const VoteButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 5px;

  > div {
    flex-basis: 180px;
    /* max-width: 200px; */
  }
`