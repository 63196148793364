// import supportedChains from "./chains";
import Web3 from "web3";

//converts small currency unit to bigger unit
export function amountSToB(value){
  if(value === undefined) return 0;
  const bVal = Web3.utils.toBN(value.toString())
  return parseFloat(Web3.utils.fromWei(bVal, 'mwei'))
}

//converts big currency unit to smaller units
export function amountBToS(value){
  return Web3.utils.toWei(value.toString(), 'mwei')
}

export function weiToEther(value){
  if(value==0){
    return 0;
  } 

  var num = value.toString();
  return Web3.utils.fromWei(num, 'ether')
}

export function etherToWei(value){
  return Web3.utils.toWei(value.toString(), 'ether')
}

export function getIndexesForRedeemPositions(outcomesLength){
  const indexSets = Array.from({ length: outcomesLength }, (v, i) => {
    return i === 0 ? 1 : parseInt(Math.pow(10, i).toString(), 2)
  })

  return indexSets;
}

export function reFormatHexAddress(str){
  var beginningChars = str.substring(0, 6);
  var endingChars = str.substr(str.length - 4);
  return `${beginningChars}....${endingChars}`;
}

export function isObject(obj) {
  return typeof obj === "object" && !!Object.keys(obj).length;
}

export function getFormattedDate(date){
  var options = { year: 'numeric', month: 'long', day: 'numeric' };
  var dateToBeConverted  = new Date(date);

  return dateToBeConverted.toLocaleDateString("en-US", options);
}

export function strToFixed(str){
  return (parseFloat(str)).toFixed(2);
}

export async function isRestrictedCountry(){
  const response = await fetch(`https://api.geoapify.com/v1/ipinfo?apiKey=4abfda91434a4172b3f81ed049eac716`);
  const jsonRes = await response.json();
  return jsonRes.country.iso_code == 'IN' ? 1 : 0;
}
