import Web3 from "web3";
import i18n from '../i18n.js';

export function getRealityAnswerFromBytes32(bytes32Answer, outcomes, isFinalized) {
    if(bytes32Answer==null)
        return null;

    if(bytes32Answer=='0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'){
        return 'Both ' + outcomes[0] + ' and ' + outcomes[1];
    }

    var result = parseInt(Web3.utils.hexToNumber(bytes32Answer));
    if([0,1].includes(result)){
        return outcomes[result];
    }else{
        return null;
    }
}

export function getUmaAnswerFromString(price, market) {
    let resolutionOptionIndex;
    if(price.toString()=='1000000000000000000') resolutionOptionIndex = 0;
    if(price==0) resolutionOptionIndex = 1;
    
    if(resolutionOptionIndex!=0 && resolutionOptionIndex!=1)    return 'Draw';

    let resolvedOptionText;
    return resolvedOptionText =  i18n.t(`markets.${market.id}.options.${market.options[resolutionOptionIndex].id}`)

    // if(price.toString()=='1000000000000000000') return 'Yes';
    // if(price==0) return 'No';

    // return 'Both Yes and No';
}