import React from 'react';
import { useState, useEffect } from 'react';

import Container from '../components/Container/Container';
import CardContainer from '../components/Container/CardContainer';
import CardLight  from '../components/MarketCard/CardLight';
import PolyBetDAOBanner from '../components/Hero/PolyBetDAOBanner';
import VoteCard from '../components/UI/Cards/VoteCard';
import { marketService } from '../services/market.service';
import configData from '../config.json';
import { authenticationService } from '../services/authentication.service';
import { useHistory } from 'react-router-dom';
import Modal from '../components/Modal/Modal';

import { uuidv4 } from '../utils/uuid';
import { useTranslation, Trans } from 'react-i18next';
import HackenAuditReport from '../assets/docs/Audit_Report.pdf';

const uniswapPool = configData.additional_staking_pools.uniswap
const pbtPool = configData.additional_staking_pools.pbt;
const gnosisUserReward = configData.claim_rewards.gnosis;
const polymarketUserReward = configData.claim_rewards.polymarket;
const kanpaiPandasUserReward = configData.claim_rewards.kanpaiPanda;
const firstMarketReward = configData.claim_rewards.willTrumpWinThe2024ElectionLPRewards;
export default function PolybetDAOPage ({ globalState={globalState} }){
  const { t } = useTranslation();
  const history = useHistory();
  const [showCountryBlockedPopUp, setShowCountryBlockedPopUp] = useState(0);
  const [marketPools, setMarketPools] = useState();

  const cardClicked = (e, link) => {
    // if(authenticationService.isCountryRestricted()){
    //   setShowCountryBlockedPopUp(1)
    // }else{
      history.push(link);
    // }
  }

  useEffect(() => {
    marketService.getPublishedMarketsWithStakingEnabled()
    .then((data) => {
      setMarketPools(data);
    }, (error) => {
      console.log(error)
    })
  }, [])
  return (
    // <div style={{ paddingTop: 50, backgroundColor: 'hsla(245, 75%, 90%, 80%)'}}>
    <div>
      <Container>
        <PolyBetDAOBanner marginBottom={30}/>

        {/* fist card section start */}
        <section style={{ paddingBottom: 30 }}>
          <h2
            className="h4"
            style={{
              fontSize: 25,
              marginBottom: 15,
              color: '#333435',
            }}
          >
            {t('polybet_dao.pbt_token_staking.title')}
          </h2>
          <Trans i18nKey="polybet_dao.pbt_token_staking.p1">
            <div style={{marginBottom:10}}>Users that stake their PBT tokens receive PBT rewards. The PolyBetDAO's staking contracts have been audited by Hacken. <a href={HackenAuditReport} target="_blank">View the Audit report here</a></div>
          </Trans>
          <CardContainer>
            <CardLight item={pbtPool} cardTitle={t('polybet_dao.pbt_token_staking.card_title')} to="/pbt-stake-unstake"   globalState={globalState} onCardClick={cardClicked} />
          </CardContainer>
        </section>

        {/* second card section start */}
        <section style={{ paddingBottom: 30 }}>
          <h2
            className="h4"
            style={{
              fontSize: 25,
              marginBottom: 15,
              color: '#333435',
            }}
          >
            {t('polybet_dao.uniswap_pool_staking.title')}
          </h2>
          <Trans i18nKey="polybet_dao.uniswap_pool_staking.p1">
            <div style={{marginBottom:10}}>Users that add liquidity to the ETH and PBT pool can stake their Uniswap LP tokens to receive PBT rewards. Visit <a href='https://app.uniswap.org/add/v2/0xa406844323F1603701E6ad95ADc8A082213a68ce/ETH' target="_blank">Uniswap to add liquidity to the ETH and PBT pool.</a>  The PolyBetDAO's staking contracts have been audited by Hacken. <a href={HackenAuditReport} target="_blank">View the Audit report here</a></div>
          </Trans>
          <CardContainer>
            <CardLight cardTitle={t('polybet_dao.uniswap_pool_staking.card_title')} item={uniswapPool} to="/uniswap-stake-unstake"   globalState={globalState} onCardClick={cardClicked} />
          </CardContainer>
        </section>

        {/* third card section start */}
        <section style={{ paddingBottom: 30 }}>
          <h2
            className="h4"
            style={{
              fontSize: 25,
              marginBottom: 15,
              color: '#333435',
            }}
          >
            {t('polybet_dao.market_staking.title')}
          </h2>
          <Trans i18nKey="polybet_dao.market_staking.p1">
            <div style={{marginBottom:10}}>After the genesis PolyBet market has been resolved users that added USDC liquidity to the PolyBet market can redeem PBT rewards. The PolyBetDAO's staking contracts have been audited by Hacken. <a href={HackenAuditReport} target="_blank">View the Audit report here</a></div>
          </Trans>
          <CardContainer>
            {/* {marketPools && marketPools.map(item => (
              <CardLight cardTitle={t(`markets.${item.id}.question`)} item={item} key={item.id} to={`stake-unstake/${item.permalink}`}  globalState={globalState} onCardClick={cardClicked}/>
            ))} */}
            <CardLight item={firstMarketReward} cardTitle={t('polybet_dao.claim_pbt.card3_title')} globalState={globalState} onCardClick={(e, to) => e.preventDefault()} />
            
            {/* *************uncomment this when using the clickable market 
              **note in to={`/claim-rewards/${firstMarketReward.company_name}/1`} 1 in the end means it's market

            <CardLight item={firstMarketReward} cardTitle={t('polybet_dao.claim_pbt.card3_title')} to={`/claim-rewards/${firstMarketReward.company_name}/1`}   globalState={globalState} onCardClick={cardClicked} /> 
            */}
            <CardLight item={{...firstMarketReward, image_url: t('markets.2.image')}} cardTitle={t('markets.2.question')} globalState={globalState} onCardClick={(e, to) => e.preventDefault()} />
            <CardLight item={{...firstMarketReward, image_url: t('markets.3.image')}} cardTitle={t('markets.3.question')} globalState={globalState} onCardClick={(e, to) => e.preventDefault()} />
            <CardLight item={{...firstMarketReward, image_url: t('markets.4.image')}} cardTitle={t('markets.4.question')} globalState={globalState} onCardClick={(e, to) => e.preventDefault()} />
            <CardLight item={{...firstMarketReward, image_url: t('markets.5.image')}} cardTitle={t('markets.5.question')} globalState={globalState} onCardClick={(e, to) => e.preventDefault()} />
          </CardContainer>
        </section>
        
        

        <section style={{ paddingBottom: 30 }}>
          <h2
            className="h4"
            style={{
              fontSize: 25,
              marginBottom: 15,
              color: '#333435',
            }}
          >
            {t('polybet_dao.claim_pbt.title')}
          </h2>
          <Trans i18nKey="polybet_dao.claim_pbt.p1">
            <div style={{marginBottom:10}}>To expedite the growth of the PolyBet community Gnosis (GNO) holders and PolyMarket users can claim PBT rewards. The PolyBetDAO's staking contracts have been audited by Hacken. <a href={HackenAuditReport} target="_blank">View the Audit report here</a></div>
          </Trans>
          <CardContainer>
            {/* 
            ***************don't delete it
            **************uncomment this when ready
          <CardLight item={polymarketUserReward} cardTitle={t('polybet_dao.claim_pbt.card2_title')} to={`/claim-rewards/${polymarketUserReward.company_name}`}   globalState={globalState} onCardClick={cardClicked} /> */}
            {/* <CardLight item={gnosisUserReward} cardTitle={t('polybet_dao.claim_pbt.card1_title')} to={`/claim-rewards/${gnosisUserReward.company_name}`}   globalState={globalState} onCardClick={(e, to) => e.preventDefault()} /> */}
            <CardLight item={gnosisUserReward} cardTitle={t('polybet_dao.claim_pbt.card1_title')} to={`/claim-rewards/${gnosisUserReward.company_name}`}   globalState={globalState} onCardClick={cardClicked} />
            <CardLight item={polymarketUserReward} cardTitle={t('polybet_dao.claim_pbt.card2_title')} to={`/claim-rewards/${polymarketUserReward.company_name}`}   globalState={globalState} onCardClick={(e, to) => e.preventDefault()} />
            <CardLight item={kanpaiPandasUserReward} cardTitle={t('polybet_dao.claim_pbt.kanpai_panda_title')} globalState={globalState} onCardClick={(e, to) => e.preventDefault()}/>
          </CardContainer>
        </section>

        {/* <VoteCard /> */}
        
      </Container>
      {showCountryBlockedPopUp===1 && (
        <Modal
          title={t('restricted_country_message')}
          align="center"
          close={() => setShowCountryBlockedPopUp(0)}
        >
          <div>
            <p style={{wordWrap:'break-word', wordBreak: 'break-all'}}>
            {t('restricted_country_message')}
            </p>
          </div>          
        </Modal>
      )}
    </div>
  )
}
