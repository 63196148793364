import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import Tab from '../../components/UI/Tab/Tab';
import TermsAndConditionAgreement from '../../components/TermsAndConditionAgreement/TermsAndConditionAgreement';
import TabContent from '../../components/UI/Tab/TabContent';
import WidgetForm from '../../components/WidgetForm/WidgetForm';
import { gsSetGlobalState, disableAsyncButton, showLoadingButton, getAndSetWalletBalances } from '../../helpers/global-state';
import { calcBuyAmount, calcSellAmount, calcSellAmountInCollateral, calcExactSellCollateralAndTokenAmount } from '../../helpers/fpmm';
import { useToasts } from 'react-toast-notifications'
import { doWeNeedToGetAllowance, getCollateralBalance, getMaxApproval, askToConnectIfNotLoggedIn, isLoggedInMetamask, loadFixedProductMarketMaker, getCurrentGasPrice, getChainBaseUrl} from '../../helpers/web3';
import Modal from '../../components/Modal/Modal';
import useModal from '../../hooks/useModal';
import { amountBToS, amountSToB} from '../../helpers/utilities';
import { isConditionalTokenApprovedForAll } from '../../helpers/fpmm';
import { marketService } from '../../services/market.service';
import configData from '../../config.json';
import Web3 from "web3";
import { useTranslation } from 'react-i18next';
import { termsAndConditionService } from '../../services/terms_and_condition.service';

const BuySell = ({globalState, setGlobalState, market, userStats, setUserStats, setShowOverlay}) => {
  const { t } = useTranslation();
  const [asideTab, setAsideTab] = useState(t('market_page.buy'));
  const [widgetYesNo, setWidgetYesNo] = useState(0);
  const { addToast } = useToasts();
  const [transactionSuccessHash, setTransactionSuccessHash] = useState('');
  const [sharesToSell, setSharesToSell] = useState('');
  const [investmentAmount, setInvestmentAmount] = useState('');
  const [buyOptionIndex, setBuyOptionIndex] = useState(0);
  const [sellOptionIndex, setSellOptionIndex] = useState(0);
  const buySubmittedFor = t('market_page.buy');
  const sellSubmittedFor = t('market_page.sell');
  const [showBuyTermsAndConditionAgreement, setShowBuyTermsAndConditionAgreement] = useState(0)
  const [showSellTermsAndConditionAgreement, setShowSellTermsAndConditionAgreement] = useState(0)
  const closeTermAndCondition = (dontThrow=0) => {
    setShowBuyTermsAndConditionAgreement(0)
    setShowSellTermsAndConditionAgreement(0)
    // setShowOverlay(false);
    if(dontThrow==0)
      throw 'agreement not signed';
  }

  const isUserLoggedIn = globalState.currentAddress == '' ? 0 : 1;
  const [sellDisplayValues, setSellDisplayValues] = useState({
    sharesToBeSold : 0,
    yourAvgPrice: 0,
    remainingShares: 0,
    youWillReceive: 0
  });
  const [buyDisplayValues, setBuyDisplayValues] = useState({
    yourAvgPrice: 0,
    estimatedSharesBought: 0,
    maxWinnings: 0,
    maxReturnOnInvestment: 0
  });

  const resetBuyTab = () => {
    const othOptionIndex = 0;
    const emptyInvestmentAmount = '';
    setInvestmentAmount(emptyInvestmentAmount);
    setBuyOptionIndex(0);
    setWidgetYesNo(othOptionIndex);
    resetBuyStats(emptyInvestmentAmount, othOptionIndex);
  }

  const resetSellTab = () => {
    const othOptionIndex = 0;
    const emptySharesToSell = '';
    setSharesToSell(emptySharesToSell);
    setSellOptionIndex(0);
    setWidgetYesNo(othOptionIndex);
  }

  const changetab = (tab) => {
    if(tab.toLowerCase()==buySubmittedFor.toLowerCase()){
      resetBuyTab();
    }else{
      resetSellTab();
    }
    setAsideTab(tab)
  }

  const onBuyOptionChange = (e, optionIndex) => {
    e.preventDefault();
    setBuyOptionIndex(optionIndex);
    setWidgetYesNo(optionIndex);
    resetBuyStats(investmentAmount, optionIndex);
  }

  const resetSellStats = (sharesToSellB, sellOptionIndex) => {
    const sellStats = getSellStats(sharesToSellB, sellOptionIndex);
    setSellDisplayValues(sellStats)
  }

  const setValidSharesForSellValue = (optionIndex) => {
    if(globalState.currentAddress!=''){
      const userSelectedIndexMaxShares = amountSToB(userStats.marketPositionBalances[optionIndex]);
      let maxUserWouldSell = sharesToSell;
      if(sharesToSell > userSelectedIndexMaxShares){
        setSharesToSell(userSelectedIndexMaxShares)
        maxUserWouldSell = userSelectedIndexMaxShares;
      } 
      resetSellStats(maxUserWouldSell, optionIndex)
    }
  }

  const onSellOptionChange = (e, optionIndex) => {
    e.preventDefault();
    setSellOptionIndex(optionIndex);
    setValidSharesForSellValue(optionIndex);
    setWidgetYesNo(optionIndex);
  }


  const mtrends = [
    { label: 'yes', price: '0.06', result: '0.06'}, 
    { label: 'no', price: '0.94', result: '0.94'}
  ]

  const onBuySubmit = (event) => {
    event.preventDefault();
  }

  const beforeSend = (submittedFor) => {
    setShowOverlay(true);
  }

  const afterSend = () => {
      resetBuyTab()
      resetSellTab()

    setShowOverlay(false);
  }

  const validate = () => {
    if(investmentAmount=="" || investmentAmount==0){
      addToast(
        t('market_page.enter_usdc_to_fund'), 
        { appearance: "error" }
      );
      return 0;
    }

    return 1;
  }

  function pause(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  const buy = async () => {
    try{
        let gasPrice = await getCurrentGasPrice(globalState)
        const contracts = globalState.loadedContracts;
        const from = globalState.currentAddress;
        const address = market.fpmm_market_maker_address;
        const fixedProductMarketMaker = await loadFixedProductMarketMaker(globalState, address);
        const investmentAmountS = amountBToS(investmentAmount)
        var maxApproval = getMaxApproval();
        var buyOutcomeIndex = buyOptionIndex;
        buyOutcomeIndex = buyOutcomeIndex.toString(2)
        const collateralBalance = await getCollateralBalance(globalState, from);
        if(collateralBalance < investmentAmountS){
          addToast(
            t('market_page.trying_to_add_more_than_you_have'), 
            { appearance: "error" }
          );
          throw t('market_page.trying_to_add_more_than_you_have');
        }

        const approvalNeeded = await doWeNeedToGetAllowance(from, address, investmentAmountS, from, contracts)

        if(approvalNeeded){
            const receipt = await contracts.collateralToken.methods.approve(address, maxApproval).send({from: from, gasPrice: gasPrice})
            gasPrice = await getCurrentGasPrice(globalState)
        }

        // const outcomeTokensToBuy = await fixedProductMarketMaker.methods.calcBuyAmount(investmentAmountS, buyOutcomeIndex).call({from: from});
        const outcomeTokensToBuy = buyDisplayValues.estimatedSharesSmall;
        fixedProductMarketMaker.methods.buy(investmentAmountS, buyOutcomeIndex, outcomeTokensToBuy)
        .send({from: from, gasPrice: gasPrice})
        .on('transactionHash', function(hash){
          setTransactionSuccessHash(hash);
          afterSend();
        })
        .on('confirmation', async function(confirmationNumber, receipt){
          if(confirmationNumber==configData.SHOW_RESULT_AFTER_NTH_CONFIRMATION){
            await pause(5000);
            getAndSetWalletBalances(globalState, setGlobalState)
            // setUserStats() 
            setTimeout(() => {
                addToast('Market positions updated!', { appearance: "success" });
            }, 4000); // Delays the execution by 4000 milliseconds (4 seconds)
          }
        })
        .on('error', function(error, receipt) {
          addToast(
            error.message, 
            { appearance: "error" }
          );
          afterSend();
        });

    }catch(error){
      setShowOverlay(false);
      throw error;
    }
  }

  const askToSignAgreement = async(action) => {
    const hasUserSigned = await termsAndConditionService.hasUserSigned(globalState.currentAddress, configData.sign_terms_message);
    if(hasUserSigned==0){
      switch (action) {
        case 'buy':
          setShowBuyTermsAndConditionAgreement(1)
          break;
        case 'sell':
          setShowSellTermsAndConditionAgreement(1)
          break;
      
        default:
          break;
      }
      throw 'agreement not signed';
    } 
  }

  const validateAndBuy = async (event) => {
    try{
      closeTermAndCondition(1);
      askToConnectIfNotLoggedIn(globalState, addToast);

      beforeSend(buySubmittedFor);
      const isValid = validate();
      if(isValid){
        await askToSignAgreement('buy');
        await buy();
      }else{
        afterSend()
      }
    }catch(error){
      //we are resetting the form as we have oped sign agreement popup
      if(error=='agreement not signed'){
        setShowOverlay(false);
      }else{
        afterSend()  
      }
    }
  }

  const onBuyValueChange = (event) => {
    const investmentAmountB = event.target.value;
    setInvestmentAmount(investmentAmountB)
    resetBuyStats(investmentAmountB, buyOptionIndex);    
  }

  const getBuyStats = (investmentAmount, buyOptionIndex) => {
    const investmentDisplayAmount = investmentAmount==='' ? 0 : investmentAmount;
    let buyStats = {yourAvgPrice: 0, estimatedSharesBought: 0, maxWinnings: 0, maxReturnOnInvestment: 0}
    if(investmentDisplayAmount!=0){
      const investmentAmountS = Web3.utils.toBN(amountBToS(investmentAmount.toString()));
      const estimatedSharesBought = calcBuyAmount(investmentAmountS, buyOptionIndex, market.token_amounts)
      buyStats.estimatedSharesSmall = estimatedSharesBought;
      buyStats.estimatedSharesBought = amountSToB(estimatedSharesBought).toFixed(2)
      buyStats.yourAvgPrice = (investmentAmount/buyStats.estimatedSharesBought).toFixed(2);
      buyStats.maxWinnings = buyStats.estimatedSharesBought
      buyStats.maxReturnOnInvestment = (((buyStats.maxWinnings - investmentAmount) * 100)/investmentAmount).toFixed(2)
    }
    return buyStats;
  }

  const resetBuyStats = (investmentAmount, buyOptionIndex) => {
    const buyStats = getBuyStats(investmentAmount, buyOptionIndex);
    setBuyDisplayValues(buyStats)
  }


  const onMaxBuyClick  = (e) => {
    e.preventDefault();
    try{
      askToConnectIfNotLoggedIn(globalState, addToast);
      setInvestmentAmount(globalState.collateralBalance);
      resetBuyStats(globalState.collateralBalance, buyOptionIndex);
    }catch(error){
      console.log(error)
    }
  }

  const validateSell = () => {
    if(sharesToSell=="" || sharesToSell==0){
      addToast(
        t('market_page.enter_shares_you_want_to_sell'), 
        { appearance: "error" }
      );
      return 0;
    }
    if(sellDisplayValues.sharesToBeSold == 0){
      addToast(
        "These many shares can't be traded!", 
        { appearance: "error" }
      );
      return 0;
    }

    return 1;
  }

  const validateAndSell = async (event) => {
    // event.preventDefault();

    try{
      closeTermAndCondition(1);
      askToConnectIfNotLoggedIn(globalState, addToast);
      beforeSend(sellSubmittedFor);
      const isValid = validateSell();
      if(isValid){
        await askToSignAgreement('sell');
        await sell();
      }else{
        afterSend()
      }
    }catch(error){
      //we are resetting the form as we have oped sign agreement popup
      if(error=='agreement not signed'){
        setShowOverlay(false);
      }else{
        afterSend()  
      }
    }
  }

  const sell = async () => {
    try{
        let gasPrice = await getCurrentGasPrice(globalState)
        const contracts = globalState.loadedContracts;
        const from = globalState.currentAddress;
        const address = market.fpmm_market_maker_address;
        const fixedProductMarketMaker = await loadFixedProductMarketMaker(globalState, address);
        const maxTokensToSell = amountBToS(sharesToSell);
        const returnAmountS = amountBToS(sellDisplayValues.youWillReceive);
        const isApprovedForAll = await isConditionalTokenApprovedForAll(globalState, from, address);
        if(!isApprovedForAll){
          await contracts.conditionalToken.methods.setApprovalForAll(address, true).send({from, gasPrice: gasPrice});
          gasPrice = await getCurrentGasPrice(globalState);
        }
        // const maxTokensToSell = calcSellAmount(returnAmountS, tokenIndexToSell, poolBalances)
        fixedProductMarketMaker.methods.sell(returnAmountS, sellOptionIndex, maxTokensToSell)
        .send({from: from, gasPrice: gasPrice})
        .on('transactionHash', function(hash){
          setTransactionSuccessHash(hash);
          afterSend();
        })
        .on('confirmation', async function(confirmationNumber, receipt){
          if(confirmationNumber==configData.SHOW_RESULT_AFTER_NTH_CONFIRMATION){
            await pause(5000);
            getAndSetWalletBalances(globalState, setGlobalState)
            // setUserStats() 
            setTimeout(() => {
                addToast('Market positions updated!', { appearance: "success" });
            }, 4000); // Delays the execution by 4000 milliseconds (4 seconds)
          }
        })
        .on('error', function(error, receipt) {
          addToast(
            error.message, 
            { appearance: "error" }
          );
          afterSend();
        });

    }catch(error){
      throw error;
    }
  }



  const onSellValueChange = (event) => {
    const sharesToSellB = event.target.value;

    if(isUserLoggedIn && sharesToSellB > amountSToB(userStats.marketPositionBalances[sellOptionIndex])) return 0;

    setSharesToSell(sharesToSellB)
  }

  const getSellStats = (sharesToSellB, sellOptionIndex) => {
    let sellStats = {yourAvgPrice: 0, remainingShares: 0, youWillReceive: 0, sharesToBeSold : 0}
    if(sharesToSellB==='' || parseInt(sharesToSellB)===0) return sellStats;
    
    const feeInDecimal = configData.FEE_PERCENTAGE/100;
    
    const { estimatedCollateralB, actualSharesToBeSoldB }  = calcExactSellCollateralAndTokenAmount(
      market.token_amounts,
      sellOptionIndex,
      sharesToSellB,
      feeInDecimal
    )

    if(estimatedCollateralB === 0) return sellStats

    sellStats.yourAvgPrice = (estimatedCollateralB/actualSharesToBeSoldB).toFixed(2);
    sellStats.remainingShares = 1;
    sellStats.youWillReceive = estimatedCollateralB.toFixed(2);
    sellStats.sharesToBeSold = actualSharesToBeSoldB.toFixed(2);
    return sellStats;
  }
  
  const onMaxSellClick  = (e) => {
    e.preventDefault();
    try{
      const sharesToSellB = amountSToB(userStats.marketPositionBalances[sellOptionIndex]);
      askToConnectIfNotLoggedIn(globalState, addToast);
      setSharesToSell(sharesToSellB);
    }catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
    if(globalState.currentAddress!=''){
      resetSellStats(sharesToSell, sellOptionIndex);
    }
  }, [sharesToSell])

  useEffect(() => {
    setAsideTab(t('market_page.buy'))
  }, [t('market_page.buy')])



  return (
    <>
      <BuySellWidget>
        <Tab
          tabs={[t('market_page.buy'), t('market_page.sell')]}
          selected={asideTab}
          setSelected={changetab}
          listItemWidth={50}
        >
          <TabContent isSelected={asideTab === t('market_page.buy') || asideTab !== t('market_page.sell')}>
            <WidgetForm
              selectedTab = {t('market_page.buy')}
              yesNoData={mtrends}
              btnSelect={widgetYesNo}
              setBtnSelect={(e, index) => onBuyOptionChange(e, index)}
              onSubmit={(e) => validateAndBuy(e)}
              submitText={t('market_page.buy')}
              globalState={globalState}
              setGlobalState={setGlobalState}
              inputValue={investmentAmount}
              onInputChange={(e) => onBuyValueChange(e)}
              onMaxClick={(e) => onMaxBuyClick(e)}
              market={market}
              showStats = '1'
              displayValues={buyDisplayValues}
            />
          </TabContent>

          <TabContent isSelected={asideTab === t('market_page.sell')}>
            <WidgetForm
                selectedTab = {t('market_page.sell')}
                yesNoData={mtrends}
                btnSelect={widgetYesNo}
                setBtnSelect={(e, str) => onSellOptionChange(e, str)}
                onSubmit={(e) => validateAndSell(e)}
                submitText={t('market_page.sell')}
                globalState={globalState}
                showStats = {isLoggedInMetamask(globalState)}
                setGlobalState={setGlobalState}
                inputValue={sharesToSell}
                onInputChange={(e) => onSellValueChange(e)}
                onMaxClick={(e) => onMaxSellClick(e)}
                maxItemName={t('share', {count: 2})}
                market={market}
                displayValues={sellDisplayValues}
            />
          </TabContent>
        </Tab>
      </BuySellWidget>

      {showBuyTermsAndConditionAgreement===1 && 
        <TermsAndConditionAgreement globalState={globalState} closeAgreement={closeTermAndCondition} signTermsClicked={(e) => validateAndBuy(e)}/>
      }

      {showSellTermsAndConditionAgreement===1 && 
        <TermsAndConditionAgreement globalState={globalState} closeAgreement={closeTermAndCondition} signTermsClicked={(e) => validateAndSell(e)}/>
      }
      
      {transactionSuccessHash!='' && (
        <Modal
          title={t('transaction_hash')}
          align="center"
          close={() => setTransactionSuccessHash('')}
        >
          <div>
            <p style={{wordWrap:'break-word', wordBreak: 'break-all'}}>
              {t('transaction_hash')}: {transactionSuccessHash}
            </p>
            <a href={getChainBaseUrl(globalState.chainId) + `/tx/${transactionSuccessHash}`} target="_blank">{t('visit_etherscan_to_view_transaction')}</a>
          </div>          
        </Modal>
      )}
    </>
  ) 
}

export default BuySell;

const BuySellWidget = styled.div`
  margin-bottom: 20px;
`;
