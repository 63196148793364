const theme = {
  gray: '#6c757d',
  mutedText: '#677071',
  contentText: '#296588',
  buttonText: '#157196;',
  tintPink: '#f7acb2',
  tintYellow: '#FFDFB1',
  tintGreen: '#e9ffe7',
  light: '#E3FDFF',
  lightBlue: '#2ea3f2',
  darkBlue: '#157196',
  lightTeal: '#007D82',
  darkTeal: '#0E7074',
  black:'#232b1b'
}

export default theme