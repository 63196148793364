import React, { useState } from "react";
import styled from "styled-components";
import Button from "../UI/Button/Button";
import theme from "../../styles/theme";
import InputBoxWithMax from "../InputBoxWithMax/InputBoxWithMax";
import { amountBToS } from "../../helpers/utilities";
import {
  gsSetGlobalState,
  disableAsyncButton,
  showLoadingButton,
  getAndSetWalletBalances,
} from "../../helpers/global-state";
import {
  doWeNeedToGetAllowance,
  getCollateralBalance,
  getMaxApproval,
  isLoggedInMetamask,
  loadFixedProductMarketMaker,
  getCurrentGasPrice,
  getChainBaseUrl,
} from "../../helpers/web3";
import { useToasts } from "react-toast-notifications";
import Modal from "../../components/Modal/Modal";
import useModal from "../../hooks/useModal";
import configData from "../../config.json";
import { authenticationService } from "../../services/authentication.service";
import { useTranslation } from "react-i18next";
import { termsAndConditionService } from "../../services/terms_and_condition.service";
import TermsAndConditionAgreement from "../../components/TermsAndConditionAgreement/TermsAndConditionAgreement";

const AddLiquidity = ({
  isShowing,
  toggleShow,
  globalState,
  setGlobalState,
  market,
  setUserStats,
  setShowOverlay,
}) => {
  const { t } = useTranslation();
  const [liquidity, setLiquidity] = useState("");
  const [liquiditySuccessHash, setLiquiditySuccessHash] = useState(false);
  const [showAddLiquidityModel, setShowAddLiquidityModel] = useState(false);
  const [showTermsAndConditionAgreement, setShowTermsAndConditionAgreement] =
    useState(0);
  const actionName = "add_liquidity";
  const { addToast } = useToasts();

  const [showCountryBlockedPopUp, setShowCountryBlockedPopUp] = useState(0);
  const isCountryRestricted = authenticationService.isCountryRestricted();
  const countryRestrictedBtnClicked = (e) => {
    setShowCountryBlockedPopUp(1);
  };
  const closeTermAndCondition = (dontThrow = 0) => {
    setShowTermsAndConditionAgreement(0);
    if (dontThrow == 0) throw "agreement not signed";
  };

  const onMaxClicked = (e) => {
    e.preventDefault();
    //ask them to connect if not connected
    if (!isLoggedInMetamask(globalState)) {
      addToast(t("connect_your_wallet_first"), { appearance: "warning" });
      return 0;
    }

    //get collateral balance
    //set it in the input box
    setLiquidity(globalState.collateralBalance);
  };

  const validate = () => {
    if (liquidity == "" || liquidity == 0) {
      addToast(t("market_page.enter_usdc_to_fund"), { appearance: "error" });
      return 0;
    }

    return 1;
  };

  const askToSignAgreement = async () => {
    const hasUserSigned = await termsAndConditionService.hasUserSigned(
      globalState.currentAddress,
      configData.sign_terms_message
    );
    if (hasUserSigned == 0) {
      setShowTermsAndConditionAgreement(1);
      throw "agreement not signed";
    }
  };

  const beforeSend = () => {
    setShowOverlay(true);
  };

  const afterSend = () => {
    setLiquidity("");
    setShowOverlay(false);
  };

  const addFunding = async () => {
    try {
      let gasPrice = await getCurrentGasPrice(globalState);
      const contracts = globalState.loadedContracts;
      const from = globalState.currentAddress;
      const address = market.fpmm_market_maker_address;
      const fixedProductMarketMaker = await loadFixedProductMarketMaker(
        globalState,
        address
      );
      const fundsToAdd = amountBToS(liquidity);
      const initialDistribution = [];
      var maxApproval = getMaxApproval();

      const collateralBalance = await getCollateralBalance(globalState, from);
      if (collateralBalance < fundsToAdd) {
        addToast(t("market_page.trying_to_add_more_than_you_have"), {
          appearance: "error",
        });
        throw t("market_page.trying_to_add_more_than_you_have");
      }

      const approvalNeeded = await doWeNeedToGetAllowance(
        from,
        address,
        fundsToAdd,
        from,
        contracts
      );

      if (approvalNeeded) {
        const receipt = await contracts.collateralToken.methods
          .approve(address, maxApproval)
          .send({ from: from, gasPrice: gasPrice });
        gasPrice = await getCurrentGasPrice(globalState);
      }

      fixedProductMarketMaker.methods
        .addFunding(fundsToAdd, initialDistribution)
        .send({ from: from, gasPrice: gasPrice })
        .on("transactionHash", function (hash) {
          setLiquiditySuccessHash(hash);
          afterSend();
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          if (
            confirmationNumber == configData.SHOW_RESULT_AFTER_NTH_CONFIRMATION
          ) {
            addToast(t("market_page.liquidity_positions_updated"), {
              appearance: "success",
            });
            getAndSetWalletBalances(globalState, setGlobalState);
            // setUserStats();
          }
        })
        .on("error", function (error, receipt) {
          addToast(error.message, { appearance: "error" });
          afterSend();
          // throw error;
        });
    } catch (error) {
      throw error;
    }
  };

  const validateAndAddFunding = async (event) => {
    event.preventDefault();

    //ask them to connect if not connected
    if (!isLoggedInMetamask(globalState)) {
      addToast(t("connect_your_wallet_first"), { appearance: "warning" });
      return 0;
    }

    if (!validate()) return 0;

    // setShowAddLiquidityModel(true)
    proceedWithAddFunding(event);
  };

  const proceedWithAddFunding = async (event) => {
    // event.preventDefault();
    // setShowAddLiquidityModel(false)
    closeTermAndCondition(1);
    beforeSend();
    const isValid = validate();
    if (isValid) {
      try {
        await askToSignAgreement();
        await addFunding();
      } catch (error) {
        if (error == "agreement not signed") {
          setShowOverlay(false);
        } else {
          afterSend();
        }
      }
    } else {
      afterSend();
    }
  };

  const onLiquidityChange = (event) => {
    setLiquidity(event.target.value);
  };

  return (
    <div>
      {isShowing && (
        <LiquidityBox>
          <form>
            <div className="text">{t("market_page.add_liquidity")}</div>
            <InputBoxWithMax
              value={liquidity}
              onChange={(e) => onLiquidityChange(e)}
              onClick={(e) => onMaxClicked(e)}
            />
          </form>

          <Group>
            {isCountryRestricted == 0 && (
              <Button
                appearance="primary"
                onClick={validateAndAddFunding}
                disabled={disableAsyncButton(globalState)}
              >
                {showLoadingButton(globalState, actionName)
                  ? t("market_page.adding_liquidity")
                  : t("market_page.add_liquidity")}
              </Button>
            )}
            {isCountryRestricted == 1 && (
              <Button
                appearance="danger"
                onClick={(e) => countryRestrictedBtnClicked(e)}
              >
                {t("unavailable")}
              </Button>
            )}
          </Group>
        </LiquidityBox>
      )}

      {!isShowing && (
        <ToggleWrap>
          <button onClick={toggleShow}>{t("market_page.add_liquidity")}</button>
        </ToggleWrap>
      )}

      {showTermsAndConditionAgreement === 1 && (
        <TermsAndConditionAgreement
          globalState={globalState}
          closeAgreement={closeTermAndCondition}
          signTermsClicked={(e) => proceedWithAddFunding(e)}
        />
      )}

      {showAddLiquidityModel && (
        <Modal
          title={t("market_page.add_liquidity")}
          align="center"
          close={() => setShowAddLiquidityModel(false)}
        >
          <div>
            <p>{t("market_page.add_liquidity_terms_and_conditions")}</p>
            <div className="d-flex align-center justify-content-center">
              <div style={{ padding: 10 }}>
                <Button
                  appearance="primary"
                  size="sm"
                  onClick={(e) => proceedWithAddFunding(e)}
                >
                  {t("market_page.i_agree")}
                </Button>
              </div>
              <div style={{ padding: 10 }}>
                <Button
                  appearance="danger"
                  size="sm"
                  onClick={() => setShowAddLiquidityModel(false)}
                >
                  {t("market_page.close_popup")}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showCountryBlockedPopUp === 1 && (
        <Modal
          title={t("restricted_country_title_message")}
          align="center"
          close={() => setShowCountryBlockedPopUp(0)}
        >
          <div>
            <p style={{ wordWrap: "break-word", wordBreak: "break-all" }}>
              {t("restricted_country_message")}
            </p>
          </div>
        </Modal>
      )}

      {liquiditySuccessHash != "" && (
        <Modal
          title={t("transaction_hash")}
          align="center"
          close={() => setLiquiditySuccessHash("")}
        >
          <div>
            <p style={{ wordWrap: "break-word", wordBreak: "break-all" }}>
              {t("transaction_hash")}: {liquiditySuccessHash}
            </p>
            <a
              href={
                getChainBaseUrl(globalState.chainId) +
                `/tx/${liquiditySuccessHash}`
              }
              target="_blank"
            >
              {t("visit_etherscan_to_view_transaction")}
            </a>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AddLiquidity;

const LiquidityBox = styled.div`
  padding: max(15px, min(calc(1vw * (20 * 100 / 1440)), 20px));
  border-radius: max(5px, min(calc(1vw * (8 * 100 / 1440)), 8px));
  background-color: oklch(98% 0 0);
  margin-bottom: 20px;

  .text {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }
`;

const Group = styled.div`
  margin-top: 18px;
  margin-bottom: 5px;
`;

const ToggleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: max(46px, min(calc(1vw * (56 * 100 / 1440)), 56px));
  border-radius: max(5px, min(calc(1vw * (8 * 100 / 1440)), 8px));
  text-align: center;
  border: 1px solid #1cc8ee;
 

  button {
    color: #1cc8ee;
    font-size: max(14px, min(calc(1vw * (16 * 100 / 1440)), 16px));
    font-weight: 600;
    border: none;
    cursor: pointer;
    padding: 0.5em;
    background-color: transparent;

    &:active,
    &:focus {
      outline: none;
    }
  }
`;
