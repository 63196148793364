import React from 'react';
import ReactDOM from 'react-dom';
import { ToastProvider } from 'react-toast-notifications'
import './i18n';
import App from './App';
import GlobalStyle from './styles/globalStyles';



ReactDOM.render(
  <React.StrictMode>
    <div className="page">
      <ToastProvider>
        <App />
      </ToastProvider>
    </div>
    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
