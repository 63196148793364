import React from 'react';
import styled from 'styled-components/macro';

export default function CardContainer (props) {
  return (
    <Row>{props.children}</Row>
  )
}

const Row = styled.div.attrs({ className: 'cc_row'})`
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
`