import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from './Container';
import { BreakpointUpMedium } from '../../styles/Breakpoints';

const GridContainer = ({ marginBottom, children }) => {
  return (
    <Section marginBottom={marginBottom}>
      <Container>
        <Grid>{children}</Grid>
      </Container>
    </Section>
  )
}

export default GridContainer;

GridContainer.propTypes = {
  marginBottom: PropTypes.number,
};

const Section = styled.section.attrs(props => ({
  className: 'gs',
  marginBottom: props.marginBottom || '0',
}))`
  margin-bottom: ${props => props.marginBottom}px;
`

const Grid = styled.section.attrs({ className: 'gs__grid' })`
  ${BreakpointUpMedium} {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(2, 1fr);
  }
`