/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const Tab = ({ tabs, selected, setSelected, children, listItemWidth }) => {
  return (
    <TabArea>
      <List>
        {tabs &&
          tabs.map((tab, idx) => {
            const active = tab === selected ? "active" : "";

            return (
              <ListItem
                key={`${String(tab).toLowerCase()}_${idx}`}
                listItemWidth={listItemWidth}
              >
                <a className={active} onClick={() => setSelected(tab)}>
                  {tab}
                </a>
              </ListItem>
            );
          })}
      </List>
      {children}
    </TabArea>
  );
};

export default Tab;

Tab.propTypes = {
  listItemWidth: PropTypes.number,
};

const TabArea = styled.div.attrs((props) => ({
  className: "tab-area",
  marginBottom: props.marginBottom || 0,
}))`
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  margin-bottom: 1rem;
  border-bottom: 1px solid #a0a3bd;
`;

const ListItem = styled.li`
  margin-bottom: -1px;

  a {
    position: relative;
    display: block;
    font-weight: 700;
    color: #6e7191;
    font-size: max(14px, min(calc(1vw * (16 * 100 / 1440)), 16px));
    padding: 10px 2rem;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    border-bottom: 1px solid #d0d0d0;

    &.active {
      color: #14142b;
    }
    &.active::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: #1cc8ee;
      position: absolute;
      left: 0;
      top: calc(100% - 2px);
    }
  }

  ${({ listItemWidth }) => css`
    flex-basis: ${listItemWidth}%;
    max-width: ${listItemWidth}%;
  `}
`;
