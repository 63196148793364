import {amountSToB, weiToEther} from "./utilities"


//return collateral balance in smaller unit
export const gsGetCollateralBalS = (globalState) => {
    return globalState.collateralBalanceSmallestUnit;
}

//return collateral balance in bigger unit
export const gsGetCollateralBalB = (globalState) => {
    return globalState.collateralBalance;
}

export const gsSetGlobalState = (globalState, setGlobalState, objectToBeSet) => {
    setGlobalState({
        ...globalState,
        ...objectToBeSet
    })
}

export const getAndSetWalletBalances = async (globalState, setGlobalState, setBalanceFor = 'usdc') => {
    var currentAddress = globalState.currentAddress;

    if(setBalanceFor=='usdc'){
        var collateralBalance = await globalState.loadedContracts.collateralToken.methods.balanceOf(currentAddress).call({from: currentAddress})
        collateralBalance = amountSToB(collateralBalance)
        gsSetGlobalState(globalState, setGlobalState, {
            collateralBalance
        });
    }

    if(setBalanceFor=='pbt'){
        console.log('------------------------------------------')
        console.log(globalState.loadedContracts)
        var pbtBalance = await globalState.loadedContracts.pbtToken.methods.balanceOf(currentAddress).call({from: currentAddress})
        pbtBalance = weiToEther(pbtBalance)
        gsSetGlobalState(globalState, setGlobalState, {
            pbtBalance
        });
    }
}

export const disableAsyncButton = (globalState) => {
    return globalState.submissionBeingProcessed;
}

export const showLoadingButton = (globalState, buttonAction) => {
    const boolSBP = globalState.submissionBeingProcessed;
    const strSF = globalState.submittedFor;
    if(boolSBP==false)
        return false;

    return strSF==buttonAction ? true : false;
}