import React from 'react';
import styled from 'styled-components';
import Button from '../UI/Button/Button';
import theme from '../../styles/theme';

const ResolvedMarket = ({resolvedOptionName}) => {
  return (
    <ResolvedMarketBox>
      <div className="pbt">{`Market has been resolved in the favor of "${resolvedOptionName}"`}</div>
    </ResolvedMarketBox>
  )
}

export default ResolvedMarket;

const ResolvedMarketBox = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 2px;
  border: 1px solid #ada3e2;
  background-color: ${theme.light};
  margin-bottom: 20px;

  .text {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }

  .pbt {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }
`;