import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import Web3 from "web3";
import configData from "../../config.json";

const ChainHandler = ({ globalState, setGlobalState }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [incorrectChain, setIncorrectChain] = useState(false);
  const [expectedChainName, setExpectedChainName] = useState("");
  const [expectedChainId, setExpectedChainId] = useState("");

  const DAOPages = [
    "/claim-rewards",
    "/DAO",
    "/uniswap-stake-unstake",
    "/pbt-stake-unstake",
  ];

  useEffect(() => {
    const changeChain = async (chainId) => {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: Web3.utils.toHex(chainId) }],
      });
    };
    const onLoad = async () => {
      const currentPage = location.pathname;
      let isDaoPage = false;

      if (
        window.ethereum &&
        window.ethereum.isMetaMask &&
        globalState.metamaskConnected === true
      ) {
        if (currentPage != "/") {
          const filteredPages = DAOPages.filter((daoPage) =>
            currentPage.includes(daoPage)
          );
          isDaoPage = filteredPages.length > 0 ? true : false;
        }

        const expectedChain = isDaoPage
          ? configData.DAO_PAGES_CHAIN
          : configData.OTHER_PAGES_CHAIN;
        setExpectedChainName(expectedChain);
        const expectedChainId = isDaoPage
          ? configData.DAO_PAGES_CHAIN_ID
          : configData.OTHER_PAGES_CHAIN_ID;
        setExpectedChainId(expectedChainId);
        const web3 = new Web3(window.ethereum);
        setIncorrectChain(false);

        web3.eth.getChainId().then((currentChainId) => {
          if (
            (isDaoPage === true &&
              configData.DAO_PAGES_CHAIN_ID != currentChainId) ||
            (isDaoPage === false &&
              configData.OTHER_PAGES_CHAIN_ID != currentChainId)
          ) {
            setIncorrectChain(true);
          }

          if (expectedChain !== currentChainId) changeChain(expectedChainId);
        });
      }
    };
    onLoad();
  }, [location, globalState.metamaskConnected, globalState.chainId]);

  return (
    <>
      {incorrectChain === true && globalState.metamaskConnected === true && (
        <div style={{ maxWidth: 498, marginInline: "auto" }}>
          <div
            style={{
              paddingBlock: 30,
              textAlign: "center",
              borderRadius: 8,
              backgroundColor: "#f8d7da",
              color: "#dc3545",
              paddingInline: 25,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ marginBottom: 0, fontSize: 16 }}>
              {t("switch_chain", { chain: expectedChainName })}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ChainHandler;
