import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// import Navbar from './components/Navbar/Navbar';
import Home from './Pages/Home/Home';
import MarketDetails from './Pages/MarketDetails/MarketDetails';
import PolybetDAOPage from './Pages/PolybetDAOPage';
import PolybetFormPage from './Pages/PolybetFormPage';
import PBTStakeUnstakePage from './Pages/PBTStakeUnstakePage';
import PolybetClaimReward from './Pages/PolybetClaimReward';
import Footer from './components/Footer/Footer';
import NewFooter from './components/Footer/NewFooter';
import configData from './config.json';
import { isRestrictedCountry } from './helpers/utilities';
import { gsSetGlobalState } from './helpers/global-state';
import { authenticationService } from './services/authentication.service';
import { PrivateRoute } from './components/PrivateRoute';
import { getChainBaseUrl} from './helpers/web3';

function App() {
  const [globalState, setGlobalState] = useState({
      accounts : [],
      currentAddress : "", 
      web3 : null,
      balance : "",
      collateralBalance: "",
      collateralBalanceSmallestUnit: "",
      provider : null,
      connected : false,
      chainId : 0,
      installMetamask : false,
      loadedContracts: {
        collateralToken : null,
        conditionalToken : null,
        fixedProductMarketMaker: null,
        umaCtfAdapter: null
      },
      submissionBeingProcessed : false,
      submittedFor: '',
      isCountryRestricted : 0,
      metamaskConnected: false
  })

  useEffect(() => {
    const onLoad = async () => {
      await authenticationService.setLocalStorageIsRestrictedCountry();
      var isCountryRestricted = await isRestrictedCountry()
      gsSetGlobalState(globalState, setGlobalState, {isCountryRestricted})
    }
    onLoad();
  }, [])
  return (
    <>
      <BrowserRouter>
        {/* <Navbar globalState={globalState} setGlobalState={setGlobalState}/> */}
        <Switch>
          {/* <Route exact path="/">
            <Home globalState={globalState} setGlobalState={setGlobalState}/>
          </Route>
          <Route exact path="/market/:permalink">
            <MarketDetails globalState={globalState} setGlobalState={setGlobalState}/>
          </Route>  */}

        <Route exact path="/">
          <MarketDetails globalState={globalState} setGlobalState={setGlobalState} defaultPermalink="Who-will-win-US-election" />
        </Route>
        <Route path="/market/:permalink">
          <MarketDetails globalState={globalState} setGlobalState={setGlobalState} />
        </Route>



          {/* <PrivateRoute path="/market/:permalink"  component={MarketDetails}  globalState={globalState} setGlobalState={setGlobalState} /> */}


          <Route exact path="/DAO">
            <PolybetDAOPage globalState={globalState}/>  
          </Route>
          {/* <PrivateRoute component={PolybetFormPage} path="/stake-unstake/:permalink?" stakeType={configData.STAKE_TYPE_LP} globalState={globalState} setGlobalState={setGlobalState}/> */}
          <Route path="/stake-unstake/:permalink?">
            <PolybetFormPage stakeType={configData.STAKE_TYPE_LP} globalState={globalState} setGlobalState={setGlobalState}/>
          </Route> 
          {/* <PrivateRoute  component={PolybetFormPage} path="/uniswap-stake-unstake" stakeType={configData.STAKE_TYPE_UNISWAP} globalState={globalState} setGlobalState={setGlobalState}/> */}
          <Route path="/uniswap-stake-unstake">
            <PolybetFormPage stakeType={configData.STAKE_TYPE_UNISWAP} globalState={globalState} setGlobalState={setGlobalState}/>
          </Route> 
          {/* <PrivateRoute  component={PBTStakeUnstakePage} path="/pbt-stake-unstake" stakeType={configData.STAKE_TYPE_PBT} globalState={globalState} setGlobalState={setGlobalState}/> */}
          <Route path="/pbt-stake-unstake">
            <PBTStakeUnstakePage stakeType={configData.STAKE_TYPE_PBT} globalState={globalState} setGlobalState={setGlobalState}/>
          </Route> 
          {/* <Route path="/polybet_form" component={PolybetFormPage} /> */}
          <PrivateRoute path="/claim-rewards/:companyName/:isMarket?"  globalState={globalState} setGlobalState={setGlobalState} component={PolybetClaimReward} />
          {/* <Route path="/claim-rewards/:companyName"  globalState={globalState} setGlobalState={setGlobalState} component={PolybetClaimReward} /> */}
        </Switch>
        <NewFooter />
        {/* <Footer paddingTop={80} /> */}
      </BrowserRouter>
    </>
  );
}

export default App;
