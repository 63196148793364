import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Container from '../../components/Container/Container';
import theme from '../../styles/theme';
import { BreakpointUpMedium } from '../../styles/Breakpoints';
import { strToFixed, amountSToB } from '../../helpers/utilities'
import { useTranslation } from 'react-i18next';

const MarketCard = (props) => {
  const { t } = useTranslation();
  const {to, item, onMarketClick, marginBottom} = props;
  const {
    question,
    marketWeight = item.market_resolved==1 ? 'resolved' : '',
  } = item;

  const labelClassName = (str) => {
    if ( str === 'Scalar' ||  str === 'scalar') {
      return 'scalar'
    }
    if ( str === 'Resolved' || str === 'resolved') {
      return 'resolved'
    }
    return null
  }

  const truncate = (str, strLength = 10) => {
    return str.length > strLength ? str.substring(0, (strLength - 3)) + "..." : str;
  }

  const cardOnClick = (e) => {
    e.preventDefault();
    onMarketClick(e, to)
  }

  return (
    <Container>
      <Card style={{marginBottom: marginBottom}} onClick={(e) => cardOnClick(e)}>
        <CardHeader>
            {/* <img src={item.image_url} alt='TBD' /> */}
            <h2>{t(`markets.${item.id}.question`)}</h2>
            {marketWeight && (
              <MarketWLabel className={labelClassName(marketWeight)}>{marketWeight}</MarketWLabel>
            )}
        </CardHeader>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20}}>
          <img
            src={item.image_url} alt='Market Photo'
            style={{
              maxWidth: 230,
              height: 'auto',
            }}
          />
        </div>      
        <div style={{ display: 'flex', justifyContent: 'center'}}>
          <CardFooter>
              {/* <li>
                <div className="text-muted">{t('market_card.volume')}</div>
                <div>{item && `$${(amountSToB(item.collateral_volume)).toFixed(2)}`}</div>
              </li> */}
              {item && item.options.map(e => {
                return (<li key={`item-option-${e.id}`}>
                    <div className="text-muted">{t(`markets.${item.id}.options.${e.id}`)}</div>
                    <div>{isNaN(e.marginal_price) ? 'N/A' : `$${strToFixed(e.marginal_price)}`}</div>
                  </li>
                )
              })}
          </CardFooter>
        </div>      
      </Card>
    </Container>
  )
}

export default MarketCard;

const Card = styled(Link)`
  display: block;
  text-decoration: none;
  min-height: 240px;
  border-radius: 1px;
  padding: 15px;
  background-color: ${theme.tintGreen};
  border: 5px solid #157196;
  color: ${theme.darkBlue};
  margin-bottom: 15px;

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${theme.darkBlue};
  }

  ${BreakpointUpMedium} {
    margin-bottom: 0;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  padding-block: 20px;
  // padding-left: 200px !important;
  // padding-right: 200px !important;
  padding-inline: 20px;

  /*img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    object-fit: cover;
  }*/

  h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 25px;
    /* padding-left: 10px; */
    color: ${theme.darkBlue};
    font-weight:500;
    width:700px;
    text-align:center;
    line-height:40px;
  }

  ${BreakpointUpMedium} {
    /*margin-bottom: 30px;
    img {
      height: 50px;
      width: 50px;
      border-radius: 25px;
    }*/
    h2 {
      color: ${theme.darkBlue}
      font-size: 40px;
      /* padding-left: 16px; */
    }
  }
`;

const MarketWLabel = styled.span`
  height: fit-content;
  padding: .3rem .75rem;
  margin-left: .5rem;
  font-size: .85rem;
  border-radius: .125rem;
  background-color: #dff6e7;
  background-color: rgba(5,10,15,0.06);
  color: #42c772;

  &.scalar {
    background-color: #d6e0fc;
    color: #1652f0;
  }

  &.resolved {
    background-color: #fef2f3;
    color: #f3313d;
  }
`;

const CardFooter = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  font-size: 20px;
  font-weight: 500;
  padding-block-end: 20px;

  >li:not(:last-child) {
    padding-right: 30px;
    */ font-size: 14px; */
  }

  .text-muted {
    color: #8497a2;
  }

  /*${BreakpointUpMedium} {
    >li {
      font-size: 15px;
    }
  }*/
`;