import React, {useEffect, useState} from 'react';
import Web3 from "web3";
import styled from 'styled-components/macro';
import { BreakpointUpMedium } from '../styles/Breakpoints';
import Container from '../components/Container/Container';
import Button from '../components/UI/Button/Button';
import theme from '../styles/theme';
import { useParams} from 'react-router-dom';
import { doWeNeedToGetAllowance, getCollateralBalance, getMaxApproval, isLoggedInMetamask, loadFixedProductMarketMaker, getChainBaseUrl } from '../helpers/web3';
import { marketService } from '../services/market.service';
import {Web3Connect} from '../components/Web3Connect/Web3Connect';
import InputBoxWithMax from '../components/InputBoxWithMax/InputBoxWithMax';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import configData from '../config.json';
import {getEndBlock, getStakingTokenBalance, loadStakingTokenContract, loadPBTStakingContract, getPBTStakingContractAddress, doWeNeedToGetStakingTokenAllowance, getTokensAlreadyStakedBalance, getPbtEarned, getPbtBalance} from '../helpers/staking';
import { amountBToS, amountSToB, weiToEther, etherToWei} from '../helpers/utilities';
import Overlay from '../components/Modal/Overlay';
import Modal from '../components/Modal/Modal';
import { authenticationService } from '../services/authentication.service';
import { useTranslation } from 'react-i18next';
import { termsAndConditionService } from '../services/terms_and_condition.service';
import TermsAndConditionAgreement from '../components/TermsAndConditionAgreement/TermsAndConditionAgreement';

export default function PolybetFormPage (props) {
  const { t } = useTranslation();
  const [showOverlay, setShowOverlay] = useState(false);
  const { addToast } = useToasts();
  const [market, setMarket] = useState('');
  const [currentBlockNumber, setCurrentBlockNumber] = useState(0)
  const [endBlock, setEndBlock] = useState(0)
  const [sharesToStake, setSharesToStake] = useState('');
  const [sharesToUnstake, setSharesToUnstake] = useState('');
  const [liquidityBalance, setLiquidityBalance] = useState(0);
  const [tokensAlreadyStakedBalance, setTokensAlreadyStakedBalance] = useState(0);
  const [pbtEarned, setPbtEarned] = useState(0);
  const [pbtBalance, setPbtBalance] = useState(0);
  const  {globalState, setGlobalState, stakeType} = props;
  const { permalink } = useParams();
  const isUserLoggedIn = isLoggedInMetamask(globalState);
  const [stakingTokenContract, setStakingTokenContract] = useState({});
  const [refetchRecords, setRefetchRecords] = useState(0);
  const [transactionSuccessHash, setTransactionSuccessHash] = useState('');
  const [showTermsAndConditionAgreement, setShowTermsAndConditionAgreement] = useState(0);
  const [compLoadedContracts, setCompLoadedContracts] = useState({
    stakingTokenAddress : '',
    stakingTokenContract : null,
    pbtStakingContractAddress : '',
    pbtStakingContract : null
  });
  const history = useHistory();

  const [showCountryBlockedPopUp, setShowCountryBlockedPopUp] = useState(0);
  const isCountryRestricted = authenticationService.isCountryRestricted();
  const countryRestrictedBtnClicked = (e) => {
      setShowCountryBlockedPopUp(1)
  }
  
  const amountSToBOnTypeBasis = (value) => {
    if(stakeType==configData.STAKE_TYPE_LP) return amountSToB(value);

    return weiToEther(value);
  }

  const amountBToSOnTypeBasis = (value) => {
    if(stakeType==configData.STAKE_TYPE_LP) return amountBToS(value);

    return etherToWei(value);
  }

  const getTokenToBeStakedBalance = async(stakingTokenContract = null) => {
    if(stakingTokenContract===null) stakingTokenContract = compLoadedContracts.stakingTokenContract;
    const stakingTokenBalance = amountSToBOnTypeBasis(await getStakingTokenBalance(stakingTokenContract, globalState.currentAddress));

    setLiquidityBalance(stakingTokenBalance);
    return stakingTokenBalance;
  }
  
  const cGetPBTEarned = async(poolId=-1, pbtStakingContract = null) => {
    if(pbtStakingContract===null) pbtStakingContract = compLoadedContracts.pbtStakingContract;
    if(poolId===-1) poolId = getPoolId();


    const pbtEarned = await getPbtEarned(poolId, pbtStakingContract, globalState.currentAddress);
    setPbtEarned(weiToEther(pbtEarned));
  }

  const getStakingCurrentAndEndBlock = async(pbtStakingContract = null) => {
    if(pbtStakingContract===null) pbtStakingContract = compLoadedContracts.pbtStakingContract;

    if(endBlock==0){
      const endBlock = await getEndBlock(stakeType, pbtStakingContract, globalState.currentAddress);
      setEndBlock(endBlock);
    }

    if(globalState.web3!=null){
      setCurrentBlockNumber(await globalState.web3.eth.getBlockNumber())
    }
  }

  const getTokenAlreadyStakedBal = async(poolId = -1, pbtStakingContract = null) => {
    if(pbtStakingContract===null) pbtStakingContract = compLoadedContracts.pbtStakingContract;
    if(poolId===-1) poolId = getPoolId();

    const alreadyStakedBal = amountSToBOnTypeBasis(await getTokensAlreadyStakedBalance(poolId, pbtStakingContract, globalState.currentAddress));
    setTokensAlreadyStakedBalance(alreadyStakedBal);
    return alreadyStakedBal;
  }

  const getPoolId = (objMarket = null) => {
    if(objMarket===null) objMarket = market;
    if(stakeType==configData.STAKE_TYPE_LP){
      return objMarket.reference_staking_pool_id;
    }else if(stakeType==configData.STAKE_TYPE_UNISWAP){
      return configData.UNISWAP_PBT_STAKING_CONTRACT_POOL_ID;
    }else if(stakeType==configData.STAKE_TYPE_PBT){
      return configData.PBT_TOKEN_STAKING_CONTRACT_POOL_ID;
    }
  }

  const resetFormFields = () => {
    setSharesToStake('')
    setSharesToUnstake('')
  }

  const getMarket = async () => {
    if(stakeType==configData.STAKE_TYPE_LP){
      const fetchedMarket = await marketService.getMarketDetails(permalink).catch(err => {throw err;})
      setMarket(fetchedMarket);
      return fetchedMarket;
    }

    return null;
  }
  
  useEffect(() => {
    const getStakingTokenAddress = (market) => {
      if(stakeType==configData.STAKE_TYPE_LP){
          return market.fpmm_market_maker_address;
      }else if(stakeType==configData.STAKE_TYPE_UNISWAP){
        return configData.addresses.uniswapStakingToken;
      }else if(stakeType==configData.STAKE_TYPE_PBT){
        return configData.addresses.PbtTokenContract;
      }
    }

    const loadContracts = async(market) => { 
      const strStakingTokenAddress = getStakingTokenAddress(market);
      const stakingTokenContract = await loadStakingTokenContract(globalState, strStakingTokenAddress, stakeType);
      const pbtStakingContractAddress = getPBTStakingContractAddress(stakeType, market);
      const pbtStakingContract = await loadPBTStakingContract(globalState, stakeType, market);
      const contracts = {
        stakingTokenAddress : strStakingTokenAddress,
        stakingTokenContract : stakingTokenContract,
        pbtStakingContract : pbtStakingContract,
        pbtStakingContractAddress : pbtStakingContractAddress
      };
      setCompLoadedContracts({
        ...compLoadedContracts,
        ...contracts
      });
      return contracts;
    }

    const onLoad = async () => {
      if(!isUserLoggedIn) return 0;

      resetFormFields();
      
      const market = await getMarket().catch(err => history.push("/DAO"));
      const poolId = getPoolId(market);
      const contracts = await loadContracts(market);
      const stakingTokenBalance = await getTokenToBeStakedBalance(contracts.stakingTokenContract);
      const tokensAlreadyStackedBalance = await getTokenAlreadyStakedBal(poolId, contracts.pbtStakingContract);
      const pbtEarned = await cGetPBTEarned(poolId, contracts.pbtStakingContract, globalState.currentAddress);
      const pbtBalance = await getPbtBalance(globalState, globalState.currentAddress);
      setPbtBalance(weiToEther(pbtBalance));
      await getStakingCurrentAndEndBlock(contracts.pbtStakingContract);

      //set interval for updating data 
      updatePBTEarnedInterval(poolId, contracts.pbtStakingContract);
    }
    
    onLoad();
  },[isUserLoggedIn, refetchRecords]);

  
  const connectWalletFirst = () => {
    if(!isUserLoggedIn){
      addToast(
        "Connect wallet first.", 
        { appearance: "error" }
        );
      return 1;
    }
    return 0;
  }
  
  const onUnstakeMaxClicked  = async (e) => {
    if(connectWalletFirst()===1) return 0;
    
    const tokenToBeStakedBalance = await getTokenAlreadyStakedBal();
    setSharesToUnstake(tokenToBeStakedBalance);
  }

  const onUnstakeInputChanged  = (e) => {
    if(connectWalletFirst()===1) return 0;
    
    const inputtedValue = e.target.value;

    if(inputtedValue > tokensAlreadyStakedBalance) return 0;
      
    setSharesToUnstake(inputtedValue)
  }

  const onStakeMaxClicked  = async(e) => {
    if(connectWalletFirst()===1) return 0;
    
    const tokenToBeStakedBalance = await getTokenToBeStakedBalance();
    setSharesToStake(tokenToBeStakedBalance);
  }

  const onStakeInputChanged  = (e) => {
    if(connectWalletFirst()===1) return 0;
    
    const inputtedValue = parseFloat(e.target.value);
    if(inputtedValue > liquidityBalance) return 0;
    
    setSharesToStake(inputtedValue)
  }

  
  const beforeSend = () => {
    setShowOverlay(true);
  }
  
  const afterSend = () => {
    resetFormFields();
    setShowOverlay(false);
  }

  const validateStake = () => {
    if(sharesToStake=="" || sharesToStake==0){
      addToast(
        t('enter_shares_to_stake'), 
        { appearance: "error" }
      );
      return 0;
    }

    return 1;
  }

  const stake = async (withdrawReward=0) => {
    try{
        const ownerAddress = globalState.currentAddress;
        const spenderAddress = compLoadedContracts.pbtStakingContractAddress;
        const stakingTokenContract = compLoadedContracts.stakingTokenContract;
        const pbtStakingContract = compLoadedContracts.pbtStakingContract;
        let sharesToBeStakedSmall = 0;
        if(withdrawReward===0){
          const sharesToBeStakedBig = sharesToStake;
          sharesToBeStakedSmall = amountBToSOnTypeBasis(sharesToStake);
          const maxApproval = getMaxApproval();
           
          const currentLiquidityBalance = await getTokenToBeStakedBalance();
          
          if(currentLiquidityBalance < sharesToBeStakedBig){
            addToast(
              "You are trying to stake more than your balance.", 
              { appearance: "error" }
            );
            throw "You are trying to stake than your balance.";
          }
          
          const approvalNeeded = await doWeNeedToGetStakingTokenAllowance(ownerAddress, spenderAddress, sharesToBeStakedSmall, stakingTokenContract)
          if(approvalNeeded){
            beforeSend();
            const receipt = await stakingTokenContract.methods.approve(spenderAddress, maxApproval)
              .send({from: ownerAddress})
              .on('transactionHash', function(hash){
                setShowOverlay(false);
              })
              .on('error', function(error, receipt) {
                addToast(
                  error.message, 
                  { appearance: "error" }
                );
                setShowOverlay(false);
                throw error;
              });
          }
        }
        const poolId = getPoolId();
        
        beforeSend();
        pbtStakingContract.methods
          .deposit(sharesToBeStakedSmall)
          .send({from: ownerAddress})
          .on('transactionHash', function(hash){
            setTransactionSuccessHash(hash);
            afterSend();
          })
          .on('confirmation', function(confirmationNumber, receipt){
            if(confirmationNumber==configData.SHOW_RESULT_AFTER_NTH_CONFIRMATION){
              addToast(
                t('stats_updated'), 
                { appearance: "success" }
              );
              setRefetchRecords(new Date().getTime())
            }
          })
          .on('error', function(error, receipt) {
            addToast(
              error.message, 
              { appearance: "error" }
            );
            afterSend();
            throw error;
          });
    }catch(error){
      throw error;
    }
  }

  const closeTermAndCondition = (dontThrow=0) => {
    setShowTermsAndConditionAgreement(0)
    if(dontThrow==0)
      throw 'agreement not signed';
  }

  const askToSignAgreement = async() => {
    const hasUserSigned = await termsAndConditionService.hasUserSigned(globalState.currentAddress, configData.sign_terms_message);
    if(hasUserSigned==0){
      setShowTermsAndConditionAgreement(1)
      throw 'agreement not signed';
    }
  }

  const validateAndStake = async (event) => {
    // event.preventDefault();

    if(!validateStake()) return 0;

    try{
      closeTermAndCondition(1);
      await askToSignAgreement();
      await stake();

    }catch(error){
      if(error=='agreement not signed'){
        setShowOverlay(false);
      }else{
        afterSend()  
      }  
    }
  }

  const validateUnstake = () => {
    if(sharesToUnstake=="" || sharesToUnstake==0){
      addToast(
        t('enter_shares_to_unstake'), 
        { appearance: "error" }
      );
      return 0;
    }

    return 1;
  }

  const unstake = async () => {
    try{
        const ownerAddress = globalState.currentAddress;
        const spenderAddress = compLoadedContracts.pbtStakingContractAddress;
        const stakingTokenContract = compLoadedContracts.stakingTokenContract;
        const pbtStakingContract = compLoadedContracts.pbtStakingContract;
        const sharesToBeUnstakedBig = sharesToUnstake;
        const sharesToBeUntakedSmall = amountBToSOnTypeBasis(sharesToUnstake);
        const poolId = getPoolId();
        
        const currentStakeSharesBalance = await getTokenAlreadyStakedBal();
        
        if(currentStakeSharesBalance < sharesToBeUnstakedBig){
          addToast(
            "You are trying to unstake more than you have staked.", 
            { appearance: "error" }
          );
          throw "You are trying to unstake more than you have staked.";
        }
        
        beforeSend();
        pbtStakingContract.methods
          .withdraw(sharesToBeUntakedSmall)
          .send({from: ownerAddress})
          .on('transactionHash', function(hash){
            setTransactionSuccessHash(hash);
            afterSend();
          })
          .on('confirmation', function(confirmationNumber, receipt){
            if(confirmationNumber==configData.SHOW_RESULT_AFTER_NTH_CONFIRMATION){
              addToast(
                t('stats_updated'), 
                { appearance: "success" }
              );
              setRefetchRecords(new Date().getTime())
            }
          })
          .on('error', function(error, receipt) {
            addToast(
              error.message, 
              { appearance: "error" }
            );
            afterSend();
            throw error;
          });
    }catch(error){
      throw error;
    }
  }

  const validateAndUnstake = async (event) => {
    event.preventDefault();
    if(!validateUnstake()) return 0;
    await unstake();
  }

  const updatePBTEarnedInterval = (poolId, pbtStakingContract) => {
    clearInterval();
    
    setInterval(async(poolId, pbtStakingContract) => {
      await getStakingCurrentAndEndBlock(pbtStakingContract);
      const pbtEarned = await cGetPBTEarned(poolId, pbtStakingContract);
    }, 10000, poolId, pbtStakingContract);
  }

  return (
    <div style={{ paddingTop: 50, backgroundColor: 'hsla(245, 75%, 90%, 80%)'}}>
      <Container>
        <Banner>
          <h2 style={{ marginBottom: 15 }}>
          {stakeType==configData.STAKE_TYPE_LP ? t('polybet_dao.market_staking.title') : ''}
          {stakeType==configData.STAKE_TYPE_UNISWAP ? t('polybet_dao.uniswap_pool_staking.title') : ''}
          </h2>
          {/* <p style={{ color: '#475a62' }}>
            Your Metamask Wallet is your gateway to ICE Poker, the metaverse, and beyond
          </p> */}
        </Banner>
        <Box>
          <Column>
            <ColumnTitle>{t('stake')}</ColumnTitle>
            <Group>
              <div className="text">{t('balance')}: {liquidityBalance} {t('share', {count:liquidityBalance > 1 ? 2 : 1})}</div>
              <InputBoxWithMax
                value={sharesToStake}
                onChange={(e) => onStakeInputChanged(e)}
                onClick={(e) => onStakeMaxClicked(e)}
                maxItemName={t('share', {count: 2})}
              />
              {/* <InputBox>
                <input
                  className="form-input"
                  type="number"
                  placeholder="0"
                  min="0"
                  step="0.000001"
                  value={0}
                  onChange={()=> null}
                />
                <span>USDC | <button>Max</button></span>
              </InputBox> */}
            </Group>
            
            { isUserLoggedIn==0 && isCountryRestricted==0 &&
              <Web3Connect appearance="staking" globalState={globalState} setGlobalState={setGlobalState}></Web3Connect>
            }
            {
              isUserLoggedIn==1 && isCountryRestricted==0 &&
              <Button disabled={(currentBlockNumber > endBlock) ? true : false} appearance="primary" onClick={validateAndStake}> {t('deposit')}</Button> 
            }
            {isCountryRestricted==1 && <Button appearance="danger" onClick={(e) => countryRestrictedBtnClicked(e)}>{t('unavailable')}</Button>}
          </Column>

          <Column>
            <ColumnTitle>{t('unstake')}</ColumnTitle>
            <Group>
              <div className="text">{t('balance')}: {tokensAlreadyStakedBalance} {t('share', {count:tokensAlreadyStakedBalance > 1 ? 2 : 1})}</div>
              <InputBoxWithMax
                value={sharesToUnstake}
                onChange={(e) => onUnstakeInputChanged(e)}
                onClick={(e) => onUnstakeMaxClicked(e)}
                maxItemName={t('share', {count: 2})}
              />
            </Group>
            { isUserLoggedIn==0 && isCountryRestricted==0 &&
              <Web3Connect appearance="staking" globalState={globalState} setGlobalState={setGlobalState}></Web3Connect>
            }
            {
              isUserLoggedIn==1 && isCountryRestricted==0 &&
              <Button appearance="primary" onClick={validateAndUnstake}>{t('withdraw')}</Button> 
            }
            {isCountryRestricted==1 && <Button appearance="danger" onClick={(e) => countryRestrictedBtnClicked(e)}>{t('unavailable')}</Button>}
          </Column>
          <Column>
            <ColumnTitle>{t("performance")}</ColumnTitle>
            <Group>
              <div className="text" style={{ marginBottom: 5 }}>{t("pbt_earned")}: {pbtEarned} {t('share', {count : pbtEarned > 1 ? 2 : 1})}</div>
              <div className="text" style={{ marginBottom: 5 }}>{t("pbt_balance")}: {pbtBalance} {t('share', {count : pbtBalance > 1 ? 2 : 1})}</div>
              <div className="text" style={{ marginBottom: 5 }}>&nbsp;</div>
            </Group>
            { isUserLoggedIn==0 && isCountryRestricted==0 &&
              <Web3Connect appearance="staking" globalState={globalState} setGlobalState={setGlobalState}></Web3Connect>
            }
            {
              isUserLoggedIn==1 && isCountryRestricted==0 &&
              <Button appearance="primary" disabled={pbtEarned>0 ? false : true} onClick={stake}>{t("claim")}</Button> 
            }
            {isCountryRestricted==1 && <Button appearance="danger" onClick={(e) => countryRestrictedBtnClicked(e)}>{t("unavailable")}</Button>}
          </Column>
        </Box>
      </Container>
      {showOverlay && <Overlay/>}
      {transactionSuccessHash!='' && (
        <Modal
          title={t('transaction_hash')}
          align="center"
          close={() => setTransactionSuccessHash('')}
        >
          <div>
            <p style={{wordWrap:'break-word', wordBreak: 'break-all'}}>
              {t('transaction_hash')}: {transactionSuccessHash}
            </p>
            <a href={getChainBaseUrl(globalState.chainId) + `/tx/${transactionSuccessHash}`} target="_blank">{t('visit_etherscan_to_view_transaction')}</a>
          </div>          
        </Modal>
      )}

      {showTermsAndConditionAgreement===1 && 
        <TermsAndConditionAgreement globalState={globalState} closeAgreement={closeTermAndCondition} signTermsClicked={(e) => validateAndStake(e)}/>
      }


      {showCountryBlockedPopUp===1 && (
        <Modal
          title={t('restricted_country_title_message')}
          align="center"
          close={() => setShowCountryBlockedPopUp(0)}
        >
          <div>
            <p style={{wordWrap:'break-word', wordBreak: 'break-all'}}>
            {t('restricted_country_message')}
            </p>
          </div>          
        </Modal>
      )}
    </div>
  )
}

const Banner = styled.div`
  color: #296588;
  padding: 30px 20px;
  margin-bottom: 10px;
  text-align: center;

  ${BreakpointUpMedium} {
    padding: 30px;
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  border-radius: 8px; 
  border: 2px solid #15719D;
  background-color: rgba(255,255,255, 0.4);

  ${BreakpointUpMedium} {
    flex-direction: row;
    align-items: center;
  }
`

const Column = styled.div`
  flex-basis: 100%;
  max-width: 100%;
  min-height: 195px;
  padding: 0 30px;

  &:not(:last-child) {
    border-bottom: 1px solid #7ea2b3;
    margin-bottom: 20px;
    padding-bottom: 30px;
  }

  ${BreakpointUpMedium} {
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);

    .two-column & {
      flex-basis: calc(100% / 2);
      max-width: calc(100% / 2);
    }

    &:not(:last-child) {
      border-right: 1px solid #7ea2b3;
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`
const ColumnTitle = styled.h4`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  color: #157196;
`

const Group = styled.div`
  margin: 12px 0 12px;

  .text {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 15px;
    color: #475a62;
  }
`

const InputBox = styled.div`
  position: relative;

  .form-input {
    line-height: 1.4375;
    border: 1px solid #ccc;
    padding: .5625rem .75rem .4375rem;
    width: 100%;
    border-radius: 2px;
    border: 1px solid ${theme.darkBlue};
  }

  span {
    display: block;
    position: absolute;
    padding: 0 .5rem;
    top: 6px;
    right: 2px;
    background-color: #fff;

    button{
      color: ${theme.darkBlue};
      text-decoration: underline;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
`