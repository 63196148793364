import configData from "../config.json";
import { handleResponse } from '../helpers/handle-response';

export const termsAndConditionService = {
    hasUserSigned,
    create
};

async function create(walletAddress, message, signature){
    const data = new URLSearchParams();
    data.append('wallet_address', walletAddress);
    data.append('message', message);
    data.append('signature', signature);

    const requestOptions = { method: 'POST', body: data};
    const termsAndConditionResponse = await fetch(`${configData.API_URL}/terms-and-conditions/create`, requestOptions).then(handleResponse);
    return termsAndConditionResponse;
}

async function hasUserSigned(walletAddress, message) {
    const data = new URLSearchParams();
    data.append('wallet_address', walletAddress);
    data.append('message', message);

    const requestOptions = { method: 'POST', body: data};
    const hasUserSigned = await fetch(`${configData.API_URL}/terms-and-conditions/has-user-signed`, requestOptions).then(handleResponse);
    return hasUserSigned.hasUserSignedTermsAndConditions;
}


// function getMarketDataFromTheGraph(fpmmId) {
//     const requestOptions = { 
//         method: 'POST',
//         query: `
//         {
//             fixedProductMarketMakers(where: {id:fpmmId}) 
//             {
//                 id  
//                 liquidityMeasure
//                 outcomeTokenMarginalPrices
//                 collateralVolume
//                 outcomeTokenAmounts
//             }
//         }
//         `
//     };
//     return fetch(`${configData.THE_GRAPH_API_URL}`, requestOptions).then(handleResponse);
// }