import React from 'react';
import { Route, Redirect } from 'react-router-dom';


import { authenticationService } from '../services/authentication.service';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const isRestrictedCountry = authenticationService.isCountryRestricted();
        if (isRestrictedCountry===1) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }
        // authorised so return component
        return <Component {...props}  {...rest} />
    }} />
)