import React from "react";
import styled from "styled-components";

export default function SubHero() {
  return (
    <Section>
      <Div1170>
        <Article>
          <H2>Place Your Bets</H2>

          <H3>
            DegenBets is a prediction market platform that lets users bet on the
            outcome of events.
          </H3>
        </Article>
      </Div1170>
    </Section>
  );
}

const Section = styled.section`
  background-color: #1cc8ee;
`;

const Div1170 = styled.div`
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin-inline: auto;
`;

const Article = styled.article`
  padding-top: max(25px, min(calc(1vw * (50 * 100 / 1440)), 50px));
  padding-bottom: max(25px, min(calc(1vw * (50 * 100 / 1440)), 50px));
`;

const H4 = styled.h4`
  font-weight: 500;
  text-align: center;
  color: #fff;
  font-size: max(16px, min(calc(1vw * (20 * 100 / 1440)), 20px));
  margin-bottom: max(10px, min(calc(1vw * (20 * 100 / 1440)), 20px));
`;

const H2 = styled.h2`
  font-weight: 900;
  text-align: center;
  color: #fff;
  font-size: max(22px, min(calc(1vw * (32 * 100 / 1440)), 32px));
`;

const H3 = styled.h3`
  font-weight: 700;
  text-align: center;
  color: #fff;
  font-size: max(22px, min(calc(1vw * (32 * 100 / 1440)), 32px));
`;
