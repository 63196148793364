import FixedProductMarketMaker from "../abis/FixedProductMarketMaker.json"
import UniswapToken from "../abis/UniswapToken.json"
import OtherTokenStakingABI from "../abis/OtherTokenStaking.json"
import PBTStakingABI from "../abis/PBTStaking.json"
import PBT from "../abis/PBT.json"
import configData from "../config.json"
import { loadConditionResolverUsingReality, loadFixedProductMarketMaker } from './web3'

const BN = require('bn.js');

export const isConditionalTokenApprovedForAll = async (globalState, owner, operator) => {
    try {
        const isApprovedForAll = await globalState.loadedContracts.conditionalToken.methods.isApprovedForAll(owner, operator).call({from: owner});
        return isApprovedForAll;
    } catch (error) {
        throw error;
    }
}


export const loadUniswapContract = async (globalState, address) => {
    try{
        const result = await new globalState.web3.eth.Contract(UniswapToken, configData.addresses.uniswapStakingToken);
        return result;
    }catch(e){
        throw e;
    }
}

export async function loadStakingTokenContract(globalState, contractAddress, stakeType){
    if(stakeType==configData.STAKE_TYPE_LP)
        return await loadFixedProductMarketMaker(globalState, contractAddress);               
    else if(stakeType==configData.STAKE_TYPE_UNISWAP)
        return await loadUniswapContract(globalState);               
    else if(stakeType==configData.STAKE_TYPE_PBT)
        return await loadPBTContract(globalState);                
}

export async function loadPBTStakingContract(globalState, stakeType, market){
    try{
        const pbtStakingContractAddress = getPBTStakingContractAddress(stakeType, market);
        if(stakeType==configData.STAKE_TYPE_PBT)
            var ABI = PBTStakingABI;
        else
            var ABI = OtherTokenStakingABI;
            
        const result = await new globalState.web3.eth.Contract(ABI, pbtStakingContractAddress);
        return result;
    }catch(e){
        throw e;
    }       
}

export function getPBTStakingContractAddress(stakeType, market) {
    if(stakeType==configData.STAKE_TYPE_LP){
        return market.staking_contract_address;
        // return configData.addresses.marketStakingContract;
    }else if(stakeType==configData.STAKE_TYPE_UNISWAP){
        return configData.addresses.uniswapStakingContract;
    }else if(stakeType==configData.STAKE_TYPE_PBT){
        return configData.addresses.pbtStakingContract;
    }
}

export const getStakingTokenBalance = async (stakingContract, from) => {
    try {
        return await stakingContract.methods.balanceOf(from).call({from: from})
    } catch (error) {
        throw error;
    }
}

export async function loadPBTContract(globalState){
    try{
        const result = await new globalState.web3.eth.Contract(PBT, configData.addresses.PbtTokenContract);
        return result;
    }catch(e){
        throw e;
    }       
}

export const getPbtBalance = async (globalState, userAddress, PBTContract=null) => {
    if(PBTContract===null) PBTContract = await loadPBTContract(globalState);

    try {
        return await PBTContract.methods.balanceOf(userAddress).call({from: userAddress})
    } catch (error) {
        throw error;
    }
}

export async function getTokensAlreadyStakedBalance(poolId, stakingContract, userWalletAddress){
    try{
        const result = await stakingContract.methods.userInfo(userWalletAddress).call({from: userWalletAddress})
        var amount = result.amount;
        amount = amount.split('.')[0]
        // return parseInt(result.amount);
        return amount;
    }catch(error){
        throw error;
    }
}

export async function getPBTAlreadyStakedBalance(stakingContract, userWalletAddress){
    try{
        const result = await stakingContract.methods.userInfo(userWalletAddress).call({from: userWalletAddress})
        var amount = result.amount;
        amount = amount.split('.')[0]
        return amount;
        // return parseInt(result.amount);
    }catch(error){
        throw error;
    }
}

export async function getPbtEarned(poolId, stakingContract, userWalletAddress){
    try{
        const result = await stakingContract.methods.pendingPbt(userWalletAddress).call({from: userWalletAddress})
        return result;
    }catch(error){
        throw error;
    }
}

export async function getEndBlock(stakeType, stakingContract, userWalletAddress){
    try{
        var endBlock;

        if(stakeType==configData.STAKE_TYPE_PBT){
            endBlock = await stakingContract.methods.endBlock().call({from: userWalletAddress})
        }else{
            var result = await stakingContract.methods.poolInfo().call({from: userWalletAddress})
            endBlock = result.endBlock;
        }
        return endBlock;
    }catch(error){
        throw error;
    }
}

export async function getPbtEarnedFromPBTStaking(stakingContract, userWalletAddress){
    try{
        const result = await stakingContract.methods.pendingPbt(userWalletAddress).call({from: userWalletAddress})
        return result;
    }catch(error){
        throw error;
    }
}

export const getAllowance = async (owner, spender, from, stakingTokenContract) => {
    try{
        const result = await stakingTokenContract.methods.allowance(owner, spender).call({from: from})
        return parseInt(result);
    }catch(error){
        throw error;
    }
}

export const doWeNeedToGetStakingTokenAllowance = async (owner, spender, amount, stakingTokenContract) => {
    try{
        const result = await getAllowance(owner, spender, owner, stakingTokenContract);
        return amount <= result ? false : true;
    }catch(error){
        throw error;
    }
}


