import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { BreakpointUpMedium } from '../../styles/Breakpoints';
import theme from '../../styles/theme';

export default function CardLight ({ cardTitle = '', to, item, onCardClick }) {

  const {
    title = item.question,
    imageUrl = item.image_url,
    useRelativeImagePath = item.hasOwnProperty('is_image_path_relative') && item.is_image_path_relative===1 ? 1 : 0,
    reward_qty = 0,
    apy = 1,
    liquidity = 2,
    balance = 3,
  } = item;

  const truncate = (str, strLength = 80) => {
    return str.length > strLength ? str.substring(0, (strLength - 3)) + "..." : str;
  }

  const cardOnClick = (e) => {
    e.preventDefault();
    onCardClick(e, to)
  }

  return (
    <Column>
      <Card onClick={(e) => cardOnClick(e)}>
        <Header>
          <img src={useRelativeImagePath===1 ? "./"+imageUrl : imageUrl} alt={title} />
          <h3>{cardTitle != '' ? cardTitle : truncate(title)}</h3>
          {reward_qty!=0 && <h3>{reward_qty}</h3>}
        </Header>
        {/* <Footer>
          <List>
            <li>
              <div className="text-muted">APY(%)</div>
              <div>{`${apy}`}</div>
            </li>
            <li>
              <div className="text-muted">liquidity</div>
              <div>{`${liquidity}`}</div>
            </li>
            <li>
              <div className="text-muted">no</div>
              <div>{`$${balance}`}</div>
            </li>
          </List>
        </Footer> */}
      </Card>
    </Column>
  )
}

const Column = styled.div`
  flex-basis: 100%; 
  max-width: 100%;
  padding-right: 10px;
  padding-left: 10px;

  @media (max-width: 580px) {
    flex-basis: 100%; 
    max-width: 100%;
  }
`

const Card = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  min-height: 120px;
  padding: 18px;
  margin-bottom: 20px;
  border: 5px solid #157196;
  color: ${theme.darkBlue};
  background-color: #EBF9F4;
  cursor: pointer;
 
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${theme.darkBlue};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  img {
    /*height: 100px;*/
    max-width: 100px;
    // border-radius: 50em;
    /*object-fit: cover;*/
    margin-bottom: 15px;
  }

  h3 {
    font-size: 18px;
    color: ${theme.darkBlue};
    margin-bottom: 0;
    text-align: center;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
  }

  ${BreakpointUpMedium} {
    margin-bottom: 20px;

    img {
      /*height: 60px;
      width: 60px;
      border-radius: 30px;*/
      max-width: 140px;
    }

    h3 {
      font-size: 25px;
      font-weight:500;
    }
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;

  > li {
    position: relative;
    text-align: center;
  }

  > li:not(:last-child) {
    padding-right: 20px;
    margin-right: 20px;
    font-size: 15px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 2%;
      margin-top: -15px;
      height: 30px;
      width: 1px;
      background-color: #8497a2;
    }
  }

  .text-muted {
    font-weight: 500;
    /* color: #232b1b; */
    color: #8497a2;
    text-transform: uppercase;
    font-size: 12px;
  }

  ${BreakpointUpMedium} {
    > li {
      font-size: 16px;
    }
  }
`;