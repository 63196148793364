import configData from "../config.json";
import { handleResponse } from '../helpers/handle-response';

export const marketService = {
    getPublishedMarkets,
    getMarketDetails,
    getMarketDataFromTheGraph,
    getPublishedMarketsWithStakingEnabled
};

function getPublishedMarkets() {
    const requestOptions = { method: 'GET'};
    return fetch(`${configData.API_URL}/market/get-published`, requestOptions).then(handleResponse);
}
function getPublishedMarketsWithStakingEnabled() {
    const requestOptions = { method: 'GET'};
    return fetch(`${configData.API_URL}/market/get-published-with-staking-enabled`, requestOptions).then(handleResponse);
}

function getMarketDetails(permalink) {
    const requestOptions = { method: 'GET'};
    return fetch(`${configData.API_URL}/market/get/${permalink}`, requestOptions).then(handleResponse);
}

function getMarketDataFromTheGraph(fpmmId) {
    const requestOptions = { 
        method: 'POST',
        query: `
        {
            fixedProductMarketMakers(where: {id:fpmmId}) 
            {
                id  
                liquidityMeasure
                outcomeTokenMarginalPrices
                collateralVolume
                outcomeTokenAmounts
            }
        }
        `
    };
    return fetch(`${configData.THE_GRAPH_API_URL}`, requestOptions).then(handleResponse);
}