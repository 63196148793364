import React from 'react';
import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';

import theme from '../../../styles/theme';
import Container from '../../Container/Container';

const CardGeneral = ({ appearance, title, mb }) => {
  return (
    <Container>
      <Card mb={mb}>
        <CardHeader><h4>{title}</h4></CardHeader>
      </Card>
    </Container>
  )
}

export default CardGeneral;

CardGeneral.propTypes = {
  appearance: PropTypes.oneOf(['purple', 'yellow', 'green']),
};

CardGeneral.defaultProps = {
  appearance: 'purple'
}


const Card = styled.div.attrs( props => ({
  className: 'card-general',
  mb: props.mb || 40,
}))`
  margin-bottom: ${props => props.mb}px;
  background-color: white;
`;

const CardHeader = styled.div`
  padding: 12px 30px;
  border: 5px solid ${theme.darkBlue};
  border-bottom: 0; 
  background-color: #e3fdff;
  text-align:center;

  h4 {
    font-weight: 700;
    font-size: 25px;
    color: ${theme.darkBlue};
    line-height: 1;
    margin: 0;
  }
`;