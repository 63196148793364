import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { BreakpointDownMedium, BreakpointUpContainer } from '../../styles/Breakpoints';
import BlockSpinner from './BlockSpinner'
import BlurOverlay from './BlurOverlay'

const Container = ({noPaddingSmall, children}) => {
  return (
    <SiteContainer
      noPaddingSmall={noPaddingSmall}
    >
    {children}
    </SiteContainer>
  )
}

export default Container;

Container.propTypes = {
  noPaddingSmall: PropTypes.bool,
};

Container.defaultProps = {
  noPaddingSmall: false,
};

const SiteContainer = styled.div.attrs({ className: 'container-center'})`
  position: relative;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  ${BreakpointUpContainer} {
    max-width: 1028px;
    margin-left: auto;
    margin-right: auto;
  }

  ${({ noPaddingSmall }) => noPaddingSmall && css`
    ${BreakpointDownMedium} {
      padding-left: 0;
      padding-right: 0;
    }
  `}
`