import React from "react";
import styled from "styled-components/macro";

export default function CheckboxButton({
  amount,
  label,
  checked = false,
  styleName = "default",
  imgSrc = "",
  ...props
}) {
  const getPrice = (price) => {
    if (price == null) return "";
    return isNaN(amount) ? "N/A" : `$${amount}`;
  };

  return (
    <Label>
      <InputWrap>
        <input type="checkbox" {...props} checked={checked} />
        <Btn className={`cbb ${styleName}`}>
          <img src={imgSrc} alt={label} />
          <div style={{ display: "inline-flex", gap: 10 }}>
            <Span>
              {label} | {getPrice(amount)}
            </Span>
          </div>
        </Btn>
      </InputWrap>
    </Label>
  );
}

// styles

const Label = styled.label`
  margin: 0;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  line-height: 1;
`;



const Btn = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: max(12px, min(calc(1vw * (16 * 100 / 1440)), 16px));
  height: max(44px, min(calc(1vw * (64 * 100 / 1440)), 64px));
  width: max(160px, min(calc(1vw * (231 * 100 / 1440)), 231px));
  padding: 8px;
  border-radius: 50em;
  background-color: oklch(90% 0.128 1.99);
  transition: all 150ms;

  img {
    width: 100%;
    max-width: max(28px, min(calc(1vw * (44 * 100 / 1440)), 44px));
    height: auto;
  }

  &.green {
    background-color: oklch(90% 0.100 165.46);
  }
`;

const InputWrap = styled.div`
 
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + .cbb {
      background-color: oklch(62.87% 0.228 1.99);

      &.green {
        background-color: oklch(69.92% 0.146 165.46);
      }
    }
  }
`;

const Span = styled.span`
  color: #fff;
  font-weight: 700;
  font-size: max(14px, min(calc(1vw * (20 * 100 / 1440)), 20px));
`;
