import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../../styles/theme';

const ButtonLink = ({
  children,
  appearance,
  textSize,
  textColor,
  ...props
}) => {
  return (
    <LinkButton
      appearance={appearance}
      textSize={textSize}
      textColor={textColor}
      {...props}
    >
      {children}
    </LinkButton>
  )
}
export default ButtonLink

ButtonLink.propTypes = {
  appearance: PropTypes.oneOf(['primary', 'secondary', 'default']),
  textSize: PropTypes.number,
  textColor: PropTypes.string,
};

const LinkButton = styled.a.attrs(props => ({
  className: 'button',
  textColor: props.textColor || theme.buttonText,
  textSize: props.textSize || '16',
  fontWeight: props.fontWeight || '500',
}))`
  font-weight: ${props => props.fontWeight};
  color: ${props => props.textColor};
  font-size: ${props => props.textSize}px;
  border: 1px solid ${theme.tintPink};
  background-color: ${theme.tintPink};
  padding: 6px 30px;
  box-shadow: 0px 6px 0px 1px ${theme.buttonText};

  ${({ appearance }) => appearance === 'primary' && css`
    border: 1px solid ${theme.tintYellow};
    background-color: ${theme.tintYellow};
  `}

  &:hover,
  &:focus {
    box-shadow: 0px 6px 0px -3px ${theme.buttonText};
  }
`