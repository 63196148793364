import configData from "../config.json";
import axios from 'axios';
import { web3UtilsBn, web3UtilsHexToAscii } from "../helpers/web3";
import { amountSToB } from "../helpers/utilities";
import { getUmaAnswerFromString } from "../helpers/realityeth";
import Web3 from "web3";
import UmaCtfAdapterAbi from "../abis/UmaCtfAdapter.json"

export const theGraphService = {
    getBuyTrades,
    getAveragePriceForBuyTrades,
    getAllTrades,
    getMarket,
    getUmaQuestion
};

async function getAveragePriceForBuyTrades(fpmmAddress, userWalletAddress) {
    let avgPrices = [];
    try {
       const trades = await getBuyTrades(fpmmAddress, userWalletAddress);
        if(trades.length > 0){
            let totalTradesForEveryOption = [];
            trades.forEach(e => {
                const {outcomeIndex, collateralAmount, outcomeTokensTraded} = e;
                if(totalTradesForEveryOption[outcomeIndex] === undefined){
                    totalTradesForEveryOption[outcomeIndex] = {
                        collateralAmount: web3UtilsBn(collateralAmount.toString()),
                        outcomeIndex,
                        outcomeTokensTraded: web3UtilsBn(outcomeTokensTraded.toString())
                    }
                }else{
                    totalTradesForEveryOption[outcomeIndex]['collateralAmount'] = totalTradesForEveryOption[outcomeIndex]['collateralAmount'].add(web3UtilsBn(collateralAmount.toString()))
                    totalTradesForEveryOption[outcomeIndex]['outcomeTokensTraded'] = totalTradesForEveryOption[outcomeIndex]['outcomeTokensTraded'].add(web3UtilsBn(outcomeTokensTraded.toString()))
                }
            });
            avgPrices = totalTradesForEveryOption.map((e) => { 
                return amountSToB(e.collateralAmount.toString())/amountSToB(e.outcomeTokensTraded.toString())
            })
        }
        return avgPrices;
    } catch (e) {
        console.log(e)
    }
}

async function getBuyTrades(fpmmAddress, userWalletAddress) {
    try {
        const trades = await axios.post(
            configData.THE_GRAPH_API_URL, 
            {
                query:`
                {
                    fpmmTrades(
                        orderBy: outcomeIndex, 
                        orderDirection: asc,
                        where: 
                        {
                          fpmm: "${fpmmAddress}",
                          creator: "${userWalletAddress}"
                          type: "Buy"
                        }
                    ){
                        creator {
                            id
                        }
                        id 
                        title
                        type
                        collateralToken
                        feeAmount
                        outcomeIndex
                        outcomeTokensTraded
                        outcomeTokenMarginalPrice
                        oldOutcomeTokenMarginalPrice
                        collateralAmount
                    }
                }`
            }    
        );
        return trades.data.data.fpmmTrades;
    } catch (error) {
        throw error;
    }
}

async function getPageTrades(fpmmAddress, creationTimeStampLessThan = '') {
    try {
        const noOfRecordsToBeReturned = 1000;
        let strCreationTimeStampLessThanForWhere = '';
        
        if(creationTimeStampLessThan!='')
            strCreationTimeStampLessThanForWhere = `creationTimestamp_lt : ${creationTimeStampLessThan}`
        
        const where = `
            where:{
                fpmm : "${fpmmAddress}",
                ${strCreationTimeStampLessThanForWhere}
            }`;

        // const where = `
        // where:{
        //     ${strCreationTimeStampLessThanForWhere}
        // }`;
        const trades = await axios.post(
            configData.THE_GRAPH_API_URL, 
            {
                query:`
                {
                    fpmmTrades(
                        orderBy: creationTimestamp, 
                        orderDirection: desc,
                        first: ${noOfRecordsToBeReturned}
                        ${where}
                    ){
                        id
                        creationTimestamp
                        type
                        outcomeIndex
                        outcomeTokenMarginalPrice
                    }
                }`
            }    
        );
        return trades.data.data.fpmmTrades;
    } catch (error) {
        throw error;
    }
}

async function getAllTrades (fpmmAddress) {
    try{
        let fetchMore = 1;
        let creationTimestamp = '';
        let allTrades = [];
        while(fetchMore){
            const fpmmTrades = await getPageTrades(fpmmAddress, creationTimestamp);
            const tradesLength = fpmmTrades.length;

            if(tradesLength==0 || (tradesLength > 0 && tradesLength < 1000)){
                fetchMore = 0;        
            }

            if(tradesLength > 0){
                allTrades = [...allTrades, ...fpmmTrades];
                creationTimestamp = fpmmTrades[tradesLength-1].creationTimestamp;
            }
        }
        return allTrades;        
    }catch(e){
        console.log(e)
    }
}

async function getUmaQuestion(globalState, questionId, storedMarket){
    try {
        var priceDefaultValue = 69;

        let question = {
            currentAnswer : null,
            isFinalized : false,
            currentAnswerString : null,
            readyToBeResolved : false
        };
        
        const umaApiResponse = await axios.post(
            configData.UMA_CTF_ADAPTER_THE_GRAPH_API_URL, 
            {
                query:`
                {
                    marketResolution(id:"${questionId}") {
                        id
                        ancillaryData
                        status
                        transactionHash
                        logIndex
                        reproposedPrice
                        proposedPrice
                        price
                        lastUpdateTimestamp  
                    }
                }`
            }    
        );
        console.log(umaApiResponse)
        let umaQuestion = umaApiResponse.data.data.marketResolution;
        var price = null;

        if(umaQuestion.proposedPrice != priceDefaultValue && umaQuestion.reproposedPrice != priceDefaultValue){
            price = umaQuestion.reproposedPrice;
        } else if(umaQuestion.proposedPrice != priceDefaultValue && umaQuestion.reproposedPrice == priceDefaultValue){
            price = umaQuestion.proposedPrice;
        }

        let url = getUmaQuestionUrl(umaQuestion);
        question.url = url;
        
        if(price!=null){
            if(umaQuestion.status=='resolved'){
                question.isFinalized = true;
                question.currentAnswer = question.currentAnswerString = getUmaAnswerFromString(price, storedMarket);
            }else{
                const web3 = new Web3(window.ethereum);
                const UmaCtfAdapter = await new web3.eth.Contract(UmaCtfAdapterAbi, configData.addresses.umaCtfAdapter);
                let isQuestionReadyToBeSettled = false;
                try {
                    isQuestionReadyToBeSettled = await UmaCtfAdapter.methods.ready(questionId).call();
                } catch (error) {
                    console.error(`Error checking if question is ready to be settled for questionId ${questionId}:`, error.message);
                }

                if(isQuestionReadyToBeSettled)  question.readyToBeResolved = true;
                question.currentAnswer = question.currentAnswerString = getUmaAnswerFromString(price, storedMarket);
            }
           
        } 
        
        return {...umaQuestion, ...question};
    } catch (error) {
        throw error;
    }
}

function getUmaQuestionUrl(question){
    const baseUrl = configData.UMA_QUESTION_BASE_URL;
    let concatToBaseUrl = '';
    switch(question.status){
        // initialized/posed/proposed/challenged/reproposed/disputed/resolved
        
        case 'posed':
        case 'challenged':
            concatToBaseUrl = 'propose'
            break;
        case 'resolved':
            concatToBaseUrl = 'settled'
            break;
        case 'proposed':
        case 'reproposed':
        case 'disputed':
        case 'default':
            concatToBaseUrl = ''
            break;
    }

    let url = `${baseUrl}${concatToBaseUrl}?transactionHash=${question.transactionHash}&eventIndex=${question.logIndex}`;
    return url;
}

function mapResolvedOptionTextFromPrice(){

}

async function getMarket(fpmmAddress, questionId, globalState, storedMarket) {
    try {
        var question = await getUmaQuestion(globalState, questionId, storedMarket);
        const marketApiResponse = await axios.post(
            `${configData.THE_GRAPH_API_URL}?nocache=${Date.now()}`,
            {
                query:`
                {
                    fixedProductMarketMakers(
                        where: {
                            id:"${fpmmAddress}"
                        }
                    ) {
                        id  
                        liquidityMeasure
                        outcomeTokenMarginalPrices
                        collateralVolume
                        outcomeTokenAmounts
                        conditions {
                            id
                            resolutionTimestamp
                            payouts
                        }
                        payouts
                    }
                }`
            }    
        );
        let market = marketApiResponse.data.data.fixedProductMarketMakers[0];
        market.question = question;
        return market;
    } catch (error) {
        throw error;
    }
}