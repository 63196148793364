import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import TermsOfUse from "../../assets/docs/DegenBetsTOS.pdf";

import fl1 from "../../assets/fl1.png";
import fl2 from "../../assets/fl2.png";
import fl3 from "../../assets/fl3.png";
import ethereum from "../../assets/ethereum.png";

export default function NewFooter() {
  return (
    <footer>
      <Fsection>
        <Div1170>
          <Left>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "max(25px, min(calc(1vw * (32 * 100 / 1440)), 32px))",
              }}
            >
              <div>
                <SiteBrand to="/">
                  <svg
                    width="285"
                    height="31"
                    viewBox="0 0 285 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      "--vh": "31px",
                      "--vw": "285px",
                      width: "var(--size)",
                      height: "calc((var(--vh) / var(--vw))* var(--size))",
                    }}
                  >
                    <path
                      d="M284.619 19.3477C284.619 23.6238 280.753 24.7869 272.201 24.7869C266.899 24.7869 261.562 23.9659 258.825 23.2817L259.92 19.1082C262.965 19.7582 268.096 20.4766 273.364 20.4766C277.401 20.4766 278.598 20.2713 278.598 19.3135C278.598 18.2872 277.64 18.1504 275.006 17.9793L266.488 17.5004C260.604 17.1925 258.483 15.5847 258.483 11.7875C258.483 7.34033 263.067 6.34827 271.483 6.34827C275.519 6.34827 280.822 7.03245 283.524 7.47716L282.566 11.6849C278.974 11.0349 274.322 10.556 269.977 10.556C265.667 10.556 264.504 10.7612 264.504 11.7191C264.504 12.7111 265.393 12.8138 267.172 12.9164L275.69 13.4295C281.54 13.7716 284.619 15.1742 284.619 19.3477Z"
                      fill="white"
                    />
                    <path
                      d="M234.749 16.0981V12.0614H231.02V6.86165H234.92L236.049 2.34607H241.249V6.86165H254.864V12.0614H241.249V15.4139C241.249 18.4585 242.48 19.3137 246.585 19.3137C249.903 19.3137 252.469 18.8348 255.035 18.3217L256.266 23.453C253.495 24.1372 249.801 24.7872 246.004 24.7872C238.58 24.7872 234.749 22.4952 234.749 16.0981Z"
                      fill="white"
                    />
                    <path
                      d="M216.104 19.6898C221.304 19.6898 225.067 19.1425 227.154 18.8004L228.454 23.4528C225.204 24.2738 220.004 24.787 215.523 24.787C205.602 24.787 201.292 22.0845 201.292 15.5848C201.292 9.11926 206.149 6.41675 215.112 6.41675C223.425 6.41675 228.864 8.57191 228.864 14.2506C228.864 15.0716 228.762 16.611 228.693 17.0557H207.689C208.27 19.2109 210.46 19.6898 216.104 19.6898ZM214.873 10.7271C210.083 10.7271 208.168 11.4455 207.655 13.7033H222.467C222.091 11.6165 220.38 10.7271 214.873 10.7271Z"
                      fill="white"
                    />
                    <path
                      d="M190.342 24.3085H164.343V0.978027H190.308C194.995 0.978027 197.526 2.68848 197.526 6.69093C197.526 9.59869 196.192 11.3433 193.592 12.0275C196.739 12.7459 198.381 14.559 198.381 18.0825C198.381 22.5297 195.679 24.3085 190.342 24.3085ZM188.358 6.212H171.185V10.3171H188.529C190.069 10.3171 191.095 9.70132 191.095 8.26454C191.095 6.82776 190.274 6.212 188.358 6.212ZM189.111 15.209H171.185V19.4851H188.94C191.026 19.4851 191.95 19.0062 191.95 17.3299C191.95 15.6879 190.855 15.209 189.111 15.209Z"
                      fill="white"
                    />
                    <path
                      d="M132.59 24.308V6.86146H138.269L138.645 8.94821C142.203 7.54564 146.411 6.41675 150.653 6.41675C156.947 6.41675 160.402 8.81138 160.402 14.6269V24.308H153.937V15.7216C153.937 13.0533 152.158 11.7876 148.566 11.7876C145.898 11.7876 142.682 12.3349 139.056 13.327V24.308H132.59Z"
                      fill="white"
                    />
                    <path
                      d="M116.135 19.6898C121.335 19.6898 125.098 19.1425 127.184 18.8004L128.484 23.4528C125.234 24.2738 120.035 24.787 115.553 24.787C105.633 24.787 101.322 22.0845 101.322 15.5848C101.322 9.11926 106.18 6.41675 115.143 6.41675C123.456 6.41675 128.895 8.57191 128.895 14.2506C128.895 15.0716 128.792 16.611 128.724 17.0557H107.719C108.301 19.2109 110.49 19.6898 116.135 19.6898ZM114.903 10.7271C110.114 10.7271 108.198 11.4455 107.685 13.7033H122.498C122.121 11.6165 120.411 10.7271 114.903 10.7271Z"
                      fill="white"
                    />
                    <path
                      d="M98.0291 7.68166V21.1942C98.0291 28.1044 93.3425 30.978 83.0456 30.978C78.8721 30.978 74.3565 30.4306 70.7303 29.3701L72.0645 24.5809C75.8275 25.5045 79.5905 25.9835 83.4561 25.9835C90.161 25.9835 92.0425 24.273 91.8715 21.16C88.6216 22.2205 84.7218 22.9389 80.7878 22.9389C73.6381 22.9389 69.6699 20.7495 69.6699 14.8998C69.6699 8.53688 74.8696 6.51855 83.114 6.51855C89.1348 6.51855 94.1635 7.13432 98.0291 7.64745L97.9949 7.68166H98.0291ZM76.1354 14.8998C76.1354 17.0549 77.5379 17.9786 81.2667 17.9786C84.2771 17.9786 87.869 17.7049 91.5636 16.9523V12.163C88.6216 11.9236 85.1665 11.7183 82.2246 11.7183C78.1879 11.7183 76.1354 12.5735 76.1354 14.8998Z"
                      fill="white"
                    />
                    <path
                      d="M53.8989 19.6898C59.0987 19.6898 62.8617 19.1425 64.9484 18.8004L66.2484 23.4528C62.9985 24.2738 57.7987 24.787 53.3174 24.787C43.3968 24.787 39.0864 22.0845 39.0864 15.5848C39.0864 9.11926 43.9441 6.41675 52.9069 6.41675C61.2196 6.41675 66.6589 8.57191 66.6589 14.2506C66.6589 15.0716 66.5562 16.611 66.4878 17.0557H45.4835C46.0651 19.2109 48.2544 19.6898 53.8989 19.6898ZM52.6674 10.7271C47.8781 10.7271 45.9624 11.4455 45.4493 13.7033H60.2618C59.8855 11.6165 58.175 10.7271 52.6674 10.7271Z"
                      fill="white"
                    />
                    <path
                      d="M22.7148 24.3085H0V0.978027H22.7148C30.7539 0.978027 36.0905 3.74896 36.0905 12.6091C36.0905 21.7429 30.6855 24.3085 22.7148 24.3085ZM6.94442 6.93039V18.3562H22.0648C26.8883 18.3562 28.9066 17.2273 28.9066 12.6091C28.9066 8.05929 26.8883 6.93039 22.0648 6.93039H6.94442Z"
                      fill="white"
                    />
                  </svg>
                </SiteBrand>
              </div>

              <div>
                <Terms href={TermsOfUse} target="_blank">
                  Terms of Service
                </Terms>
              </div>

                {/* <EmailUs>
                  <form>
                    <input type="email" placeholder="Email Address" />
                    <button type="submit">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.6919 13.2639C21.6539 13.3559 21.599 13.4388 21.53 13.5078L14.53 20.5078C14.384 20.6538 14.192 20.7278 14 20.7278C13.808 20.7278 13.616 20.6548 13.47 20.5078C13.177 20.2148 13.177 19.7398 13.47 19.4468L19.1899 13.7268H3C2.586 13.7268 2.25 13.3908 2.25 12.9768C2.25 12.5628 2.586 12.2268 3 12.2268H19.189L13.469 6.50686C13.176 6.21386 13.176 5.73882 13.469 5.44582C13.762 5.15282 14.237 5.15282 14.53 5.44582L21.53 12.4458C21.599 12.5148 21.6539 12.5977 21.6919 12.6897C21.7679 12.8737 21.7679 13.0799 21.6919 13.2639Z"
                          fill="#1CC8EE"
                        />
                      </svg>
                    </button>
                  </form>
                </EmailUs> */}

              <div>
                <LinkList>
                  <li>
                    {/* <a
                      href="https://x.com/degenbetsdotcom"
                      target="_blank"
                      title="Twitter"
                    > */}
                      <img src={fl1} alt="Twitter" />
                    {/* </a> */}
                  </li>
                  <li>
                    {/* <a
                      href="https://t.me/officialpolybet"
                      target="_blank"
                      style={{ color: "#007b3e" }}
                      title="Telegram"
                    > */}
                      <img src={fl2} alt="Telegram" />
                    {/* </a> */}
                  </li>
                  {/* <li>
                    <a
                      href="https://www.dextools.io/app/en/ether/pair-explorer/0x6ef662fd4fa202646712321bf7076e2cc04c7841?t=1710610281996"
                      target="_blank"
                      style={{ color: "#007b3e" }}
                      title="DEXTools"
                    >
                      <img src={fl3} alt="DEXTools" />
                    </a>
                  </li> */}
                </LinkList>
              </div>
            </div>
          </Left>

          <div data-position="right">
            <Article>
              <h2>Disclaimer</h2>
              <p>
                DegenBets is for informational purposes only. DegenBets takes no
                custody of anyone's cryptocurrency and doesn't profit from the
                information market.
              </p>
            </Article>
            {/* <Email>
              <p>
                <a href="mailto:team@degenbets.com">team@degenbets.com</a>
              </p>
            </Email> */}

            <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
              <div style={{ fontSize: 14, fontWeight: 500, color: "#fff" }}>
                Built On
              </div>
              <img
                src={ethereum}
                alt="ethereum"
                style={{ maxWidth: 104, height: "auto" }}
              />
            </div>
          </div>
        </Div1170>
      </Fsection>
      <Copy>
        <p>&copy; Copyright 2024, All Rights Reserved by DegenBets</p>
      </Copy>
    </footer>
  );
}

const Fsection = styled.section`
  --space: max(50px, min(calc(1vw * (70 * 100 / 1440)), 70px));
  padding-block: var(--space);
  background-color: #1cc8ee;
`;

const Div1170 = styled.div`
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin-inline: auto;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 367px 1fr;
    grid-gap: 130px;
  }
`;

const Left = styled.div`
  margin-bottom: 32px;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const SiteBrand = styled(Link)`
  display: block;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
  text-decoration: none;
  --size: max(154px, min(calc(1vw * (285 * 100 / 1440)), 285px));
`;
const Terms = styled.a`
  cursor: pointer;
  display: block;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  color: #fff;

  &:focus,
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;

const EmailUs = styled.div`
  form {
    display: grid;
    grid-template-columns: 1fr 88px;
    gap: max(15px, min(calc(1vw * (17 * 100 / 1440)), 17px));
  }

  input {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    width: 100%;
    height: 56px;
    padding: 4px 25px;
    border-radius: 4px;
    border: 1px solid #eff0f6;
    background-color: transparent;

    &::placeholder {
      opacity: 1;
      color: #fff;
    }

    &:focus {
      outline: none;
      border-color: #14142b;
    }
  }

  button {
    padding: 4px 6px;
    border-radius: 4px;
    border: 1px solid #fff;
    background-color: #fff;
    box-shadow: 0px 100px 80px 0px rgba(49, 49, 49, 0.1),
      0px 64.815px 46.852px 0px rgba(49, 49, 49, 0.08),
      0px 38.519px 25.481px 0px rgba(49, 49, 49, 0.06),
      0px 20px 13px 0px rgba(49, 49, 49, 0.05),
      0px 8.148px 6.519px 0px rgba(49, 49, 49, 0.04),
      0px 1.852px 3.148px 0px rgba(49, 49, 49, 0.02);
    transition: all 0.15s linear;

    &:focus,
    &:hover {
      border-color: #eff0f6;
      background-color: #eff0f6;
    }
  }
`;

const LinkList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 15px;

  > li {
    img {
      width: 100%;
      max-width: max(24px, min(calc(1vw * (32 * 100 / 1440)), 32px));
      height: auto;
    }

    &:nth-child(3) {
      img {
        max-width: max(100px, min(calc(1vw * (104 * 100 / 1440)), 104px));
      }
    }

    a {
      display: block;
    }
  }
`;

const Article = styled.article`
  color: #fff;
  margin-bottom: 20px;

  h2 {
    font-weight: 700;
    font-size: max(18px, min(calc(1vw * (20 * 100 / 1440)), 20px));
    margin-bottom: max(25px, min(calc(1vw * (32 * 100 / 1440)), 32px));
  }

  p {
    font-weight: 400;
    font-size: 14px;
  }
`;

const Email = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-block: max(15px, min(calc(1vw * (20 * 100 / 1440)), 20px));
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;

  p {
    margin: 0;
  }

  a {
    color: #fff !important;
    text-decoration: none;
    font-weight: 700;
    font-size: max(18px, min(calc(1vw * (20 * 100 / 1440)), 20px));
  }
`;

const Copy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  background-color: #fff;

  p {
    font-size: 14px;
    font-weight: 500;
    color: #14142b;
    margin: 0;
    line-height: 1;
  }
`;
