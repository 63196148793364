import React from "react";
import styled from "styled-components";

import linksBg from "../../assets/linksBg.jpg";
import linkCard from "../../assets/links-card.png";

import c1 from "../../assets/c1.png";
import c2 from "../../assets/c2.png";
import c3 from "../../assets/c3.png";
import c4 from "../../assets/c4.png";
import c5 from "../../assets/c5.png";
import c6 from "../../assets/c6.png";
import c7 from "../../assets/c7.png";
import c8 from "../../assets/c8.png";
import c9 from "../../assets/c9.png";
import ex1 from "../../assets/ex1.png";
import ex2 from "../../assets/ex2.png";
import ex3 from "../../assets/ex3.png";
import ex4 from "../../assets/ex4.png";
import ex5 from "../../assets/ex5.png";
import ex6 from "../../assets/ex6.png";
import ex7 from "../../assets/ex7.png";

export default function DegenbetsLinks() {
  return (
    <Section>
      
      <Div1170>
        <LeftCol>
          <div style={{ marginBottom: 40 }}>
            <H4>BASED & VERIFIED</H4>
            <H2>OFFICIAL DegenBets LINKS</H2>
          </div>
          <div style={{ marginBottom: 40 }}>
            <H3>SOCIAL LINKS</H3>
            <LinkList>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={c1} alt="" />
                </a>
              </li>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={c2} alt="" />
                </a>
              </li>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={c3} alt="" />
                </a>
              </li>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={c4} alt="" />
                </a>
              </li>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={c5} alt="" />
                </a>
              </li>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={c6} alt="" />
                </a>
              </li>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={c7} alt="" />
                </a>
              </li>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={c8} alt="" />
                </a>
              </li>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={c9} alt="" />
                </a>
              </li>
            </LinkList>
          </div>

          <div>
            <H3>EXCHANGES</H3>
            <ExchangeList>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={ex1} alt="" />
                </a>
              </li>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={ex2} alt="" />
                </a>
              </li>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={ex3} alt="" />
                </a>
              </li>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={ex4} alt="" />
                </a>
              </li>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={ex5} alt="" />
                </a>
              </li>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={ex6} alt="" />
                </a>
              </li>
              <li>
                <a href="#" rel="noopener noreferrer">
                  <img src={ex7} alt="" />
                </a>
              </li>
            </ExchangeList>
          </div>
        </LeftCol>

        <RightCol>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <img src={linkCard} alt="img" />
          </div>
        </RightCol>
      </Div1170>
     
    </Section>
  );
}

const Section = styled.section`
  background-color: #270b0f;
  background: url(${linksBg});
  background-size: cover;
  padding-top: max(40px, min(calc(1vw * (50 * 100 / 1440)), 50px));
  padding-bottom: max(40px, min(calc(1vw * (50 * 100 / 1440)), 50px));
`;

const Div1170 = styled.div`
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin-inline: auto;

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 30px;
  }
`;

const LeftCol = styled.div`
  margin-bottom: 30px;
  @media (min-width: 992px) {
    grid-column: 1/9;
    margin-bottom: 0;
  }
`;

const RightCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-width: 470px;
    height: auto;
  }
  @media (min-width: 992px) {
    grid-column: 9/13;
  }
`;

const H2 = styled.h2`
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;
  font-size: max(22px, min(calc(1vw * (42 * 100 / 1440)), 42px));
  margin: 0;
`;

const H4 = styled.h4`
  font-weight: 700;
  color: #1cc8ee;
  text-transform: uppercase;
  font-size: max(14px, min(calc(1vw * (16 * 100 / 1440)), 16px));
  margin-bottom: 20px;
`;

const H3 = styled.h3`
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  font-size: max(14px, min(calc(1vw * (16 * 100 / 1440)), 16px));
  margin-bottom: 16px;
`;

const LinkList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(9, minmax(30px, 60px));
  justify-content: space-between;

  > li {

    img {
      width: 100%;
      max-width: max(24px, min(calc(1vw * (48 * 100 / 1440)), 48px));
      height: auto;
    }

    a {
      display: block;
    }
  }
`;

const ExchangeList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 14px;

  > li {
    img {
      width: 100%;
      max-width: max(104px, min(calc(1vw * (124 * 100 / 1440)), 124px));
      height: auto;
    }

    &:nth-child(5) {
      img {
        max-width: max(65px, min(calc(1vw * (70 * 100 / 1440)), 70px));
      }
    }

    &:nth-child(7) {
      img {
        max-width: max(100px, min(calc(1vw * (104 * 100 / 1440)), 104px));
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      height: 52px;
      border-radius: 50em;
      background-color: #fff;
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
`;
