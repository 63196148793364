import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const InputBoxWithMax = ({ value, onChange, onClick, maxItemName = 'USDC' }) => {
  const { t } = useTranslation();

  return (
    <InputBox>
      <input
        className="form-input"
        type="number"
        placeholder="0"
        min="0"
        value={value}
        onChange={onChange}
        onWheel={() => document.activeElement.blur()}
      />
      <div className="maxString"><span>{maxItemName}</span><span>|</span> <button onClick={onClick}>{t('max')}</button></div>
    </InputBox>
  )
}

export default InputBoxWithMax;

const InputBox = styled.div`
  position: relative;

  .form-input {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.4;
    padding: 10px 110px 10px 16px;
    width: 100%;
    height: 52px;
    border-radius: max(5px, min(calc(1vw * (8 * 100 / 1440)), 8px));
    border: 1px solid #EFF0F6;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  .maxString {
    display: flex;
    align-items: center;
    gap: 1px;
    position: absolute;
    padding: 0 4px;
    top: 15px;
    right: 2px;
    background-color: #fff;
    color: #14142B;
    font-weight: 700;
    font-size: 14px;

    span{
      display:inline-block;
      padding-left: 3px;
      padding-right: 3px;
    }

    button{
      color: #14142B;
      text-decoration: underline;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0 8px 0 0;
      font-weight: 700;
      font-size: 14px;
      margin-top: -2px;
    }
  }
`