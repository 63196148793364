import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

const AboutMarket = ({ market, umaQuestion }) => {
  const { t } = useTranslation();
  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <div>
      {market && (
        <Box>
          <Heading>{t("market_page.about_market")}</Heading>
          <p>{renderHTML(t(`markets.${market.id}.about_market`))}</p>
        </Box>
      )}
      {market && market.resolution_source && umaQuestion != null && (
        <Resource>
          <div className="text">{t("market_page.resolution_source")}</div>
          <div>
            <a
              href={umaQuestion.url}
              style={{ display: "inline-block"}}
              target="_blank"
              rel="noopener noreferrer"
            >
              {umaQuestion != null && (
                <button>{t("market_page.uma_question")}</button>
              )}
            </a>
          </div>
        </Resource>
      )}
    </div>
  );
};

export default AboutMarket;

const Box = styled.div`
  padding: 15px;
  margin-bottom: 15px;
  background-color: #fff;

  p {
    font-weight: 500;
    color: #6e7191;
    font-size: 16px;
    margin-bottom: 0px;

    a {
      color: #6e7191;
    }
  }
`;

const Resource = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: max(20px, min(calc(1vw * (30 * 100 / 1440)), 30px));
  padding-inline: max(16px, min(calc(1vw * (20 * 100 / 1440)), 20px));
  margin-bottom: max(30px, min(calc(1vw * (50 * 100 / 1440)), 50px));
  border-radius: 4px;
  border: 1px solid #eff0f6;
  background-color: #fff;

  .text {
    font-weight: 700;
    color: #14142b;
    font-size: max(20px, min(calc(1vw * (24 * 100 / 1440)), 24px));
  }

  button {
    color: #fff;
    font-weight: 700;
    font-size: max(14px, min(calc(1vw * (16 * 100 / 1440)), 16px));
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-block: max(8px, min(calc(1vw * (14 * 100 / 1440)), 14px));
    padding-inline: max(18px, min(calc(1vw * (24 * 100 / 1440)), 24px));
    border-radius: 4px;
    border: 1px solid #1CC8EE;
    background-color: #1CC8EE;
  }
`;

const Heading = styled.h2`
  margin-bottom: 20px;
  font-weight: 700;
  color: #14142b;
  font-size: max(20px, min(calc(1vw * (24 * 100 / 1440)), 24px));
`;
