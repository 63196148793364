// import {amountBToS} from "./utilities"
import { theGraphService } from '../services/thegraph.service'



export const getTradesForLineChart = async (fpmm) => {
    let tradesToReturn = [];
    try {
        const allTrades = await theGraphService.getAllTrades(fpmm);
        tradesToReturn = rearrageTrades(allTrades)
    } catch (error) {
        
    }
    return tradesToReturn;
}

function getCurrentUnixTimestamp() {
    return Math.floor(Date.now() / 1000);
}

function get24HoursBackUnixTimestamp(currentUnixTimeStamp=''){
    if(currentUnixTimeStamp==''){
        currentUnixTimeStamp = getCurrentUnixTimestamp();
    } 

    const numberOfSecondsInADay = 86400;
    return currentUnixTimeStamp - numberOfSecondsInADay;
}

function getWeekBackUnixTimestamp(currentUnixTimeStamp=''){
    if(currentUnixTimeStamp==''){
        currentUnixTimeStamp = getCurrentUnixTimestamp();
    } 

    const numberOfSecondsInWeek = 604800;
    return currentUnixTimeStamp - numberOfSecondsInWeek;
}

function get30DaysBackUnixTimestamp(currentUnixTimeStamp=''){
    if(currentUnixTimeStamp==''){
        currentUnixTimeStamp = getCurrentUnixTimestamp();
    } 

    const numberOfSecondsIn30Days = 2592000;
    return currentUnixTimeStamp - numberOfSecondsIn30Days;
}

function getCurrentPrices(trades) {
    const recentTrade = trades[0];
    return getPrices(recentTrade.outcomeIndex, recentTrade.outcomeTokenMarginalPrice);    
}

function getPrices(optionIndex, price){
    const otherOptionIndex = optionIndex==0 ? 1 : 0;
    let prices = {};
    prices[optionIndex] = price * 100;
    prices[otherOptionIndex] = (1 - price) * 100;
    return prices;
}

function processForDay(dayTrades, entry, currentUnixTS){
    const totalTrades = dayTrades.length;
    const compareAgainstTS = totalTrades > 0 ? dayTrades[totalTrades-1].timestamp : currentUnixTS;
    const newEntryPrices = getPrices(entry.outcomeIndex, entry.outcomeTokenMarginalPrice);

    if(compareAgainstTS==currentUnixTS){
        dayTrades.push({
            timestamp: compareAgainstTS,
            prices: newEntryPrices,
            xAxisTitle: 'now'
        });
    }

    const entryAndLastTSDiff = compareAgainstTS - entry.creationTimestamp;
    const noOfSecInHalfAnHour = 1800;
    const requiredRecords = 49;
    const firstTradeTS = dayTrades[0].timestamp;
    const halfAnHour = .5;

    if(entryAndLastTSDiff > noOfSecInHalfAnHour){
        let newStepsCount = Math.floor(entryAndLastTSDiff/noOfSecInHalfAnHour);

        if(newStepsCount + totalTrades > requiredRecords){
            newStepsCount = requiredRecords - totalTrades;
        }

        for(var i=1; i<=newStepsCount; i++){
            const newEntryTS = compareAgainstTS - noOfSecInHalfAnHour * i;
            const hourDiffBetWeenFirstAndCurrentTS = ((firstTradeTS - newEntryTS)/noOfSecInHalfAnHour) * halfAnHour;
            const xAxisTitle = `-${hourDiffBetWeenFirstAndCurrentTS} h`;
            dayTrades.push({
                timestamp: newEntryTS,
                prices: newEntryPrices,
                xAxisTitle,
            });
        }
    }
    return dayTrades;
}

export function getFormattedDate(unixTimestamp){
    const options = { month: 'short', day: 'numeric', hour: "numeric" };
    const milliseconds = unixTimestamp * 1000
    const dateToBeConverted  = new Date(milliseconds);

    return dateToBeConverted.toLocaleDateString("en-US", options);
}

function processChartRecord(previousTrades, entry, currentUnixTS, processForTimeInDays){
    const totalTrades = previousTrades.length;
    const compareAgainstTS = totalTrades > 0 ? previousTrades[totalTrades-1].timestamp : currentUnixTS;
    const newEntryPrices = getPrices(entry.outcomeIndex, entry.outcomeTokenMarginalPrice);
    if(totalTrades === 0){
        previousTrades.push({
            timestamp: compareAgainstTS,
            prices: newEntryPrices,
            xAxisTitle: 'now'
        });
    }

    const entryAndLastTSDiff = compareAgainstTS - entry.creationTimestamp;
    const noOfSecIn12Hours = 43200;
    const requiredNoOfRecordsInADay = 2;
    const requiredRecords = processForTimeInDays>0 ? processForTimeInDays*requiredNoOfRecordsInADay : 0;
    const limitedRecords = requiredRecords>0 ? true : false;
    const firstTradeTS = previousTrades[0].timestamp;
    const twelveHours = 12;


    if(entryAndLastTSDiff > noOfSecIn12Hours){
        let newStepsCount = Math.floor(entryAndLastTSDiff/noOfSecIn12Hours);

        if(limitedRecords===true && newStepsCount + totalTrades > requiredRecords){
            newStepsCount = requiredRecords - totalTrades;
        }

        for(var i=1; i<=newStepsCount; i++){
            const newEntryTS = compareAgainstTS - noOfSecIn12Hours * i;
            // const hourDiffBetWeenFirstAndCurrentTS = ((firstTradeTS - newEntryTS)/noOfSecIn12Hours) * twelveHours;
            const xAxisTitle = `${getFormattedDate(newEntryTS)}`;
            previousTrades.push({
                timestamp: newEntryTS,
                prices: newEntryPrices,
                xAxisTitle
            });
        }
    }
    
    return previousTrades;
}

function rearrageTrades(trades) { 
    if(trades.length==0) return trades;
    const currentUnixTS = getCurrentUnixTimestamp();
    const dayBackUnixTimestamp = get24HoursBackUnixTimestamp(currentUnixTS);
    const weekBackUnixTimestamp = getWeekBackUnixTimestamp(currentUnixTS);
    const monthBackUnixTimestamp = get30DaysBackUnixTimestamp(currentUnixTS);
    // const dayBackUnixTimestamp = get24HoursBackUnixTimestamp(currentUnixTS);
    const currentPrices = getCurrentPrices(trades);
    
    let dayTrades = [];
    let weekTrades = [];
    let monthTrades = [];
    let allTimeTrades = [];
    let dayTradesProcessingDone = 0;
    let weekTradesProcessingDone = 0;
    let monthTradesProcessingDone = 0;

    const totalTrades = trades.length;
    for(var i=0; i<totalTrades; i++){
        const currentTrade = trades[i];

        if(!dayTradesProcessingDone){
            if(parseInt(currentTrade.creationTimestamp) <= dayBackUnixTimestamp){
                dayTradesProcessingDone = 1;
            }
            
            dayTrades = processForDay(dayTrades, currentTrade, currentUnixTS);
        }

        if(!weekTradesProcessingDone){
            if(parseInt(currentTrade.creationTimestamp) <= weekBackUnixTimestamp){
                weekTradesProcessingDone = 1;
            }
    
            weekTrades = processChartRecord(weekTrades, currentTrade, currentUnixTS, 7);
        }

        if(!monthTradesProcessingDone){
            if(parseInt(currentTrade.creationTimestamp) <= monthBackUnixTimestamp){
                monthTradesProcessingDone = 1;
            }
    
            monthTrades = processChartRecord(monthTrades, currentTrade, currentUnixTS, 30);
        }

        allTimeTrades = processChartRecord(allTimeTrades, currentTrade, currentUnixTS, 0);
    }
    return {
        dayTrades: dayTrades.reverse(),
        weekTrades: weekTrades.reverse(),
        monthTrades: monthTrades.reverse(),
        allTimeTrades: allTimeTrades.reverse(),
    }
}