import React from 'react';
import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';

import theme from '../../../styles/theme';
import Container from '../../Container/Container';

const CardGeneral = ({ appearance, title, children, mb, mt }) => {
  return (
    <Container>
      <Card mb={mb} mt={mt}>
        <CardHeader><h4>{title}</h4></CardHeader>
        <CardBody appearance={appearance}>
          { children }
        </CardBody>
      </Card>
    </Container>
  )
}

export default CardGeneral;

CardGeneral.propTypes = {
  appearance: PropTypes.oneOf(['purple', 'yellow', 'green']),
};

CardGeneral.defaultProps = {
  appearance: 'purple'
}


const Card = styled.div.attrs( props => ({
  className: 'card-general',
  mb: props.mb || 40,
  mt: props.mt,
}))`
  margin-top: ${props => props.mt}px;
  margin-bottom: ${props => props.mb}px;
  background-color: white;
`;

const CardHeader = styled.div`
  padding: 12px 30px;
  border: 5px solid ${theme.darkBlue};
  background-color: #e3fdff;

  h4 {
    font-weight: 700;
    font-size: 25px;
    color: ${theme.darkBlue};
    line-height: 1;
    margin: 0;
    text-align: center;
  }
`;

const CardBody = styled.div`
  padding: 40px 30px;
  min-height: 300px;
  border-left: 5px solid ${theme.darkBlue};
  border-right: 5px solid ${theme.darkBlue};
  border-bottom: 5px solid ${theme.darkBlue};
  background-color: #f2e7ff;
  color: #296588;

  &:empty {
    display:none;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    text-wrap: wrap;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  ${({ appearance }) => appearance === 'yellow' && css`
      background-color: #ffdfb1;
  `}

  ${({ appearance }) => appearance === 'green' && css`
      background-color: #fdffe7;
  `}
`;
