import React, { useState } from "react";
import styled from "styled-components";

import text_effect from "../../assets/text_effect.jpg";
// import tccleftimg from "../../assets/tccleftimg.png";
import tccleftimg from "../../assets/crystal.webp";

export default function MoreSection() {
  const [asideTab, setAsideTab] = useState("Prediction Market");

  const changetab = (tab) => {
    setAsideTab(tab);
  };

  return (
    <SectionMore>
      <h3 className="more-title">DEGENBETS IS A HOME FOR DEGENS</h3>
      <Div1170>
        <Tab
          tabs={[
            "Prediction Market",
            "Private Bets",
            "DegenBets Token"
          ]}
          selected={asideTab}
          setSelected={changetab}
        >
          <TabContent isSelected={asideTab === "Prediction Market"}>
            <Tcc1>
              <TccLeft>
                <img src={tccleftimg} alt="" />
              </TccLeft>
              <TccRight>
                <h3>PREDICTION MARKET</h3>
                <p>
                  DegenBets is a prediction market platform that lets users bet on the outcome of events. DegenBets 
                  users buy shares in a market where winners will receive $1 per share while losers get $0. DegenBets has
                  decentralized market resolution so Degens can be sure their bets resolve correctly.
                </p>
                <p>
                  DegenBets will host a wide variety of markets covering multiple categories. DegenBets doesn't hold users' funds and uses UMA for market resolution.
                </p>

                {/* <div>
                  <a className="cta" href="#">Coming Soon</a>
                </div> */}
              </TccRight>
            </Tcc1>
          </TabContent>
          <TabContent isSelected={asideTab === "Private Bets"}>
            <Tcc1>
              <TccLeft>
                <img src={tccleftimg} alt="" />
              </TccLeft>
              <TccRight>
                <h3>Private Bets</h3>
                <p>
                DegenBets will soon offer Private Bets. 
                With private bets degens can challenge their friends or enemies to bets. 
                Users set the odds and market terms and can challenge any user. 
                Once their opponent accepts a bet the funds are left in a smart contract until the terms of the bet have been reached. 
                Winners receive all the funds in the contract while losers receive none.
                </p>
              </TccRight>
            </Tcc1>
          </TabContent>
          <TabContent isSelected={asideTab === "DegenBets Token"}>
            <Tcc1>
              <TccLeft>
                <img src={tccleftimg} alt="" />
              </TccLeft>
              <TccRight>
                <h3>DegenBets Token</h3>
                <p>
                  $DBT - DegenBets Token is coming soon. Users that participate in the initial market will be participating in a token generating event. Degens that decide to degen in DegenBets markets will be airdropped $DBT. DBT holders can participate in the DegenBetsDAO and govern the DegenBets platform.
                </p>
              </TccRight>
            </Tcc1>
          
          </TabContent>
        </Tab>
      </Div1170>
    </SectionMore>
  );
}

// tab

function Tab({ tabs, selected, setSelected, children }) {
  return (
    <TabArea>
      <div className="swap">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M280-160 80-360l200-200 56 57-103   103h287v80H233l103 103-56 57Zm400-240-56-57 103-103H440v-80h287L624-743l56-57 200 200-200 200Z"/></svg>
      </div>
      <List>
        {tabs &&
          tabs.map((tab, idx) => {
            const active = tab === selected ? "active" : "";

            return (
              <ListItem key={`${String(tab).toLowerCase()}_${idx}`}>
                <a className={active} onClick={() => setSelected(tab)}>
                  {tab}
                </a>
              </ListItem>
            );
          })}
      </List>
      {children}
    </TabArea>
  );
}

function TabContent({ isSelected, children }) {
  if (isSelected) {
    const active = isSelected ? "active" : "";
    return (
      <TavContentArea isSelected={isSelected} className={active}>
        {children}
      </TavContentArea>
    );
  }
  return null;
}

const TabArea = styled.div.attrs(() => ({
  className: "tab-area",
}))`
  margin-bottom: 10px;
  position:relative;

  .swap{
    position: absolute;
    width: 100%;
    left: 0;
    top:-10px;
    display:flex;
    justify-content: flex-end;
  }

  @media (min-width: 415px) {
    .swap{
      display:none;
    }
  }

`;

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(6, minmax(146px, 1fr));
  grid-gap: 30px;
  width: 100%;
  padding-left: 0;
  list-style: none;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dee2e6;
  overflow-y: hidden;
  overflow-x: auto;
`;

const ListItem = styled.li`
  margin-bottom: -1px;

  a {
    font-size: 14px;
    position: relative;
    font-weight: 500;
    display: block;
    padding: 12px 13px;
    text-decoration: none;
    color: #6e7191;
    cursor: pointer;
    text-align: center;

    &.active {
      color: #14142b;
    }

    &.active::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: #1cc8ee;
      position: absolute;
      left: 0;
      top: calc(100% - 2px);
    }
  }
`;

const TavContentArea = styled.div`
  opacity: 0;
  transition: opacity 0.15s linear;

  &.active {
    display: block;
    opacity: 1;
  }
`;

const SectionMore = styled.section`
  background-color: #1cc8ee;
  background: url(${text_effect});
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: max(40px, min(calc(1vw * (50 * 100 / 1440)), 50px));
  padding-bottom: max(40px, min(calc(1vw * (50 * 100 / 1440)), 50px));

  .more-title {
    font-weight: 700;
    text-align: center;
    color: #fff;
    font-size: max(26px, min(calc(1vw * (42 * 100 / 1440)), 42px));
    margin-bottom: max(40px, min(calc(1vw * (50 * 100 / 1440)), 50px));
  }

  @media (max-width: 991px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Div1170 = styled.div`
  max-width: 1160px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: max(20px, min(calc(1vw * (30 * 100 / 1440)), 30px));
  padding-bottom: max(30px, min(calc(1vw * (40 * 100 / 1440)), 40px));
  margin-inline: auto;
  border-radius: max(8px, min(calc(1vw * (16 * 100 / 1440)), 16px));
  background-color: #fff;
`;

const Tcc1 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: max(30px, min(calc(1vw * (40 * 100 / 1440)), 40px));
  padding-top: max(20px, min(calc(1vw * (40 * 100 / 1440)), 40px));

  @media (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const TccLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-width: 486px;
    height: auto;
  }
`;

const TccRight = styled.article`
  h3 {
    font-weight: 900;
    color: #14142b;
    text-transform: uppercase;
    font-size: max(20px, min(calc(1vw * (24 * 100 / 1440)), 24px));
    margin-bottom: 16px;
  }

  p {
    font-weight: 400;
    color: #6e7191;
    font-size: max(15px, min(calc(1vw * (16 * 100 / 1440)), 20px));
    margin-bottom: 18px;
  }

  .cta {
    text-decoration: none;
    color: #14142B;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-block: max(8px, min(calc(1vw * (12 * 100 / 1440)), 12px));
    padding-inline: max(20px, min(calc(1vw * (40 * 100 / 1440)), 40px));
    border-radius: max(4px, min(calc(1vw * (8 * 100 / 1440)), 8px));
    border: 1px solid #14142B;
    background-color: #fff;
  }
`;
