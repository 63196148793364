import React from 'react';
import styled, { css } from 'styled-components';

const TabContent = ({
  isSelected,
  children
}) => {
  if (isSelected) {
    const active = (isSelected ? 'active': '');
    return (
      <TavContentArea isSelected={isSelected} className={active}>
        {children}
      </TavContentArea>
    )
  }
  return null
}

export default TabContent;

const TavContentArea = styled.div`
  /* display: none; */
  opacity: 0;
  transition: opacity .15s linear;

  &.active {
    display: block;
    opacity: 1;
  }
`; 


