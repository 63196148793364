// import configData from "../../config.json";
import gnosisTreeData from "./gnosis.json";
import polyMarketTreeData from "./polymarket.json";
import firstMarketTreeData from "./firstMarket.json";
// import { handleResponse } from '../../helpers/handle-response';
import MerkleTree from "merkletreejs";
import { ethers } from "ethers"; // Ethers
import keccak256 from "keccak256"; // Keccak256 hashing
import configData from "../../config.json";
import { handleResponse } from '../../helpers/handle-response';
import PBTDistributor from "../../abis/PBTDistributor.json"

export const merklesService = {
    getTree,
    generateLeaf,
    getProof,
    getTreeData,
    getReward,
    loadPBTDistributionContract,
    hasClaimedReward,
    isClaimOpen
};

function generateLeaf(address, value){
    return Buffer.from(
      // Hash in appropriate Merkle format
      ethers.utils
        .solidityKeccak256(["address", "uint256"], [address, value])
        .slice(2),
      "hex"
    );
  }

function getTreeData(companyName){
  switch (companyName) {
    case 'gnosis':
      return gnosisTreeData
      break;
    case 'polymarket':
      return polyMarketTreeData
      break;
    case 'will-trump-win-the-2024-election-LP-rewards':
      return firstMarketTreeData
      break;
    default:
      break;
  }
}

function getTree(treeData) {
  var leaves = Object.entries(treeData.airdrop).map(([address, tokens]) => 
      generateLeaf(
        ethers.utils.getAddress(address),
        ethers.utils.parseUnits(tokens.toString(), treeData.decimals).toString()
      )
  );

  const merkleTree = new MerkleTree(
      leaves,
      keccak256,
      { sortPairs: true }
    );

  return merkleTree;
}

function getProof(treeData, merkleTree, address, numTokens){
  const leaf = generateLeaf(
      ethers.utils.getAddress(address),
      ethers.utils.parseUnits(numTokens, treeData.decimals).toString()
    );
  const proof = merkleTree.getHexProof(leaf);
  const root = merkleTree.getRoot().toString('hex')

  return proof;
}

function getReward(userAddress, companyName) {
  const requestOptions = { method: 'GET'};
  return fetch(`${configData.API_URL}/airdrop/get/${userAddress}/${companyName}`, requestOptions).then(handleResponse);
}

const toCamel = (s) => {
  return s.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

function formatMarketName(name){
  name = name.replaceAll("-", "_");
  return toCamel(name);
}

function isCompanyMarket(companyName){
  if(companyName=='gnosis' || companyName=='polymarket') return false;
  
  return true;
}

function isClaimOpen(companyName) {
  return configData.claim_rewards[formatMarketName(companyName)]["claim_open"];
}

async function loadPBTDistributionContract(globalState, companyName) {
  try{
      let result;
      if(!isCompanyMarket(companyName)){
        result = await new globalState.web3.eth.Contract(PBTDistributor, configData.claim_rewards[companyName]["distribution_contract"]);
      }else{
        result = await new globalState.web3.eth.Contract(PBTDistributor, configData.claim_rewards[formatMarketName(companyName)]["distribution_contract"]);
      }
      return result;
  }catch(e){
      throw e;
  }
}

async function hasClaimedReward(userAddress, distributorContract){
  try {
      return await distributorContract.methods.hasClaimed(userAddress).call({from: userAddress})
  } catch (error) {
      throw error;
  }
}
