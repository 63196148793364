import React, { useState } from "react";
import styled from "styled-components";
import Button from "../UI/Button/Button";
import theme from "../../styles/theme";
import InputBoxWithMax from "../InputBoxWithMax/InputBoxWithMax";
import { strToFixed } from "../../helpers/utilities";
import { authenticationService } from "../../services/authentication.service";
import Modal from "../Modal/Modal";
import { useTranslation } from "react-i18next";

const WidgetForm = ({
  selectedTab,
  onSubmit,
  refreshPrices,
  yesNoData,
  btnSelect,
  setBtnSelect,
  sharesInputValue,
  onChangeShareInput,
  submitText,
  globalState,
  setGlobalState,
  inputValue,
  onInputChange,
  onMaxClick,
  maxItemName,
  market,
  showStats,
  displayValues,
}) => {
  const { t } = useTranslation();
  const [showCountryBlockedPopUp, setShowCountryBlockedPopUp] = useState(0);
  const isCountryRestricted = authenticationService.isCountryRestricted();
  const countryRestrictedBtnClicked = (e) => {
    setShowCountryBlockedPopUp(1);
  };

  return (
    <WidgetBox>
      <OutcomeLabel>
        <div className="text">{t("market_page.pick_outcome")} </div>
      </OutcomeLabel>

      <Fieldset>
        <div className="fsButtons">
          {market &&
            market.options.map((item, idx) => {
              const active = item.index_set - 1 === btnSelect ? "active" : "";
              return (
                <ButtonStyle
                  key={`${String(item.option).toLowerCase()}_${idx}`}
                  className={`${idx === 0 ? "btn-yes" : "btn-no"} ${active}`}
                  onClick={(e) => setBtnSelect(e, item.index_set - 1)}
                >
                  <span>
                    {market && t(`markets.${market.id}.options.${item.id}`)}
                  </span>
                  <span className="price">{`$${strToFixed(
                    item.marginal_price
                  )}`}</span>
                </ButtonStyle>
              );
            })}
        </div>
      </Fieldset>

      <HowLabel>
        <div className="text">{t("market_page.how_much")}</div>
        <InputBoxWithMax
          value={inputValue}
          onChange={(e) => onInputChange(e)}
          onClick={(e) => onMaxClick(e)}
          maxItemName={maxItemName}
        />
      </HowLabel>

      {selectedTab &&
        selectedTab === t("market_page.buy") &&
        showStats == 1 && (
          <Group>
            <Line>
              <div className="text-left">
                {t("market_page.your_avg_price")}:
              </div>
              <div className="text-right">{`$${displayValues.yourAvgPrice}`}</div>
            </Line>

            <Line>
              <div className="text-left">
                {t("market_page.estimated_shares_bought")}:
              </div>
              <div className="text-right">{`${displayValues.estimatedSharesBought}`}</div>
            </Line>
            <Line>
              <div className="text-left">
                {t("market_page.maximum_winnings")}:
              </div>
              <div className="text-right">{`$${displayValues.maxWinnings}`}</div>
            </Line>
            <Line>
              <div className="text-left">
                {t("market_page.max_return_on_investment")}:
              </div>
              <div className="text-right">
                {`${displayValues.maxReturnOnInvestment}`}%
              </div>
            </Line>
          </Group>
        )}

      {selectedTab &&
        selectedTab === t("market_page.sell") &&
        showStats == 1 && (
          <Group>
            <Line>
              <div className="text-left">
                {t("market_page.actual_shares_sold")}:
              </div>
              <div className="text-right">{`${displayValues.sharesToBeSold}`}</div>
            </Line>
            <Line>
              <div className="text-left">
                {t("market_page.selling_avg_price")}:
              </div>
              <div className="text-right">{`$${displayValues.yourAvgPrice}`}</div>
            </Line>
            <Line>
              <div className="text-left">
                {t("market_page.selling_remaining_shares")}:
              </div>
              <div className="text-right">{`${displayValues.remainingShares}`}</div>
            </Line>
            <Line>
              <div className="text-left">
                {t("market_page.selling_amount_you_will_receive")}:
              </div>
              <div className="text-right">{`$${displayValues.youWillReceive}`}</div>
            </Line>
          </Group>
        )}
        
      <Group>
        {isCountryRestricted == 0 && (
          <Button appearance="primary" onClick={(e) => onSubmit(e)}>
            {submitText}
          </Button>
        )}
        {isCountryRestricted == 1 && (
          <Button
            appearance="danger"
            onClick={(e) => countryRestrictedBtnClicked(e)}
          >
            {t("unavailable")}
          </Button>
        )}
      </Group>

      {showCountryBlockedPopUp === 1 && (
        <Modal
          title={t("restricted_country_message")}
          align="center"
          close={() => setShowCountryBlockedPopUp(0)}
        >
          <div>
            <p style={{ wordWrap: "break-word", wordBreak: "break-all" }}>
              {t("restricted_country_message")}
            </p>
          </div>
        </Modal>
      )}
    </WidgetBox>
  );
};

export default WidgetForm;

const WidgetBox = styled.div`
  padding-bottom: 5px;
`;

const FormLabel = styled.label`
  display: block;
  margin: 1rem 0 0.4375rem;

  .text {
    font-weight: 500;
    font-size: 15px;
  }
`;

const OutcomeLabel = styled(FormLabel)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: max(18px, min(calc(1vw * (20 * 100 / 1440)), 20px));
  margin-bottom: max(10px, min(calc(1vw * (14 * 100 / 1440)), 14px));

  .text {
    color: #6e7191;
    font-weight: 500;
    font-size: max(14px, min(calc(1vw * (16 * 100 / 1440)), 16px));
  }
`;

const Fieldset = styled.fieldset`
  border: none;
  padding: 0;

  .fsButtons {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);

    button:nth-of-type(odd) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    button:nth-of-type(even) {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

const ButtonStyle = styled.button`
  width: 100%;
  height: max(46px, min(calc(1vw * (53 * 100 / 1440)), 53px));
  color: #14142b;
  font-weight: 700;
  font-size: max(12px, min(calc(1vw * (14 * 100 / 1440)), 14px));
  line-height: 1;
  outline: none;
  padding: 4px 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #eff0f6;
  background-color: #fff;

  .price {
    opacity: 0.6;
    display: inline-block;
    padding-left: 2px;
  }

  .result {
    margin-top: 0.4375rem;
    font-size: 14px;
    display: none;
  }

  &.btn-yes {
    &.active {
      border-color: #1cc8ee;
      background-color: #e8f9fd;

      .result {
        display: block;
      }
    }
  }

  &.btn-no {
    &.active {
      border-color: #1cc8ee;
      background-color: #e8f9fd;

      .result {
        display: block;
      }
    }
  }

  &:active,
  &:focus {
    outline: 0;
    box-shadow: none;
  }
`;

const HowLabel = styled(FormLabel)`
  .text {
    color: #6e7191;
    font-weight: 500;
    font-size: max(14px, min(calc(1vw * (16 * 100 / 1440)), 16px));
    margin-bottom: 8px;
  }
`;

const Group = styled.div.attrs((props) => ({
  mt: props.mt || 18,
}))`
  margin-top: ${({ mt }) => mt}px;
`;

const Line = styled.div`
  overflow-wrap: break-word;
  word-wrap: break-word;
  margin-bottom: max(14px, min(calc(1vw * (18 * 100 / 1440)), 18px));

  > div {
    font-weight: 500;
    color: #6E7191;
    flex: 1 1;
    font-size: max(14px, min(calc(1vw * (16 * 100 / 1440)), 16px));
    letter-spacing: -0.5px;
    white-space: nowrap;
    margin-bottom: max(14px, min(calc(1vw * (18 * 100 / 1440)), 18px));
  }

  .text-right {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: max(12px, min(calc(1vw * (14 * 100 / 1440)), 14px));
    height: max(46px, min(calc(1vw * (53 * 100 / 1440)), 53px));
    padding: 10px 16px;
    border: 1px dotted #EFF0F6;
    border-radius: max(5px, min(calc(1vw * (8 * 100 / 1440)), 8px));
    background-color: #fff;
    margin-bottom: 4px;
   
  }
`;
