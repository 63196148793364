import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Logo({ to, title = "Degenbets" }) {
  return (
    <SiteBrand title={title} to={to}>
      <svg
        viewBox="0 0 285 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          "--vh": "30px",
          "--vw": "285px",
          width: "var(--size)",
          height: "calc((var(--vh) / var(--vw))* var(--size))",
        }}
      >
        <title>{title}</title>
        <path
          d="M284.619 18.3695C284.619 22.6457 280.753 23.8088 272.201 23.8088C266.899 23.8088 261.562 22.9877 258.825 22.3036L259.92 18.1301C262.965 18.78 268.096 19.4984 273.364 19.4984C277.401 19.4984 278.598 19.2932 278.598 18.3353C278.598 17.3091 277.64 17.1722 275.006 17.0012L266.488 16.5222C260.604 16.2144 258.483 14.6065 258.483 10.8093C258.483 6.36218 263.067 5.37012 271.483 5.37012C275.519 5.37012 280.822 6.0543 283.524 6.49901L282.566 10.7067C278.974 10.0567 274.322 9.57782 269.977 9.57782C265.667 9.57782 264.504 9.78308 264.504 10.7409C264.504 11.733 265.393 11.8356 267.172 11.9382L275.69 12.4514C281.54 12.7935 284.619 14.196 284.619 18.3695Z"
          fill="#1CC8EE"
        />
        <path
          d="M234.749 15.1199V11.0833H231.02V5.8835H234.92L236.049 1.36792H241.249V5.8835H254.864V11.0833H241.249V14.4357C241.249 17.4803 242.48 18.3356 246.585 18.3356C249.903 18.3356 252.469 17.8566 255.035 17.3435L256.266 22.4749C253.495 23.159 249.801 23.809 246.004 23.809C238.58 23.809 234.749 21.517 234.749 15.1199Z"
          fill="#1CC8EE"
        />
        <path
          d="M216.104 18.7118C221.304 18.7118 225.067 18.1645 227.154 17.8224L228.454 22.4748C225.204 23.2958 220.004 23.8089 215.523 23.8089C205.602 23.8089 201.292 21.1064 201.292 14.6067C201.292 8.14123 206.149 5.43872 215.112 5.43872C223.425 5.43872 228.864 7.59389 228.864 13.2726C228.864 14.0936 228.762 15.633 228.693 16.0777H207.689C208.27 18.2329 210.46 18.7118 216.104 18.7118ZM214.873 9.74905C210.083 9.74905 208.168 10.4674 207.655 12.7252H222.467C222.091 10.6385 220.38 9.74905 214.873 9.74905Z"
          fill="#1CC8EE"
        />
        <path
          d="M190.342 23.3305H164.343V0H190.308C194.995 0 197.526 1.71045 197.526 5.7129C197.526 8.62066 196.192 10.3653 193.592 11.0495C196.739 11.7679 198.381 13.581 198.381 17.1045C198.381 21.5517 195.679 23.3305 190.342 23.3305ZM188.358 5.23397H171.185V9.33905H188.529C190.069 9.33905 191.095 8.72329 191.095 7.28651C191.095 5.84974 190.274 5.23397 188.358 5.23397ZM189.111 14.2309H171.185V18.5071H188.94C191.026 18.5071 191.95 18.0281 191.95 16.3519C191.95 14.7099 190.855 14.2309 189.111 14.2309Z"
          fill="#1CC8EE"
        />
        <path
          d="M132.59 23.33V5.88344H138.269L138.645 7.97018C142.203 6.56762 146.411 5.43872 150.653 5.43872C156.947 5.43872 160.402 7.83335 160.402 13.6489V23.33H153.937V14.7436C153.937 12.0753 152.158 10.8095 148.566 10.8095C145.898 10.8095 142.682 11.3569 139.056 12.3489V23.33H132.59Z"
          fill="white"
        />
        <path
          d="M116.135 18.7118C121.335 18.7118 125.098 18.1645 127.184 17.8224L128.484 22.4748C125.234 23.2958 120.035 23.8089 115.553 23.8089C105.633 23.8089 101.322 21.1064 101.322 14.6067C101.322 8.14123 106.18 5.43872 115.143 5.43872C123.456 5.43872 128.895 7.59389 128.895 13.2726C128.895 14.0936 128.792 15.633 128.724 16.0777H107.719C108.301 18.2329 110.49 18.7118 116.135 18.7118ZM114.903 9.74905C110.114 9.74905 108.198 10.4674 107.685 12.7252H122.498C122.121 10.6385 120.411 9.74905 114.903 9.74905Z"
          fill="white"
        />
        <path
          d="M98.0291 6.70363V20.2162C98.0291 27.1264 93.3425 29.9999 83.0456 29.9999C78.8721 29.9999 74.3565 29.4526 70.7303 28.3921L72.0645 23.6029C75.8275 24.5265 79.5905 25.0054 83.4561 25.0054C90.161 25.0054 92.0425 23.295 91.8715 20.182C88.6216 21.2424 84.7218 21.9608 80.7878 21.9608C73.6381 21.9608 69.6699 19.7715 69.6699 13.9217C69.6699 7.55886 74.8696 5.54053 83.114 5.54053C89.1348 5.54053 94.1635 6.15629 98.0291 6.66942L97.9949 6.70363H98.0291ZM76.1354 13.9217C76.1354 16.0769 77.5379 17.0005 81.2667 17.0005C84.2771 17.0005 87.869 16.7269 91.5636 15.9743V11.185C88.6216 10.9455 85.1665 10.7403 82.2246 10.7403C78.1879 10.7403 76.1354 11.5955 76.1354 13.9217Z"
          fill="white"
        />
        <path
          d="M53.8989 18.7118C59.0987 18.7118 62.8617 18.1645 64.9484 17.8224L66.2484 22.4748C62.9985 23.2958 57.7987 23.8089 53.3174 23.8089C43.3968 23.8089 39.0864 21.1064 39.0864 14.6067C39.0864 8.14123 43.9441 5.43872 52.9069 5.43872C61.2196 5.43872 66.6589 7.59389 66.6589 13.2726C66.6589 14.0936 66.5562 15.633 66.4878 16.0777H45.4835C46.0651 18.2329 48.2544 18.7118 53.8989 18.7118ZM52.6674 9.74905C47.8781 9.74905 45.9624 10.4674 45.4493 12.7252H60.2618C59.8855 10.6385 58.175 9.74905 52.6674 9.74905Z"
          fill="white"
        />
        <path
          d="M22.7148 23.3305H0V0H22.7148C30.7539 0 36.0905 2.77093 36.0905 11.6311C36.0905 20.7649 30.6855 23.3305 22.7148 23.3305ZM6.94442 5.95236V17.3782H22.0648C26.8883 17.3782 28.9066 16.2493 28.9066 11.6311C28.9066 7.08126 26.8883 5.95236 22.0648 5.95236H6.94442Z"
          fill="white"
        />
      </svg>
    </SiteBrand>
  );
}

const SiteBrand = styled(Link)`
  display: block;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
  text-decoration: none;
  --size: max(154px, min(calc(1vw * (285 * 100 / 1440)), 285px));
`;