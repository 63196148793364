import React from "react";
import styled from "styled-components";

import heroBg from "../../assets/hero-bg.jpg";
import NewNavbar from "../../components/Navbar/NewNavbar";
import ChainHandler from "../../components/ChainHandler/ChainHandler";

export default function NewHero({ globalState, setGlobalState }) {
  return (
    <Section>
      <Div1170>
        <NewNavbar
          globalState={globalState}
          setGlobalState={setGlobalState}
        />
        <Article>
          {/* <H2>Welcome To</H2> */}
          <H1>
            Degen<span>Bets</span>
          </H1>
          <H3>A Betting Platform for Degens</H3>

          <ChainHandler
            globalState={globalState}
            setGlobalState={setGlobalState}
          ></ChainHandler>
        </Article>
      </Div1170>
    </Section>
  );
}

const Section = styled.section`
  background-color: #22090d;
  background: url(${heroBg});
  background-size: cover;
`;

const Div1170 = styled.div`
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin-inline: auto;
`;

const Article = styled.article`
  padding-top: max(30px, min(calc(1vw * (50 * 100 / 1440)), 50px));
  padding-bottom: max(90px, min(calc(1vw * (125 * 100 / 1440)), 125px));
`;

const H2 = styled.h2`
  font-family: "Pacifico", cursive;
  font-weight: 400;
  text-align: center;
  color: #fff;
  font-size: max(25px, min(calc(1vw * (40 * 100 / 1440)), 40px));
`;

const H1 = styled.h1`
  font-weight: 900;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: max(50px, min(calc(1vw * (156 * 100 / 1440)), 156px));
  margin: 0;

  span {
    color: #1cc8ee;
  }
`;

const H3 = styled.h3`
  font-weight: 900;
  line-height: 50px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: max(10px, min(calc(1vw * (25 * 100 / 1440)), 15px));
  font-size: max(25px, min(calc(1vw * (30 * 100 / 1440)), 30px));
  margin: max(30px, min(calc(1vw * (50 * 100 / 1440)), 50px));
`;
