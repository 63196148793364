import React from 'react';
import styled from 'styled-components/macro';

const News = ({ mainTitle, newsFeed }) => {
  return (
    <Box>
      <Heading>{mainTitle}</Heading>
      {newsFeed && newsFeed.map((item, idx) => {
        return (
          <ItemCard key={`news${idx}`}>
            <ItemContent>
              <ContentColumn>
                <h3>{item.newsTitle}</h3>
                <p>{item.newsDescription}</p>
                {item.readMoreLink!=null && <p><a target="_blank" rel="noopener noreferrer" href={item.readMoreLink}>Read More</a></p>}
              </ContentColumn>

              {/* {item.imageUrl && (
                <ContentImage>
                  <img src={item.imageUrl} alt={item.title} />
                </ContentImage>
              )} */}
            </ItemContent>
          </ItemCard>
        )
      })}
    </Box>
  ) 
}

export default News;


const Box = styled.div`
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fff;
`

const Heading = styled.h2`
  margin-bottom: 20px;
  font-size: 22px;
`

const ItemCard = styled.div`
 margin-bottom: 20px;
`

const ItemContent = styled.div`
  display: flex;
`
const ContentColumn = styled.div`
  flex: 1 1 auto;

  h3 {
    font-size: 17px;
    margin-bottom: 15px;
  }

  p{
    font-size: 15px;
    margin-bottom: 5px;
    line-height: 1.4;
  }

  p:last-child {
    margin-bottom: 0;
  }
`

const ContentImage = styled.div`
  max-width: 100px;
  border-radius: 2px;
  align-self: center;
  margin-left: 22px;

  img {
    max-width: 100%;
    border-radius: 2px;
    height: auto;
  }
`