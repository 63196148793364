import React, {useState} from 'react';
import styled from 'styled-components';
import Button from '../UI/Button/Button';
import theme from '../../styles/theme';
import InputBoxWithMax from '../InputBoxWithMax/InputBoxWithMax';
import { amountBToS} from '../../helpers/utilities';
import { gsSetGlobalState, disableAsyncButton, showLoadingButton, getAndSetWalletBalances } from '../../helpers/global-state';
import { isLoggedInMetamask, loadConditionResolverUsingReality, getCurrentGasPrice , getChainBaseUrl} from '../../helpers/web3';
import { useToasts } from 'react-toast-notifications'
import Modal from '../../components/Modal/Modal';
import useModal from '../../hooks/useModal';
import configData from '../../config.json';
import { useTranslation } from 'react-i18next';
import UmaCtfAdapterAbi from "../../abis/UmaCtfAdapter.json"
import Web3 from "web3";


const ResolveMarket = ({
  globalState, 
  setGlobalState,
  market,
  setUserStats,
  setShowOverlay,
  setSelectedPositionTab,
  winningsAndLossesLabel
}) => {
  const { t } = useTranslation();
  const [liquidity, setLiquidity] = useState('');
  const [marketResolutionSuccessHash, setMarketResolutionSuccessHash] = useState(false);
  const [showResolveMarketModel, setShowResolveMarketModel] = useState(false);
  const actionName = 'resolve_market';
  const { addToast } = useToasts();

  const beforeSend = () => {
    setShowOverlay(true);
  }

  const afterSend = () => {
    setLiquidity("")
    setShowOverlay(false);
  }

  const resolveMarket = async () => {
    try{
        let gasPrice = await getCurrentGasPrice(globalState)
        const contracts = globalState.loadedContracts;
        const from = globalState.currentAddress;
        const questionId = market.question_id;
        // const conditionResolverUsingReality = await loadConditionResolverUsingReality(globalState, configData.addresses.conditionResolverUsingReality);

        const web3 = new Web3(window.ethereum);
        const UmaCtfAdapter = await new web3.eth.Contract(UmaCtfAdapterAbi, configData.addresses.umaCtfAdapter);
        
        UmaCtfAdapter.methods
        .resolve(questionId)
        .send({from: from, gasPrice: gasPrice})
        .on('transactionHash', function(hash){
          setMarketResolutionSuccessHash(hash);
          afterSend();
        })
        .on('confirmation', function(confirmationNumber, receipt){
          if(confirmationNumber==configData.SHOW_RESULT_AFTER_NTH_CONFIRMATION){
            addToast(
              t('market_page.market_resolved_successfully'), 
              { appearance: "success" }
            );

            setUserStats();
            setSelectedPositionTab(winningsAndLossesLabel)
          }
        })
        .on('error', function(error, receipt) {
          addToast(
            error.message, 
            { appearance: "error" }
          );
          afterSend();
          // throw error;
        });
    }catch(error){
      throw error;
    }
  }

  const validateAndResolveMarket = async (event) => {
    event.preventDefault();

    //ask them to connect if not connected
    if(!isLoggedInMetamask(globalState)){
      addToast(
        t('connect_your_wallet_first'), 
        { appearance: "warning" }
      );
      return 0;
    }

    setShowResolveMarketModel(true)
  }

  const proceedWithResolvingMarket = async(event) => {
    event.preventDefault();
    setShowResolveMarketModel(false)
    beforeSend();
    try{
      await resolveMarket();
    }catch(error){
      afterSend()  
    }
  }
  
  return (
    <div>
      <ToggleWrap>
        <Button 
          onClick={validateAndResolveMarket} 
          disabled={disableAsyncButton(globalState)}
        >
          {showLoadingButton(globalState, actionName) ? t('market_page.resolving') : t('market_page.resolve_market')}
        </Button>
      </ToggleWrap>

      {showResolveMarketModel && (
        <Modal
          title={t('market_page.resolve_market_confirmation_title')}
          align="center"
          close={() => setShowResolveMarketModel(false)}
        >
          <div>
            <p>
              {t('market_page.resolve_market_confirmation_description')}
            </p>
            <div className="d-flex align-center justify-content-center">
              <div style={{padding:10}}>
                <Button appearance="primary" size="sm" onClick={(e) => proceedWithResolvingMarket(e)}>{t('market_page.i_agree')}</Button>
              </div>
              <div style={{padding:10}}>
                <Button appearance="danger" size="sm" onClick={() => setShowResolveMarketModel(false)}>{t('market_page.close_popup')}</Button>
              </div>
            </div>
          </div>          
         
        </Modal>
      )}

      {marketResolutionSuccessHash!='' && (
        <Modal
          title={t('transaction_hash')}
          align="center"
          close={() => setMarketResolutionSuccessHash('')}
        >
          <div>
            <p style={{wordWrap:'break-word', wordBreak: 'break-all'}}>
              {t('transaction_hash')}: {marketResolutionSuccessHash}
            </p>
            <a href={getChainBaseUrl(globalState.chainId) + `/tx/${marketResolutionSuccessHash}`} target="_blank">{t('visit_etherscan_to_view_transaction')}</a>
          </div>          
        </Modal>
      )}
    </div>
  )
};

export default ResolveMarket;

const ToggleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: max(46px, min(calc(1vw * (56 * 100 / 1440)), 56px));
  border-radius: max(5px, min(calc(1vw * (8 * 100 / 1440)), 8px));
  text-align: center;
  border: 1px solid #1cc8ee;
  margin-bottom: 20px;
  margin-top: 20px;

  button {
    color: #1cc8ee;
    font-size: max(14px, min(calc(1vw * (16 * 100 / 1440)), 16px));
    font-weight: 600;
    border: none;
    cursor: pointer;
    padding: 0.5em;
    background-color: transparent;

    &:active,
    &:focus {
      outline: none;
    }
  }
`;
