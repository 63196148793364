import React, {useState} from 'react';
import styled from 'styled-components';
import Button from '../UI/Button/Button';
import theme from '../../styles/theme';
import InputBoxWithMax from '../InputBoxWithMax/InputBoxWithMax';
import { amountBToS} from '../../helpers/utilities';
import { getAndSetWalletBalances } from '../../helpers/global-state';
import {  isLoggedInMetamask, loadFixedProductMarketMaker, getCurrentGasPrice , getChainBaseUrl} from '../../helpers/web3';
import { useToasts } from 'react-toast-notifications'
import Modal from '../../components/Modal/Modal';
import useModal from '../../hooks/useModal';
import configData from '../../config.json';
import { amountSToB, strToFixed, getIndexesForRedeemPositions } from '../../helpers/utilities'
import { removeLiquidity } from '../../helpers/fpmm'
import { useTranslation } from 'react-i18next';


const RedeemPositions = ({
  isShowing,
  toggleShow,
  globalState, 
  setGlobalState, 
  userStats,
  setUserStats,
  market,
  setShowOverlay
}) => {
  const { t } = useTranslation();
  const [liquidity, setLiquidity] = useState('');
  const [redeemPostionsSuccessHash, setRedeemPostionsSuccessHash] = useState(false);
  const [showRedeemPositionsModel, setShowRedeemPositionsModel] = useState(false);
  const { addToast } = useToasts();

  const beforeSend = () => {
    setShowOverlay(true);
  }

  const afterSend = () => {
    setLiquidity("")
    setShowOverlay(false);
  }

  const onError = (error) => {
    addToast(
      error.message, 
      { appearance: "error" }
    );  
    afterSend();
  }

  const onTransactionHash = (hash) => {
    setRedeemPostionsSuccessHash(hash);
    afterSend();
  }

  const onConfirmation = (confirmationNumber, receipt) => {
    if(confirmationNumber==configData.SHOW_RESULT_AFTER_NTH_CONFIRMATION){
      addToast(
        t('market_page.market_positions_redeemed_successfully'), 
        { appearance: "success" }
      );
      getAndSetWalletBalances(globalState, setGlobalState)
    }
  }

  const redeemPositions = async () => {
    try{
      let gasPrice = await getCurrentGasPrice(globalState)
      var marketOptionsCount = market.options.length;
      var indexSets = getIndexesForRedeemPositions(marketOptionsCount);
      var conditionId = market.condition_id;
      var parentCollectionId = `0x${'0'.repeat(64)}`;
      var collateralAddress = configData.addresses.collateralToken;
      const from = globalState.currentAddress;

      const result = await globalState
      .loadedContracts
      .conditionalToken
      .methods
      .redeemPositions(
        collateralAddress,
        parentCollectionId,
        conditionId,
        indexSets
      )
      .send({from: from, gasPrice: gasPrice})
      .on('transactionHash', function(hash){
          onTransactionHash(hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
          onConfirmation(confirmationNumber, receipt)
      })
      .on('error', function(error) {
          onError(error)
      });
    }catch(error){
      throw error;
    }
  }

  const validateAndRedeemPositions = async (event) => {
    event.preventDefault();

    //ask them to connect if not connected
    if(!isLoggedInMetamask(globalState)){
      addToast(
        t('connect_your_wallet_first'), 
        { appearance: "warning" }
      );
      return 0;
    }

    beforeSend();
    try{
      await redeemPositions();
      afterSend();
    }catch(error){
      afterSend()  
    }
  }

  return (
    <div>
      {!isShowing && (
        <ToggleWrap>
          <button onClick={validateAndRedeemPositions}>{t('market_page.redeem_positions')}</button>
        </ToggleWrap>
      )}

      {redeemPostionsSuccessHash!='' && (
        <Modal
          title={t('transaction_hash')}
          align="center"
          close={() => setRedeemPostionsSuccessHash('')}
        >
          <div>
            <p style={{wordWrap:'break-word', wordBreak: 'break-all'}}>
              {t('transaction_hash')}: {redeemPostionsSuccessHash}
            </p>
            <a href={getChainBaseUrl(globalState.chainId) + `/tx/${redeemPostionsSuccessHash}`} target="_blank">{t('visit_etherscan_to_view_transaction')}</a>
          </div>          
        </Modal>
      )}
    </div>
  )
};

export default RedeemPositions;

const LiquidityBox = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 2px;
  border: 1px solid #ada3e2;
  background-color: ${theme.light};
  margin-bottom: 20px;


  .text {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }
`;

const Group = styled.div`
  margin-top: 18px;
  margin-bottom: 5px;
`;

const ToggleWrap = styled.div`
  text-align: center;
  border: 1px solid #ada3e2;
  background-color: ${theme.light};
  margin-bottom: 20px;

  button {
    border: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    text-decoration: underline;
    padding: .5em;
    background-color: transparent;

    &:active,
    &:focus {
      outline: none;
    }
  }
`;
