import FixedProductMarketMaker from "../abis/FixedProductMarketMaker.json"
import ConditionResolverUsingReality from "../abis/ConditionResolverUsingReality.json"
import RealityEth from "../abis/RealityEthV3_0.json"
import {amountBToS} from "./utilities"
import { useToasts } from 'react-toast-notifications';
import { throwError } from "ethers/errors";
import Web3 from "web3";
import configData from "../config.json";


const BN = require('bn.js');


export const getAllowance = async (owner, spender, from, contracts) => {
    try{
        const result = await contracts.collateralToken.methods.allowance(owner, spender).call({from: from})
        return parseInt(result);
    }catch(error){
        throw error;
    }
}

export const doWeNeedToGetAllowance = async (owner, spender, amount, from, contracts) => {
    try{
        const result = await getAllowance(owner, spender, from, contracts);
        return amount <= result ? false : true;
    }catch(error){
        throw error;
    }
}

export const isLoggedInMetamask = (globalState) => {
    return globalState.currentAddress=='' ? 0 : 1;
}

export const askToConnectIfNotLoggedIn = (globalState, addToast) => {
    try {
        if(!isLoggedInMetamask(globalState)){
            addToast(
                "Connect your wallet first by clicking on Connect wallet button on top right!", 
                { appearance: "warning" }
            );
            throw new Error('Wallet not connected!')
        }
    } catch (error) {
        throw error;
    }
}

export const loadFixedProductMarketMaker = async (globalState, address) => {
    try{
        const result = await new globalState.web3.eth.Contract(FixedProductMarketMaker.abi, address);
        return result;
    }catch(e){
        throw e;
    }
}

export const loadConditionResolverUsingReality = async (globalState, address) => {
    try{
        const result = await new globalState.web3.eth.Contract(ConditionResolverUsingReality, address);
        return result;
    }catch(e){
        throw e;
    }
}

export const getMaxApproval = () => {
    var a = new BN(2, 10);
    var b = new BN(256, 10);
    var c = new BN(1);
    return a.pow(b).sub(c);
}

export const getCollateralBalance = async (globalState, from) => {
    const contracts = globalState.loadedContracts;
    if(contracts.collateralToken!=null){
        var bal = await contracts.collateralToken.methods.balanceOf(from).call({from: from})
        return parseInt(bal)
    }else{
        throw `Contracts not loaded`;
    }
}

export const web3UtilsBn = (str) => {
    return Web3.utils.toBN(str);
}

export const getCurrentGasPrice = async(globalState) => {
    let increaseBy = 1.2
    let price = await globalState.web3.eth.getGasPrice();
    price = Math. trunc(price * increaseBy);
    return price;
}

export const getChainBaseUrl = (chainId) => {
    var url = '';
    switch(chainId) {
        case 137:
            url = configData.POLYGON_URL;
        break;
        case 80001:
            url = configData.POLYGON_MUMBAI_URL;
        break;
        case 5:
            url = configData.GOERLI_URL;
        break;
        case 1:
            url = configData.ETHERSCAN_URL;
        break;
        default:
            url = configData.ETHERSCAN_URL; 
            break;

    }
    return url;
}