import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Container from '../Container/Container'
import { BreakpointUpMedium } from '../../styles/Breakpoints';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal/Modal';
import { authenticationService } from '../../services/authentication.service';

// import svgLine from '../../assets/curly-lines.svg';
import ethereumLogo from '../../assets/ethereum-logo.svg';
import ButtonLink from '../../components/UI/ButtonLink/ButtonLink';

const PolyBetDAOBanner = ({ marginBottom }) => {
  const { t, i18n } = useTranslation();
  const [showCountryBlockedPopUp, setShowCountryBlockedPopUp] = useState(0);
  useEffect(() => {
    if(authenticationService.isCountryRestricted()){
      // setShowCountryBlockedPopUp(1)
    }
    window.scrollTo(0, 0);
  }, [])

  return (
    <Section marginBottom={marginBottom}>
      {/* <Container noPaddingSmall> */}
        <EthereumLogo>
          <img src={ethereumLogo} alt="ethereum - logo" />
        </EthereumLogo>
        <ContentBox>
          <h1>{t('polybet_dao.box_1.title')}</h1>
          <p>{t('polybet_dao.box_1.p1')}</p>
          {showCountryBlockedPopUp===1 && (
            <Modal
              title={t('restricted_country_message')}
              align="center"
              close={() => setShowCountryBlockedPopUp(0)}
            >
              <div>
                <p style={{wordWrap:'break-word', wordBreak: 'break-all'}}>
                {t('restricted_country_message')}
                </p>
              </div>          
            </Modal>
          )}
        </ContentBox>
      {/* </Container> */}
    </Section>
  )
}

export default PolyBetDAOBanner;

PolyBetDAOBanner.propTypes = {
  marginBottom: PropTypes.number
};

const Section = styled.section.attrs({ className: 'hero' })`
  position: relative;
  ${({ marginBottom }) => marginBottom && css`
    margin-bottom: ${marginBottom}px;
  `}

  ${Container} {
    position: relative;
  }
`

const ContentBox = styled.div.attrs({ className: 'hero__content' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 230px;
  border-radius: 3px;
  padding: 40px 20px;
  background-position: top left;
  background-repeat: repeat;
  background-color: #4ea8c0;
  background-image: linear-gradient(180deg, #5094bf 0%, #29c4a9 100%);

  h1, p {
    color: white;
    text-align: center;
  }

  h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }

  p{
    font-weight: 700;
    font-size: 19px;
  }

  ${BreakpointUpMedium} {
    h1 {
      font-size: 40px;
      margin-bottom: 25px;
    }
    p{
      max-width: 510px;
      margin-bottom: 25px;
    }
  }
`

const ButtonsGroup = styled.div.attrs({ className: 'hero__buttons-group' })`
  display: flex;
  flex-direction: column;

  >div{
    padding: 12px;
  }

  ${BreakpointUpMedium} {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`

const EthereumLogo = styled.div`
  display: none;

  @media (min-width: 1070px) {
    display: block;
    position: absolute;
    width: 100px;
    top: 225px;
    left: -17%;
  }
`
