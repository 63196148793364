import React from "react";
import styled from "styled-components";
import Logo from "../Logo/Logo";
import { Web3Connect } from "../Web3Connect/Web3Connect";

export default function NewNavbar({ globalState, setGlobalState }) {
  return (
    <>
      <Nav>
        <div>
          <Logo to="/" />
        </div>

        <NavList>
          <li>
            <Web3Connect
              globalState={globalState}
              setGlobalState={setGlobalState}
            ></Web3Connect>
          </li>
        </NavList>
      </Nav>
    </>
  );
}


const Nav = styled.nav.attrs({ className: "navbar__container" })`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 104px;
`;

const NavList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;

  > li {
    &:not(:first-child) {
      padding-left: 15px;
    }
  }
`;
