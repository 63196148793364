import FixedProductMarketMaker from "../abis/FixedProductMarketMaker.json"
import {amountBToS} from "./utilities"
import configData from "../config.json"
import Web3 from "web3";
import { newtonRaphson } from '@fvictorio/newton-raphson-method'
import Big from 'big.js'
import { BigNumber, bigNumberify, formatUnits, getAddress } from 'ethers/utils';
import { loadFixedProductMarketMaker, getCurrentGasPrice  } from './web3'
import { amountSToB } from './utilities'
import { theGraphService } from '../services/thegraph.service'

const BN = require('bn.js');

export const getMaxApproval = () => {
    var a = new BN(2, 10);
    var b = new BN(256, 10);
    var c = new BN(1);
    return a.pow(b).sub(c);
}

export const  calcBuyAmount = (investmentAmount, outcomeIndex, poolBalances) => {
    // require(outcomeIndex < positionIds.length, "invalid outcome index");
    const ONE = Web3.utils.toBN(1e18);
    const fee = Web3.utils.toBN(configData.FEE_FACTOR);
    var iAmount = Web3.utils.toBN(investmentAmount);
    var oIndex = Web3.utils.toBN(outcomeIndex);
    poolBalances.forEach((e, i) => poolBalances[i] = Web3.utils.toBN(e));
    let investmentAmountsubFees = iAmount.clone().sub(iAmount.clone().mul(fee).div(ONE));
    
    let buyTokenPoolBalance = poolBalances[outcomeIndex];
    let endingOutcomeBalance = buyTokenPoolBalance.mul(ONE);
    for(let i = 0; i < poolBalances.length; i++) {
        if(i != outcomeIndex) {
            let poolBalance = poolBalances[i].clone();
            endingOutcomeBalance = ceildiv(
                endingOutcomeBalance.mul(poolBalance), 
                poolBalance.add(investmentAmountsubFees)
            );
        }
    }
    // require(endingOutcomeBalance > 0, "must have non-zero balances");
    return buyTokenPoolBalance.add(investmentAmountsubFees).sub(ceildiv(endingOutcomeBalance, ONE)).toString(10);
}

export const  calcSellAmount = (returnAmount, outcomeIndex, poolBalances) => {
    // require(outcomeIndex < positionIds.length, "invalid outcome index");
    const ONE = Web3.utils.toBN(1e18);
    const fee = Web3.utils.toBN(configData.FEE_FACTOR);
    var rAmount = Web3.utils.toBN(returnAmount);
    poolBalances.forEach((e, i) => poolBalances[i] = Web3.utils.toBN(e));
    let returnAmountaddFees = rAmount.clone().mul(ONE).div(ONE.clone().sub(fee));
    
    let sellTokenPoolBalance = poolBalances[outcomeIndex];
    let endingOutcomeBalance = sellTokenPoolBalance.mul(ONE);
    for(let i = 0; i < poolBalances.length; i++) {
        if(i != outcomeIndex) {
            let poolBalance = poolBalances[i].clone();

            endingOutcomeBalance = ceildiv(
                endingOutcomeBalance.mul(poolBalance), 
                poolBalance.sub(returnAmountaddFees)
            );
        }
    }
    return returnAmountaddFees.add(ceildiv(endingOutcomeBalance, ONE)).sub(sellTokenPoolBalance).toString(10);
}

export const ceildiv = (x, y) => {
    x = Web3.utils.toBN(x);
    y = Web3.utils.toBN(y);
    let one = Web3.utils.toBN(1)
    if(!x.isNeg() && x.gt(0)) return x.sub(one).div(y).add(one);
    return x.div(y);
}

export function convertToBN(str){
    return Web3.utils.toBN(str)
} 

export const calcExactSellCollateralAndTokenAmount = (
    poolBalances,
    sellOptionIndex,
    sharesToSell,
    fee
) => {
    let collateralDecimal = amountBToS('1');
    let gotCollateralResult = 0;
    let tokensMeetingCriteria = sharesToSell;
    const sharesToSellS = amountBToS(`${sharesToSell}`)
    var actualToBeSoldTokens;
    var collateralToReturn;
    while(!gotCollateralResult){
        collateralToReturn = calcSellAmountInCollateral(tokensMeetingCriteria,
            poolBalances[sellOptionIndex],
            poolBalances.filter((e, i) => i!=sellOptionIndex),
            fee
            // .02 
          );
        collateralToReturn = collateralToReturn.mul(collateralDecimal)
        actualToBeSoldTokens = new Big(calcSellAmount(collateralToReturn.toString(), sellOptionIndex, poolBalances));
        if(actualToBeSoldTokens.gt(sharesToSellS) || actualToBeSoldTokens.lt(collateralToReturn.toString())){
            --tokensMeetingCriteria;
        }else{
            gotCollateralResult = 1;
        }
    }
    return { 
        estimatedCollateralB: collateralToReturn.div(collateralDecimal).toNumber(), 
        actualSharesToBeSoldB: actualToBeSoldTokens.div(collateralDecimal).toNumber(),
        estimatedCollateralS: collateralToReturn, 
        actualSharesToBeSoldS: actualToBeSoldTokens
    } ;
}

export const calcSellAmountInCollateral = (
    sharesToSell,
    holdings,
    otherHoldings,
    fee,
  ) => {
    Big.DP = 90
  
    const sharesToSellBig = new Big(sharesToSell.toString())
    const holdingsBig = new Big(holdings.toString())
    const otherHoldingsBig = otherHoldings.map(x => new Big(x.toString()))
  
    const f = (r) => {
      // For three outcomes, where the first outcome is the one being sold, the formula is:
      // f(r) = ((y - R) * (z - R)) * (x  + a - R) - x*y*z
      // where:
      //   `R` is r / (1 - fee)
      //   `x`, `y`, `z` are the market maker holdings for each outcome
      //   `a` is the amount of outcomes that are being sold
      //   `r` (the unknown) is the amount of collateral that will be returned in exchange of `a` tokens
      const R = r.div(1 - fee)
      const firstTerm = otherHoldingsBig.map(h => h.minus(R)).reduce((a, b) => a.mul(b))
      const secondTerm = holdingsBig.plus(sharesToSellBig).minus(R)
      const thirdTerm = otherHoldingsBig.reduce((a, b) => a.mul(b), holdingsBig)
      return firstTerm.mul(secondTerm).minus(thirdTerm)
    }
  
    const r = newtonRaphson(f, 0, { maxIterations: 100 })
    
    if (r) {
        // const oneCollateral = new Big(amountBToS('1'));
        // const amountToSell = bigNumberify(r.mul(oneCollateral).toFixed(0))
        // console.log('r original value ', r.toString())
        // const amountToSell = bigNumberify(r.round(0,0).toString())
        const amountToSell = bigNumberify(r.toFixed(0))
        return amountToSell
    }
  
    return null
}

const getUserLiquiditySharesValue = (userLiquidityShares, totalLiquidityShares, totalSharesValue) => {
    userLiquidityShares = amountSToB(userLiquidityShares);
    totalLiquidityShares = amountSToB(totalLiquidityShares)
    totalSharesValue = amountSToB(totalSharesValue)
    const userSharePercentage = (userLiquidityShares * 100) / totalLiquidityShares;
    const userSharesValue = (userSharePercentage * totalSharesValue) / 100;
    return userSharesValue;
}

export async function getMarketStatsForUser(globalState, market, from, isUserLoggedIn) {
    try {
        
        const fpmmAddress = market.fpmm_market_maker_address;
        const questionId = market.question_id;
        const marketDataFromGraph = await theGraphService.getMarket(fpmmAddress, questionId, globalState, market);
        
        // if(!isUserLoggedIn)
        //     return {
        //         liquidity: {
        //             liquidityShares: null,
        //             feesWithdrawable: null,
        //             userLiquidityShareValueInUsd: null
        //         },
        //         marketDataFromGraph,
        //         marketPositionBalances: null,
        //         marketPositionAvgPrices: null,
        //         marketTotalLiquidityShares: null
        //     };
        const isRealityEthQuestionFinalized = marketDataFromGraph.question.isFinalized;
        marketDataFromGraph.question.isFinalized = isRealityEthQuestionFinalized;
        const fpmmContract = await loadFixedProductMarketMaker(globalState, fpmmAddress);               
        const marketPositions = Array.from(market.options, (e) => e.position_id)
        const marketPositionBalances = await getMarketPositionBalances(globalState, marketPositions, from);
        const marketPositionAvgPrices = await theGraphService.getAveragePriceForBuyTrades(fpmmAddress, from);
        const feesWithdrawable = await getFeesWithdrawableBy(fpmmContract, from);
        const marketTotalLiquidityShares = await getFpmmTotalSupply(fpmmContract, from);
        const liquidityShares = await getFpmmBalance(fpmmContract, from);
        const userLiquidityShareValueInUsd = getUserLiquiditySharesValue(liquidityShares, marketTotalLiquidityShares, market.liquidity_measure);

        return {
            liquidity: {
                liquidityShares,
                feesWithdrawable,
                userLiquidityShareValueInUsd
            },
            marketDataFromGraph,
            marketPositionBalances,
            marketPositionAvgPrices,
            marketTotalLiquidityShares
        }
    } catch (error) {
        throw error;
    }
}

export const isConditionalTokenApprovedForAll = async (globalState, owner, operator) => {
    try {
        const isApprovedForAll = await globalState.loadedContracts.conditionalToken.methods.isApprovedForAll(owner, operator).call({from: owner});
        return isApprovedForAll;
    } catch (error) {
        throw error;
    }
}

export const getFpmmBalance = async (fpmmContract, from) => {
    try {
        const fpmmBalance = await fpmmContract.methods.balanceOf(from).call({from: from})
        return fpmmBalance;
    } catch (error) {
        throw error;
    }
}

export const getFpmmTotalSupply = async (fpmmContract, from) => {
    try {
        const fpmmBalance = await fpmmContract.methods.totalSupply().call({from: from})
        return fpmmBalance;
    } catch (error) {
        throw error;
    }
}

export const getFeesWithdrawableBy = async (fpmmContract, from) => {
    try {
        const bal = await fpmmContract.methods.feesWithdrawableBy(from).call({from});
        return bal;
    } catch (error) {
        return 0;
    }
}

export const getMarketPositionBalances = async (globalState, marketPositions, from) => {
    try {
        let positionsBalances = await globalState.loadedContracts.conditionalToken.methods.balanceOfBatch(Array.from({length: marketPositions.length}, ()=>from), marketPositions).call({from: from});
        return positionsBalances;
    } catch (error) {
        throw error;
    }
}

export const removeLiquidity = async ({
    globalState, 
    market, 
    from, 
    liquidityToBePulled,
    onConfirmation,
    onError,
    onTransactionHash
}) => {
    try {
        let gasPrice = await getCurrentGasPrice(globalState)
        const fpmmAddress = market.fpmm_market_maker_address;
        const fpmmContract = await loadFixedProductMarketMaker(globalState, fpmmAddress);
        
        fpmmContract.methods.removeFunding(liquidityToBePulled).send({from: from, gasPrice: gasPrice})
        .on('transactionHash', function(hash){
            onTransactionHash(hash)
        })
        .on('confirmation', function(confirmationNumber, receipt){
            onConfirmation(confirmationNumber, receipt)
        })
        .on('error', function(error, receipt) {
            onError(error)
        });
    } catch (error) {
        throw error;
    }
}

export const fpmmMergeShares = async ({
    globalState, 
    market, 
    from, 
    sharesToMerge,
    onConfirmation,
    onError,
    onTransactionHash
}) => {
    try {
        let gasPrice = await getCurrentGasPrice(globalState)
        const indexSets = [Web3.utils.toBN(1).shln(0), Web3.utils.toBN(1).shln(1)];
        const argMergePositions = [
            configData.addresses.collateralToken, 
            '0x0000000000000000000000000000000000000000000000000000000000000000', 
            market.condition_id, 
            indexSets, 
            Web3.utils.toBN(sharesToMerge)
        ]

        globalState.loadedContracts.conditionalToken.methods.mergePositions(...argMergePositions).send({from: from, gasPrice: gasPrice})
        .on('transactionHash', function(hash){
            onTransactionHash(hash)
        })
        .on('confirmation', function(confirmationNumber, receipt){
            onConfirmation(confirmationNumber, receipt)
        })
        .on('error', function(error, receipt) {
            onError(error)
        });
    } catch (error) {
        throw error;
    }
}